import React, { useEffect, useRef, useState } from "react";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../util/style";
import boderImg from "./../../../../assets/border.png";
import logo from "./../../../../logo.png";
import Signature from "./Signature";
import { formatValue } from "../detail_payment.hooks";
import { PaymentStatusBadge, useStyles } from "../parts/CusomStyle";
import {
  createAliasForCategory,
  getTimeFromDate,
} from "../parts/imutable_state";
import { pxToMm } from "../../../../util/function";
import ApprovalLine from "./ApprovalLine";
import "../../styles/style.print.css";
import PaidStample from "../../../../assets/paid_stample.svg";

//* Main Function
const DocumentForPrint = ({ detailPR, height, mode }) => {
  const { renderValue } = formatValue(detailPR);
  //* =========== Primitif Var ===============

  // const statusColors = {
  //   pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
  //   approved: { bgColor: successSurfaceColor, colors: successMainColor },
  //   rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
  //   // Add more statuses as needed
  // };
  const classesStyle = useStyles();

  const validateValue = (key, value) => {
    switch (key) {
      case "Client":
        return ": ".concat(value.name);
      case "Create At":
        return `${renderValue(value, key)} ${getTimeFromDate(value)}`;
      case "Category":
        return createAliasForCategory(value);
      default:
        return renderValue(value, key);
    }
  };

  const Stample = () => {
    return (
      <>
        <div
          style={{
            position: "absolute",
            // bottom: "-3.5rem",
            transform: "translate(-50%,-50%)",
            top: "50%",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: "0.20",
          }}
        >
          <div style={{ padding: "1rem" }}>
            <img
              src={PaidStample}
              alt="Paid"
              // style={{ width: "95%" }}
              className="not-effect"
            />
          </div>
          {/* <div
            style={{
              ...boxStyle,
              // transform: "rotate(2.5deg)",
            }}
          ></div>
          <div
            style={{
              ...boxStyle,
              transform: "rotate(-5deg)",
            }}
          ></div>
          <h1
            className="not-effect"
            style={{
              padding: "0 1.7rem",
              margin: 0,
              textAlign: "center",
              color: "#ff0000",
              fontSize: "2.6rem",
              // background: "yellow",
            }}
          >
            {text}
          </h1> */}
        </div>
      </>
    );
  };

  const renderGridItemsFromObject = (object) => {
    const alias = {
      "No. Reference": "No",
      "Created By": "User",
      "Create At": "Date",
      Remarks: "Remark",
    };

    const includedPropery = Object.keys(alias);
    const rightAlignedKeys = ["Subtotal", "Discount", "VAT (11%)", "Total"];
    const isRightAlignedKey = (key) => {
      return (
        rightAlignedKeys.includes(key) || key.toLowerCase().includes("discount")
      );
    };

    return (
      <>
        {/* <PaymentStatusBadge status="paid"  /> */}
        {Object.entries(object).map(([key, value]) =>
          // Skip rendering "Request to"
          includedPropery.includes(key) && value !== null ? (
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <Typography variant="body1">{alias[key] || null}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: isRightAlignedKey(key) ? "right" : "left" }}
                >
                  {renderValue(value, key)}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null
        )}
      </>
    );
  };

  const renderRequestedServices = (object) => {
    const alias = {
      "Create At": "Create At",
      "Due Date": "Due Date",
      Note: "Note",
      Total: "Total",
      "Pay At": "Pay At",
      "Code Billing":"Code Billing"
    };
    const listKey = Object.keys(alias);
    if (detailPR.Category === "Services") {
      listKey.unshift("Client", "Service", "Sub Service");

      alias.Service = "Services";
      alias["Sub Service"] = "Sub Service";
      alias.Client = "Client Name";
    }

    const formater = new Intl.ListFormat("en", {
      style: "narrow",
      type: "conjunction",
    });
    const attachmentName = (attachments) => {
      if (attachments && Array.isArray(attachments) && attachments.length) {
        const names = attachments.map((attch) => attch.filename);
        return ": ".concat(formater.format(names));
      }
      return ": -";
      // if (Array.isArray(object["Attachment"]?.["attachment"])) {
      //   const names = object["Attachment"]?.["attachment"].map(
      //     (val) => val?.filename
      //   );
      //   if (names.length > 0) {
      //     return ": ".concat(formater.format(names));
      //   } else {
      //   }
      // }
    };
    return (
      <>
        {/* {Object.entries(object).map(([key, value]) => */}
        {listKey.map((key) => (
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
                  {key === "Client" ? "Client Name" : key}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  textAlign: key === "Total" ? "right" : "left",
                }}
              >
                {validateValue(key, object[key])}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
              Attachment
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              textAlign: "left",
            }}
          >
            {attachmentName(object["Attachment"]?.["attachment"])}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
              Payment Attachment
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              textAlign: "left",
            }}
          >
            {attachmentName(object["paymentAttachment"])}
          </Typography>
        </Grid>

        {/* Render Note in bottom */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ApprovalLine detailDocument={detailPR} />
          {/* <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
            }}
          >
            <span>:&nbsp;</span>
            {object?.Note}
          </Typography> */}
        </Grid>
      </>
    );
  };

  //! Not Used
  //   const renderPriceInformation = (object) => {
  //     return Object.entries(object).map(([key, value]) =>
  //       (value !== undefined || value !== null) &&
  //       includedProperty.includes(key) ? (
  //         <React.Fragment key={key}>
  //           <Grid item xs={8}>
  //             <Typography
  //               variant="body2"
  //               sx={{ textAlign: "right", fontWeight: "bold" }}
  //             >
  //               {key}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={4}>
  //             <Typography
  //               variant="subtitle1"
  //               sx={{ fontWeight: 800, textAlign: "right" }}
  //             >
  //               {value}
  //             </Typography>
  //           </Grid>
  //         </React.Fragment>
  //       ) : null
  //     );
  //   };

  /** ============ Component ========== */

  const SignatureSectionComp = () => (
    <Grid
      container
      spacing={3}
      sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
    >
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        <Signature companyName="User" signatureText={detailPR["Created By"]} />
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        <Signature companyName="Manager Division" signatureText={detailPR?.PIC?.name} />
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        {/* TODO: Change to relative to data */}
        <Signature
          companyName="Accounting"
          signatureText={detailPR["Accounting"]}
        />
      </Grid>
    </Grid>
  );

  const RenderFooter = () => (
    <div className={`${classesStyle.footer}`}>
      <Box sx={{ display: "flex", gap: ".5rem" }}>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          +6221 2986 5888
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          pacific@pacificpatent.com
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        www.pacificpatent.com
      </Typography>
    </div>
  );

  return (
    <>
      <div className="page-break">
        <Paper className={`${classesStyle.root} DocumentPreview`}>
          <img src={boderImg} className={classesStyle.logo} />

          {/* Header */}
          <div className={classesStyle.header}>
            <div style={{ position: "relative" }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "800" }}
                className="not-effect"
              >
                {mode}
              </Typography>
            </div>
            <div>
              <img src={logo} alt="logo" />
            </div>
          </div>

          {/* End of Header */}

          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs="6" container>
              {renderGridItemsFromObject(detailPR)}
            </Grid>

            <Box
              sx={{
                width: "50%",
              }}
            >
              {/* <Grid item xs="5" container> */}
              {/* <Grid item xs={12}> */}
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                PT Pacific Patent Multiglobal{" "}
              </Typography>
              {/* </Grid> */}
              {/* <Grid item xs={12}> */}
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "right", color: neutral70 }}
              >
                DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav
                51-52
                <br /> Jakarta Pusat 10260
              </Typography>
              {/* </Grid> */}
              {/* </Grid>  */}
            </Box>
          </Grid>

          {/* Content */}
          <div className={classesStyle.content}>
            <Grid
              container
              spacing={2}
              sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 0 }}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ color: neutral70 }}>
                  Category
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "black", fontWeight: "800" }}
                >
                  {createAliasForCategory(detailPR?.Category)}
                </Typography>
              </Grid>
              {renderRequestedServices(detailPR)}
            </Grid>
          </div>
          <Divider />

          {/* Price information and total */}
          <Grid
            container
            spacing={2}
            sx={{
              "& .MuiGrid-item": { paddingTop: "7px" },
              mt: 0,
              padding: "20px",
            }}
          >
            <Grid item xs={8}>
              <Typography
                variant="body2"
                sx={{ textAlign: "right", fontWeight: "bold" }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 800, textAlign: "right" }}
              >
                {renderValue(detailPR.Total, "total", false)}
              </Typography>
            </Grid>
          </Grid>

          {/* Signature */}
          <SignatureSectionComp />

          {/* Footer */}
          <RenderFooter />
          {detailPR["Status"] === "Paid" && <Stample />}
        </Paper>
        <style>
          {`
        .page-break {
          page-break-before: always;
        }
        .DocumentPreview{
          display:none;
        }
        .DocumentPreviewFooter{
          padding: 20px;
          background-color: #09345C;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media print {
          @page {
            size: 210mm ${pxToMm(height)}mm; /* A4 size */
              margin: 0;
              /* Remove all margins */
          }

          body {
              margin: 0;
          }

          body *{
            visibility:hidden;
          }
.DocumentPreviewFooter{
   position:fixed;
  bottom:0;
  left:0;
  width:100%;
  border-radius:unset;
}
          .DocumentPreviewFooter, .DocumentPreviewFooter *{
              visibility: visible;
 padding: 10px 20px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          print-color-adjust: exact;
          webkit-print-color-adjust: exact; /* For WebKit-based browsers */
        background-color:  #09345C !important; /* Override background color */
        }

          .DocumentPreview{
            display:block;
            position: fixed !important;
            top: 0;

            left: 0;
            width: 100%;
            z-index: 11999;
            visibility: visible;
            page-break-before: always;
          }

          .DocumentPreview,.DocumentPreview *{
              visibility: visible;
          }
        }
        `}
        </style>
      </div>
    </>
  );
};

export default DocumentForPrint;
