import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
  Divider,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import CalendarIcon from "./../../../assets/calendar.png";
import SearchIconBlack from "./../../../assets/search-black.svg";
import RemoveIcon from "./../../../assets/minus.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../util/style";
import axios from "../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
} from "../../../components/Form";
import { addSalesQuotation, deleteSalesQuotation, editSalesQuotation, getSalesQuotation, getSalesQuotationDetails } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
  formatCurrencyWithoutLable,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import LeftDrawer from "../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";

const SalesQuotation = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                           SALES_ORDER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  /*
  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    console.log(currentPath, userAccess);
  }, [userAccess]);
  */
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const ANOTHER_ACTION_TYPE = {
    ADD_SALES_ORDER: 'ADD_SALES_ORDER',
    ADD_SALES_ORDER_FROM_SALES_QUOTATION: 'ADD_SALES_ORDER_FROM_SALES_QUOTATION',
    CHECK_SALES_QUOTATION: 'CHECK_SALES_QUOTATION'
  }
  const { token } = useSelector((state) => state.auth);
  const {
    data,
    isLoading: isLoadingDetail,
  } = useSelector((state) => state.salesquotationsdetail);

  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Sales Quotation");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [type, setType] = useState(ACTION_TYPE.ALL_TYPE);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.ALL_DATE);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateElm, setStartDateElm] = useState(null);
  const [endDateElm, setEndDateElm] = useState(null);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);
  const [category, setCategory] = useState(ACTION_TYPE.ALL_CATEGORY);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const QuotationStatus = {
    REJECTED: 'rejected'
  }

  const columns = [
    {
      field: "code",
      headerName: "No. Sales Quotation",
      width: 250,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <a
            onClick={() => navigate(`detail/${params.row.id}`)}
            //             onClick={() => handleFormModal(ACTION_TYPE.DETAIL, params.row)}
            style={{ textDecoration: "none", cursor: "grab", color: 'blue' }}
          >
            {params.value}
          </a>
        </div>

      ),
    },
//     ...(process.env.REACT_APP_API_URL !== "https://api-app.pacificpatent.com"
    ...(true
      ? [
        {
          field: "referral",
          headerName: "Referral Code",
          width: 190,
          disableColumnMenu: true,
        },
      ]
      : []),
    {
      field: "client_name",
      headerName: "Client Name",
      width: 190,
      disableColumnMenu: true,
    },
    {
      field: "service_name",
      headerName: "Service Category",
      width: 220,
      disableColumnMenu: true,
      // valueGetter: (params) => {
      //   return params.row.service_categories
      //     .map((item) => item.service.name)
      //     .join(", ");
      // },
      // renderCell: (params) => (
      //   <Box
      //     sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
      //   >
      //     {params.value.split(", ").map((name, key) => (
      //       <span key={key}>{name}</span>
      //     ))}
      //   </Box>
      // ),
    },

    {
      field: "sub_services",
      headerName: "Sub Service Category",
      width: 280,
      disableColumnMenu: true,
      // sortable: false,
      // valueGetter: (params) => {
      //   // Assuming 'params.row.service_categories' is an array of objects
      //   const subServices = params.row.service_categories.flatMap((item) =>
      //     item.sub_services.map((subService) => subService.name)
      //   );
      //   return subServices.join(", ");
      // },
      // renderCell: (params) => (
      //   <Box
      //     sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
      //   >
      //     {params.value.split(", ").map((name, key) => (
      //       <StatusBadge
      //         key={key}
      //         status={name}
      //         isinfo={true}
      //         style={{ margin: ".1rem .25rem" }}
      //       >
      //         {name}
      //       </StatusBadge>
      //     ))}
      //   </Box>
      // ),
      renderCell: (params) => (
        <Box
          sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
        >
          {params.value.map((subService) => (
            <StatusBadge
              key={subService.id}
              status={subService.name}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {subService.name}
            </StatusBadge>
          ))}
        </Box>
      ),
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 190,
      disableColumnMenu: true,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      width: 190,
      disableColumnMenu: true,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 190,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        const statusColors = {
          Draft: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Approved: { bgColor: successSurfaceColor, colors: successMainColor },
          Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
          // Add more statuses as needed
        };

        const statusInfo = statusColors[params.value] || {
          bgColor: infoSurfaceColor,
          colors: infoMainColor,
        };
        return (
          <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
            {params.value}
          </StatusBadge>
        )
      },
    },
    // {
    //   field: "date_deal",
    //   headerName: "Date Deal",
    //   width: 190,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <>
    //       Deal: {params.value}
    //     </>
    //   ),
    // },

  ];

  const fields = [
    // {
    //   title: "Basic Information",
    //   subtitle: "Complete all employee basic information, as basic data",
    //   isDisplayTitle: false,
    // },
    {
      title: "Client",
      isDisplayTitle: false,
      children: [
        {
          name: "client_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Client Name",
          apiEndpoint: "/master/clients",
          required: true,
        },
        {
          name: "date",
          type: INPUT_TYPE.DATE,
          label: "Date Created",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "DD/MM/YYYY", // Custom date format
          defaultValue: dayjs(),
          required: true,
        },
//         ...(process.env.REACT_APP_API_URL !== "https://api-app.pacificpatent.com"
        ...(true
          ? [
            {
              name: "referral",
              type: INPUT_TYPE.TEXT,
              label: "Employee referral code",
              defaultValue: "",
              placeholder: "Input employee referral code ( Ops )",
              required: false,
            },
            ]
            : []),
          ],
        },
        {
          title: "Service",
          subtitle: "Define the service the client chooses",
          isDisplayTitle: true,
          children: [
            {
              name: "service_id",
              type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
              label: "Service Category",
              apiEndpoint: "/master/services",
              required: true,
              additionalProps: {
                disabled: false,//actionType === ACTION_TYPE.EDITED
              },
              gridWidth: 9
            },
            {
              name: "currency",
              type: INPUT_TYPE.SELECT_ONE,
              defaultValue:"Rp",
              options: [
                  { id: "Rp", name: "Rp" },
                  { id: "USD", name: "USD" },
                  { id: "EUR", name: "EUR" },
                  { id: "CNY", name: "CNY" },
                  { id: "SGD", name: "SGD" },
                  { id: "GBP", name: "GBP" },
                  { id: "AUD", name: "AUD" },
                  { name: "CNF", id: "CNF" },
                ],
              required: true,
              additionalProps: {
                showLable: false,
                showError: false,
              },
             styleForGrid: {
                marginTop: 2.5
              },
              gridWidth: 3
            },
          ],
        },
        {
          title: "Sub Service",
          isDisplayTitle: false,
          isMultilpe: true,
          children: [
            {
              name: "sub_service_id",
              type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
              label: "Sub Category Service",
              // apiEndpoint: "/master/services",
              required: true,
              additionalProps: {
                disabled: false, //actionType === ACTION_TYPE.EDITED // || formValues['Service'] && formValues['Service']['service_id'] === undefined || formValues['Service'] && formValues['Service']['service_id'] === null
              }
            },
            {
              name: "criteria_id",
              type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
              label: "Particular",
              placeholder:'Select particular (Ops)',
//               apiEndpoint: "/master/criterias",
              required: false,
              additionalProps: {
//                 disabled: actionType === ACTION_TYPE.EDITED
//                 disabled:true
              }
            },
            {
              name: "desc",
              type: INPUT_TYPE.TEXTAREA,
              label: "Description",
              placeholder: "Input description (Ops)",
              defaultValue: "",
              required: false,
              additionalProps: {
                // disabled: actionType === ACTION_TYPE.EDITED
              }
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'subtitle2',
              label: "Price",
              style: {
                marginTop: 1
              },
              gridWidth: 6,
            },
            {
              name: "price",
              type: INPUT_TYPE.NUMBER,
              startIcon: "Rp",
              defaultValue: 0,
              placeholder: 'Price',
              required: true,
              additionalProps: {
                showLable: false,
                showError: false,
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'subtitle2',
              label: "Quantity",
              style: {
                marginTop: 1
              },
              gridWidth: 6,
            },
            {
              name: "quantity",
              type: INPUT_TYPE.NUMBER,
//               startIcon: "Rp",
              defaultValue: 1,
              placeholder: 'Quantity',
              required: true,
              additionalProps: {
                showLable: false,
                showError: false,
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'subtitle2',
              label: "Subtotal",
              style: {
                marginTop: 1
              },
              gridWidth: 6,
            },
//             {
//               type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
//               variant: 'subtitle2',
//               name: "subtotal",
//               label: "Rp. ",
//               defaultValue: 0,
//               style: {
//                 marginTop: 1,
//                 ...justifyContentEnd,
//                 fontWeight: 'bold'
//               },
//               gridWidth: 6,
//             },
             {
              name: "subtotal",
              type: INPUT_TYPE.NUMBER,
              startIcon: "Rp",
              defaultValue: 0,
              placeholder: 'Subtotal',
              required: true,
              additionalProps: {
                showLable: false,
                showError: false,
                readOnly: true,
                disabled:true,
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'subtitle2',
              label: "Discount",
              style: {
                marginTop: 1
              },
              gridWidth: 8,
            },
            {
              name: "discount",
              type: INPUT_TYPE.DISCOUNT,
              endIcon: " % ",
              defaultValue: 0,
              placeholder: 'Discount',
              required: false,
              additionalProps: {
                showLable: false,
                showError: false,
                // disabled: actionType === ACTION_TYPE.EDITED
              },
              gridWidth: 4,
            },
            {
              name: "vat_percent",
              type: INPUT_TYPE.CHECKBOX,
              defaultValue: [], // Array for selected format values
              options: [
                { id: "11", name: "VAT 11%" },
              ],
              additionalProps: {
                showLable: false,
                showError: false,
                disabled:true
                // disabled: actionType === ACTION_TYPE.EDITED
              },
              required: false,
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'subtitle2',
              name: "vat_to_rp",
              label: "Rp. ",
              defaultValue: 0,
              style: {
                marginTop: 1,
                ...justifyContentEnd,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'subtitle2',
              label: "Total",
              style: {
                marginTop: 1,
                marginBottom: 1.5,
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'subtitle2',
              name: "total_to_rp",
              label: "Rp. ",
              defaultValue: 0,
              style: {
                marginTop: 1,
                marginBottom: 1.5,
                ...justifyContentEnd,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
          ],
        },
        {
          title: "Attachment",
          subtitle: 'You can upload the documents required for this proses (Ops)',
          isDisplayTitle: true,
          children: [
            {
              name: "attachment",
              type: INPUT_TYPE.FILE,
              label: "Upload File",
              tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
              allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
              maximumSizeInMB: 2, //MB
              defaultValue: '',
              // required: true,
            },
          ],
        },
        {
          title: "Note Section",
          isDisplayTitle: false,
          children: [
            {
              name: "note",
              type: INPUT_TYPE.TEXTAREA,
              label: "Note",
              defaultValue: "",
              placeholder: "Input description (Ops)",
              required: false,
            },
            {
              name: "remarks",
              type: INPUT_TYPE.TEXT,
              label: "Remarks",
              defaultValue: "",
              placeholder: "Input remark",
              required: true,
            },
          ],
        },
        {
          title: "Price Information",
          isDisplayTitle: false,
          children: [
            {
              name: "price_services_info",
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'body1',
              label: "Price  ",
              defaultValue: '(0 service)',
              style: {
                marginTop: 1,
              },
              gridWidth: 6,
            },
            {
              name: "price_services_info_to_rp",
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'body1',
              label: "Rp.  ",
              defaultValue: '0',
              style: {
                marginTop: 1,
                ...justifyContentEnd,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'body1',
              label: "Discount",
              style: {
                marginTop: 1
              },
              gridWidth: 6,
            },
            {
              name: "discount_info_to_rp",
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'body1',
              label: "Rp.  ",
              defaultValue: '0',
              style: {
                marginTop: 1,
                ...justifyContentEnd,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
            {
              type: INPUT_TYPE.DIVIDER,
              style: {
                marginTop: 1
              },
              gridWidth: 12,
            },
            {
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'body1',
              label: "VAT",
              style: {
                marginTop: 1
              },
              gridWidth: 6,
            },
            {
              name: "vat_info_to_rp",
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'body1',
              label: "Rp.  ",
              defaultValue: '0',
              style: {
                marginTop: 1,
                ...justifyContentEnd,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
            {
              name: "total_price_info",
              type: INPUT_TYPE.TYPOGRAPHY,
              variant: 'body1',
              label: "Total Price",
              style: {
                marginTop: 1,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
            {
              name: "total_price_info_to_rp",
              type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
              variant: 'body1',
              label: "Rp.  ",
              defaultValue: '0',
              style: {
                marginTop: 1,
                ...justifyContentEnd,
                fontWeight: 'bold'
              },
              gridWidth: 6,
            },
          ],
        },
      ]
//   const [fields, setFields] = useState([]);
  // const [options, setOptions] = useState({});


  const initialSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: 0,
    vat_percent: '',
    vat_to_rp: 0,
    total_to_rp: 0,
    criteria_id: "",
    desc: "",
  };

  const initialAttachment = {
    attachment: [""],
  };

  const initialErrorSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: "",
    vat_percent: "",
    criteria_id: "",
    desc: "",
  };
  const initialErrorAttachment = {
    attachment: [""],
  };

  const initialFormValues = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialSubService]
            : fieldGroup.title === "Attachment"
              ? initialAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
              );
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialErrorValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialErrorValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialErrorSubService]
            : fieldGroup.title === "Attachment"
              ? initialErrorAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: "" }),
                {}
              );
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());

  /*
    useEffect(() => {
  
      setIsLoadedFormValues(false)
      // Define your fields based on the actionType
      const newFields = actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION
        ? [
          {
            title: "Check Sales Quotation",
            isDisplayTitle: false,
            children: [
              {
                name: "client_id",
                type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                label: "Client Name",
                apiEndpoint: "/master/clients",
                required: false,
                // required: true,
              },
              {
                name: "periode",
                label: "Sales Quotation Period",
                type: INPUT_TYPE.DATE,
                endIcon: <img src={CalendarIcon} />,
                formatDate: "MMMM YYYY", // Custom date format
                // defaultValue: dayjs("01/01/2021"),
                additionalProps: {
                  placeholder: "“month” & “year”",
                  views: ["month", "year"],
                  disabled: true //formValues['Check Sales Quotation'] && formValues['Check Sales Quotation']['client_id'] && formValues['Check Sales Quotation']['client_id'] === '',
                },
                defaultValue: "",
                required: false,
              },
              {
                name: "sales_quotation_id",
                type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                label: "No. Sales Quotation",
  //               apiEndpoint: "/master/sales-quotation",
                required: false,
                additionalProps: {
                  disabled: true//formValues['Check Sales Quotation'] && formValues['Check Sales Quotation']['client_id'] && formValues['Check Sales Quotation']['client_id'] === '' && formValues['Check Sales Quotation'] && formValues['Check Sales Quotation']['periode'] && formValues['Check Sales Quotation']['periode'] === ''
                }
              },
            ]
          }
        ]
        : actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION || actionType === ACTION_TYPE.EDITED ? [
          // {
          //   title: "Basic Information",
          //   subtitle: "Complete all employee basic information, as basic data",
          //   isDisplayTitle: false,
          // },
          {
            title: "Client",
            isDisplayTitle: false,
            children: [
              {
                name: "client_id",
                type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                label: "Client Name",
                apiEndpoint: "/master/clients",
                required: true,
              },
              {
                name: "date",
                type: INPUT_TYPE.DATE,
                label: "Birth Date",
                endIcon: <img src={CalendarIcon} />,
                formatDate: "DD/MM/YYYY", // Custom date format
                defaultValue: dayjs(),
                required: true,
              },
            ],
          },
          {
            title: "Service",
            subtitle: "Define the service the client chooses",
            isDisplayTitle: true,
            children: [
              {
                name: "service_id",
                type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                label: "Service Category",
                apiEndpoint: "/master/services",
                required: true,
                additionalProps: {
                  disabled: actionType === ACTION_TYPE.EDITED || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION
                }
              },
            ],
          },
          {
            title: "Sub Service",
            isDisplayTitle: false,
            isMultilpe: true,
            children: [
              {
                name: "sub_service_id",
                type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                label: "Sub Category Service",
                // apiEndpoint: "/master/services",
                required: true,
                additionalProps: {
                  disabled: actionType === ACTION_TYPE.EDITED || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION || formValues['Service'] && formValues['Service']['service_id'] === undefined || formValues['Service'] && formValues['Service']['service_id'] === null
                }
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY,
                variant: 'subtitle2',
                label: "Subtotal",
                style: {
                  marginTop: 1
                },
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'subtitle2',
                name: "subtotal",
                label: "Rp. ",
                defaultValue: 0,
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY,
                variant: 'subtitle2',
                label: "Discount",
                style: {
                  marginTop: 1
                },
                gridWidth: 8,
              },
              {
                name: "discount",
                type: INPUT_TYPE.DISCOUNT,
                endIcon: " % ",
                defaultValue: 0,
                placeholder: 'Discount',
                required: false,
                additionalProps: {
                  showLable: false,
                  showError: false,
                  // disabled: actionType === ACTION_TYPE.EDITED
                },
                gridWidth: 4,
              },
              {
                name: "vat_percent",
                type: INPUT_TYPE.CHECKBOX,
                defaultValue: [], // Array for selected format values
                options: [
                  { id: "11", name: "VAT 11%" },
                ],
                additionalProps: {
                  showLable: false,
                  showError: false,
                  disabled:true
                  // disabled: actionType === ACTION_TYPE.EDITED
                },
                required: false,
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'subtitle2',
                name: "vat_to_rp",
                label: "Rp. ",
                defaultValue: 0,
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY,
                variant: 'subtitle2',
                label: "Total",
                style: {
                  marginTop: 1
                },
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'subtitle2',
                name: "total_to_rp",
                label: "Rp. ",
                defaultValue: 0,
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                name: "criteria_id",
                type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                label: "",
                apiEndpoint: "/master/criterias",
                required: true,
                additionalProps: {
                  disabled: actionType === ACTION_TYPE.EDITED || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION
                }
              },
              {
                name: "desc",
                type: INPUT_TYPE.TEXTAREA,
                label: "Description",
                placeholder: "Input description (Ops)",
                defaultValue: "",
                required: false,
                additionalProps: {
                  // disabled: actionType === ACTION_TYPE.EDITED
                }
              },
            ],
          },
          {
            title: "Attachment",
            subtitle: 'You can upload the documents required for this proses (Ops)',
            isDisplayTitle: true,
            children: [
              {
                name: "attachment",
                type: INPUT_TYPE.FILE,
                label: "Upload File",
                tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
                allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
                maximumSizeInMB: 2, //MB
                defaultValue: '',
                // required: true,
              },
            ],
          },
          {
            title: "Note Section",
            isDisplayTitle: false,
            children: [
              {
                name: "note",
                type: INPUT_TYPE.TEXTAREA,
                label: "Note",
                defaultValue: "",
                placeholder: "Input description (Ops)",
                required: false,
              },
            ],
          },
          {
            title: "Price Information",
            isDisplayTitle: false,
            children: [
              {
                name: "price_services_info",
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'body1',
                label: "Price  ",
                defaultValue: '(1 service)',
                style: {
                  marginTop: 1,
                },
                gridWidth: 6,
              },
              {
                name: "price_services_info_to_rp",
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'body1',
                label: "Rp.  ",
                defaultValue: '8000,000',
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY,
                variant: 'body1',
                label: "Discount",
                style: {
                  marginTop: 1
                },
                gridWidth: 6,
              },
              {
                name: "discount_info_to_rp",
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'body1',
                label: "Rp.  ",
                defaultValue: '6000,000',
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                type: INPUT_TYPE.TYPOGRAPHY,
                variant: 'body1',
                label: "VAT",
                style: {
                  marginTop: 1
                },
                gridWidth: 6,
              },
              {
                name: "vat_info_to_rp",
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'body1',
                label: "Rp.  ",
                defaultValue: '75000,000',
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                name: "total_price_info",
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'body1',
                label: "Total Price",
                style: {
                  marginTop: 1,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
              {
                name: "total_price_info_to_rp",
                type: INPUT_TYPE.TYPOGRAPHY_DYNAMIC,
                variant: 'body1',
                label: "Rp.  ",
                defaultValue: '75000,000',
                style: {
                  marginTop: 1,
                  ...justifyContentEnd,
                  fontWeight: 'bold'
                },
                gridWidth: 6,
              },
            ],
          },
        ] : [];
  
      setFields(newFields);
  
  
      // setFormValues(initialFormValues());
  
      // const [options, setOptions] = useState({});
      // setErrors(initialErrors());
      setFormValues(initialFormValues(newFields));
      setErrors(initialErrors(newFields));
      const aa = actionType
      const bb = fields;
      // debugger
      // setIsLoadedFormValues(true)
  
    }, [actionType]); // Trigger the effect whenever actionType changes
  */

  useEffect(() => {


    // // setFormValues({})
    // // setErrors({});
    // if (actionType == ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION || actionType == ANOTHER_ACTION_TYPE.ADD_SALES_ORDER) {
    //   // setIsLoadedFormValues(false)
    //   // setFormValues(initialFormValues());
    //   // setErrors(initialErrors());
    //   const aa = actionType
    //   const bb = fields;
    //   debugger;
    //   // setIsLoadedFormValues(true)
    // } else {
    //   //   setFormValues({})
    //   // setErrors({});
    // }

    // if (actionType == ACTION_TYPE.EDITED || actionType == ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION || actionType == ANOTHER_ACTION_TYPE.ADD_SALES_ORDER) {
    // setFormValues(initialFormValues(fields));
    // setErrors(initialErrors(fields));
    // setIsLoadedFormValues(true)
    // }
  }, [fields])

  // const fields = actionType === ACTION_TYPE.ADD || actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION
  //   ? [
  //     {
  //       title: "asdad",
  //       isDisplayTitle: false,
  //       children: [
  //         {
  //           name: "client_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Client Name",
  //           apiEndpoint: "/master/clients",
  //           required: false,
  //           // required: true,
  //         },
  //         {
  //           name: "sales_quotation_period",
  //           label: "Sales Quotation Period",
  //           type: INPUT_TYPE.DATE,
  //           endIcon: <img src={CalendarIcon} />,
  //           formatDate: "MMMM YYYY", // Custom date format
  //           defaultValue: dayjs("01/01/2021"),
  //           additionalProps: {
  //             placeholder: "“month” & “year”",
  //             views: ["month", "year"],
  //             // disabled: true,
  //           },
  //           // defaultValue: "",
  //           required: false,
  //         },
  //         {
  //           name: "sales_quotation_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "No. Sales Quotation",
  //           apiEndpoint: "/master/sales-quotation",
  //           required: false,
  //         },
  //       ]
  //     }
  //   ]
  //   : [
  //     {
  //       title: "Basic Information",
  //       subtitle: "Complete all employee basic information, as basic data",
  //       isDisplayTitle: false,
  //     },
  //     {
  //       title: "Personal Data",
  //       isDisplayTitle: false,
  //       children: [
  //         {
  //           name: "employe_name",
  //           type: INPUT_TYPE.TEXT,
  //           label: "Employee Name",
  //           placeholder: "Input employee name",
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 12, // Full width
  //         },
  //         {
  //           name: "birthdate",
  //           type: INPUT_TYPE.DATE,
  //           label: "Birth Date",
  //           endIcon: <img src={CalendarIcon} />,
  //           formatDate: "DD/MM/YYYY", // Custom date format
  //           // defaultValue: dayjs("01/01/2021"),
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "birthplace",
  //           type: INPUT_TYPE.TEXT,
  //           label: "Place Of Birth",
  //           placeholder: "Input place of birth",
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "gender",
  //           type: INPUT_TYPE.RADIO,
  //           label: "Gender",
  //           defaultValue: [], // Array for selected format values
  //           options: [
  //             { id: "male", name: "Male" },
  //             { id: "female", name: "Female" },
  //           ],
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "religion_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Religion",
  //           placeholder: "Select Religion",
  //           // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/religions",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "marital_status_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Marital Status",
  //           placeholder: "Select marital status",
  //           // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/marital-statuses",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "blood_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Blood Type",
  //           placeholder: "Select blood type ( Ops )",
  //           // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/bloods",
  //           required: false,
  //           gridWidth: 6, // Half width
  //         },
  //       ],
  //     },
  //     {
  //       title: "Contact Information",
  //       isDisplayTitle: false,
  //       children: [
  //         {
  //           name: "email",
  //           type: INPUT_TYPE.TEXT,
  //           label: "Email",
  //           defaultValue: "",
  //           required: true,
  //           validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  //           errorMessage: "Invalid email address",
  //           gridWidth: 12, // Full width
  //         },
  //         {
  //           name: "mobile_phone",
  //           type: INPUT_TYPE.PHONE_NUMBER,
  //           label: "Mobile Phone",
  //           placeholder: "Input mobile phone",
  //           defaultValue: "",
  //           validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
  //           errorMessage:
  //             "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "phone",
  //           type: INPUT_TYPE.NUMBER,
  //           label: "Phone",
  //           placeholder: "Input phone ( Ops )",
  //           defaultValue: "",
  //           required: false,
  //           validation: (value) => /^02\d+$/.test(value),
  //           errorMessage: "Phone should start with '02' and numeric characters",
  //           gridWidth: 6, // Half width
  //         },
  //       ],
  //     },
  //     {
  //       title: "Identity & Address",
  //       isDisplayTitle: false,
  //       children: [
  //         {
  //           name: "identity_type",
  //           type: INPUT_TYPE.RADIO,
  //           label: "Identity Type",
  //           defaultValue: [], // Array for selected format values
  //           options: [
  //             { id: "ktp", name: "KTP" },
  //             { id: "passport", name: "Passport" },
  //           ],
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "identity_number",
  //           type: INPUT_TYPE.NUMBER,
  //           label: "Identity Number",
  //           defaultValue: "",
  //           validation: (value) => /^.{1,16}$/.test(value),
  //           errorMessage: "Please enter a value with a maximum of 16 characters",
  //           required: true,
  //           gridWidth: 6,
  //         },
  //         {
  //           name: "identity_expiry",
  //           type: INPUT_TYPE.DATE,
  //           label: "Identitity Expiration Date",
  //           endIcon: <img src={CalendarIcon} />,
  //           formatDate: "DD/MM/YYYY", // Custom date format
  //           defaultValue: "",
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "postal_code",
  //           type: INPUT_TYPE.NUMBER,
  //           label: "Postal Code",
  //           placeholder: "Input postal code ( Ops )",
  //           validation: (value) => /^.{1,9}$/.test(value),
  //           errorMessage: "Please enter a value with a maximum of 9 characters",
  //           defaultValue: "",
  //           required: false,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "is_permanent",
  //           type: INPUT_TYPE.CHECKBOX,
  //           defaultValue: "", // Array for selected format values
  //           options: [{ id: 1, name: "Permanent" }],
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "citizen_id_address",
  //           type: INPUT_TYPE.TEXTAREA,
  //           label: "Citizen ID Address",
  //           placeholder: "Input citizen id address",
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 12, // Half width
  //         },
  //         {
  //           name: "use_as_residential_address",
  //           type: INPUT_TYPE.CHECKBOX,
  //           defaultValue: "", // Array for selected format values
  //           options: [{ id: 1, name: "Use as residential address" }],
  //           gridWidth: 12, // Half width
  //         },
  //         {
  //           name: "residential_address",
  //           type: INPUT_TYPE.TEXTAREA,
  //           label: "Residential Address",
  //           placeholder: "Input residential address",
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 12, // Half width
  //         },
  //       ],
  //     },
  //     {
  //       title: "Sub Service",
  //       isDisplayTitle: false,
  //       children: [
  //         {
  //           name: "name",
  //           type: INPUT_TYPE.TEXT,
  //           label: "Full Name",
  //           placeholder: "Input full name",
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 6, // Full width
  //         },
  //         {
  //           name: "relation_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Relationship",
  //           placeholder: "Select relationship",
  //           defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/family-relations",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "birthdate",
  //           type: INPUT_TYPE.DATE,
  //           label: "Birth Date",
  //           endIcon: <img src={CalendarIcon} />,
  //           formatDate: "DD/MM/YYYY", // Custom date format
  //           defaultValue: "",
  //           gridWidth: 6, // Half width
  //           required: true,
  //         },
  //         {
  //           name: "gender",
  //           type: INPUT_TYPE.RADIO,
  //           label: "Gender",
  //           defaultValue: [], // Array for selected format values
  //           options: [
  //             { id: "male", name: "Male" },
  //             { id: "female", name: "Female" },
  //           ],
  //           gridWidth: 6, // Half width
  //           required: true,
  //         },
  //         {
  //           name: "religion_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Religion",
  //           placeholder: "Select religion ( Ops )",
  //           defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/religions",
  //           required: false,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "marital_status_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Marital Status",
  //           placeholder: "Select marital status ( Ops )",
  //           defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/marital-statuses",
  //           required: false,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "identity_number",
  //           type: INPUT_TYPE.NUMBER,
  //           label: "Identity Number",
  //           placeholder: "Input identity number ( Ops )",
  //           defaultValue: "",
  //           validation: (value) => /^.{1,16}$/.test(value),
  //           errorMessage: "Please enter a value with a maximum of 16 characters",
  //           required: false,
  //           gridWidth: 6,
  //         },
  //         {
  //           name: "job_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Job",
  //           placeholder: "Select job ( Ops )",
  //           defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/jobs",
  //           required: false,
  //           gridWidth: 6, // Half width
  //         },
  //       ],
  //     },
  //     {
  //       title: "Attachment",
  //       isDisplayTitle: false,
  //       children: [
  //         {
  //           name: "name",
  //           type: INPUT_TYPE.TEXT,
  //           label: "Full Name",
  //           placeholder: "Input full name",
  //           defaultValue: "",
  //           required: true,
  //           gridWidth: 6, // Full width
  //         },
  //         {
  //           name: "relation_id",
  //           type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  //           label: "Relationship",
  //           placeholder: "Select relationship",
  //           defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //           apiEndpoint: "/master/family-relations",
  //           required: true,
  //           gridWidth: 6, // Half width
  //         },
  //         {
  //           name: "phone",
  //           type: INPUT_TYPE.PHONE_NUMBER,
  //           label: "Phone Number",
  //           placeholder: "Input phone number",
  //           defaultValue: "",
  //           required: true,
  //           validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
  //           errorMessage:
  //             "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
  //           gridWidth: 12, // Half width
  //         },
  //       ],
  //     },
  //   ];


  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.salesquotations);


  // const rows = [
  //   {
  //     "id": 1,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT.Kompas Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Waiting",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 2,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Rejected",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 3,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Approved",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 4,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Approved",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 5,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Approved",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 6,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Waiting",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 7,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Waiting",
  //     "date_deal": '12/03/2023'
  //   },
  //   {
  //     "id": 8,
  //     "code": "293/PPM/SQ/XII/2022",
  //     "client_name": "PT. Mayora Tbk",
  //     "service_categories": [
  //       {
  //         "service": {
  //           "id": 1,
  //           "name": "Main Service 1",
  //           "status": "active"
  //         },
  //         "sub_services": [
  //           {
  //             "sub_service_id": 3,
  //             "name": "Sub Service 2",
  //             "status": "active"
  //           },
  //           {
  //             "sub_service_id": 2,
  //             "name": "Sub Service 1",
  //             "status": "active"
  //           }
  //         ],
  //       },
  //     ],
  //     "total_price": "Rp. 29.000.000",
  //     "date": "24/02/2023",
  //     "created_by": "Howard Esther",
  //     "status": "Waiting",
  //     "date_deal": '12/03/2023'
  //   }
  // ];


  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    if (data) {
      setDetailRowV2(data);
    }
  }, [data]);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      // Check if the field has a children array
      if (field.children) {
        field.children.forEach((child) => {
          const { name, apiEndpoint } = child;
          if (apiEndpoint) {
            if (!options.hasOwnProperty(name)) {
              fetchOptions(apiEndpoint, name);
            }
          }
        });
      } else {
        // If there's no children array, use the field directly
        const { name, apiEndpoint } = field;
        if (apiEndpoint) {
          if (!options.hasOwnProperty(name)) {
            fetchOptions(apiEndpoint, name);
          }
        }
      }
    });
  }, [options]);


  useEffect(() => {
    if (status === ACTION_TYPE.DEFAULT_STATUS) {
      setType(ACTION_TYPE.ALL_TYPE);
    }
  }, [status]);

  useEffect(() => {
    if (
      // type !== ACTION_TYPE.DEFAULT_TYPE &&
      status !== ACTION_TYPE.DEFAULT_STATUS
    ) {

      dispatch(
        getSalesQuotation({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          category,
          dateFilter,
          startDate,
          endDate,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [type, status]);

  // useEffect(() => {
  //   if (type !== ACTION_TYPE.DEFAULT_TYPE) {
  //     dispatch(
  //       getSalesQuotation({
  //         token,
  //         limit,
  //         search,
  //         status,
  //         sortDirection,
  //         sortBy,
  //         type,
  //       })
  //     );
  //   } else {
  //     setSelectedData([]); //buat disabled button toolbar
  //   }
  // }, [type]);


  const handleAddFieldRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Sub Service" && initialSubService,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Sub Service"
        && initialErrorSubService
        ,
      ], // Add an empty error object
    }));
  };

  const handleRemoveFieldRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));

    if (fields.length && (actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION || actionType === ACTION_TYPE.EDITED)) {
      const updatedFields = fields.map((field) => {
        if (field.title === "Sub Service" && field.children) {
          return {
            ...field,
            children: field.children.map((subField, index) => {
              if (subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
                if (subField.name === "sub_service_id") {
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED ||  actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                      // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };
                } else {
                  // return {
                  //   ...subField,
                  //   additionalProps: {
                  //     ...subField.additionalProps,
                  //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
                  //   },
                  // };
                }
              } else {
                if (subField.name === "discount" || subField.name === "vat_percent" || subField.name === "subtotal" || subField.name === "criteria_id") {
                  // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: true
                      // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                      // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };
                }
              }
              return subField;
            }),
          };
        }
        return field;
      });
      const a = formValues;
      const is = formValues['Service'] &&
        (formValues['Service']['service_id'] === undefined ||
          formValues['Service']['service_id'] === null);


      // debugger;
      //       setFields(updatedFields);

    }
    if (actionType === ACTION_TYPE.ADD || ACTION_TYPE.EDITED) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        updatedValues['Price Information']['price_services_info'] = `(${updatedValues['Sub Service'].length} service)`;

        // Calculate the sum of subtotals, discounts, vat_to_rp, and total_to_rp
        const subServiceValues = updatedValues['Sub Service'];

        // OLD
        //       const subtotalSum = subServiceValues.reduce((a, b) => a + (b.subtotal ? Number(b.subtotal) : 0), 0);
        //       const discountSum = subServiceValues.reduce((a, b) => a + (b.discount ?  (b.subtotal * Number(b.discount)/100) : 0), 0);
        //       const vatToRpSum = subServiceValues.reduce((a, b) => a + (b.vat_to_rp ? Number(b.vat_to_rp) : 0), 0);
        //       const totalToRpSum = subServiceValues.reduce((a, b) => a + (b.total_to_rp ? Number(b.total_to_rp) : 0), 0);

        //  NEW
        const subtotalSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined) {
            return a + Number(b.subtotal);
          } else {
            return a;
          }
        }, 0);

        const discountSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined && b.discount !== undefined) {
            return a + (b.subtotal * Number(b.discount) / 100);
          } else {
            return a;
          }
        }, 0);

        const vatToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.vat_to_rp !== undefined) {
            return a + Number(b.vat_to_rp);
          } else {
            return a;
          }
        }, 0);

        const totalToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.total_to_rp !== undefined) {
            return a + Number(b.total_to_rp);
          } else {
            return a;
          }
        }, 0);

        // Round the numeric values to four decimal places before assigning them
        updatedValues['Price Information']['price_services_info_to_rp'] = parseFloat(subtotalSum.toFixed(4));
        updatedValues['Price Information']['discount_info_to_rp'] = parseFloat(discountSum.toFixed(4));
        updatedValues['Price Information']['vat_info_to_rp'] = parseFloat(vatToRpSum.toFixed(4));
        updatedValues['Price Information']['total_price_info_to_rp'] = parseFloat(totalToRpSum.toFixed(4));


        return updatedValues;
      });
    }
  };

  // BARU
  useEffect(() => {

    // setIsLoadedFormValues(false)
    // ADD_SALES_ORDER
    if (fields.length && (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED)) {
      const updatedFields = fields.map((field) => {
        if (field.title === "Sub Service" && field.children) {
          return {
            ...field,
            children: field.children.map((subField, index) => {
              if (subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
                if (subField.name === "sub_service_id") {
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                      // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };
                } else {
                  // return {
                  //   ...subField,
                  //   additionalProps: {
                  //     ...subField.additionalProps,
                  //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
                  //   },
                  // };
                }
              } else {
                if (subField.name === "discount" || subField.name === "vat_percent" || subField.name === "subtotal" || subField.name === "criteria_id") {
                  // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: true
                      // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                      // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };
                }
              }
              return subField;
            }),
          };
        }
        return field;
      });
      const a = formValues;
      const is = formValues['Service'] &&
        (formValues['Service']['service_id'] === undefined ||
          formValues['Service']['service_id'] === null);


      // debugger;
      //     setFields(updatedFields);

    }


    if (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        updatedValues['Price Information']['price_services_info'] = `(${updatedValues['Sub Service'].length} service)`;

        // Calculate the sum of subtotals, discounts, vat_to_rp, and total_to_rp
        const subServiceValues = updatedValues['Sub Service'];



        // OLD
        //       const subtotalSum = subServiceValues.reduce((a, b) => a + (b.subtotal ? Number(b.subtotal) : 0), 0);
        //       const discountSum = subServiceValues.reduce((a, b) => a + (b.discount ?  (b.subtotal * Number(b.discount)/100) : 0), 0);
        //       const vatToRpSum = subServiceValues.reduce((a, b) => a + (b.vat_to_rp ? Number(b.vat_to_rp) : 0), 0);
        //       const totalToRpSum = subServiceValues.reduce((a, b) => a + (b.total_to_rp ? Number(b.total_to_rp) : 0), 0);

        //  NEW
        const subtotalSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined) {
            return a + Number(b.subtotal);
          } else {
            return a;
          }
        }, 0);

        const discountSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined && b.discount !== undefined) {
            return a + (b.subtotal * Number(b.discount) / 100);
          } else {
            return a;
          }
        }, 0);

        const vatToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.vat_to_rp !== undefined) {
            return a + Number(b.vat_to_rp);
          } else {
            return a;
          }
        }, 0);

        const totalToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.total_to_rp !== undefined) {
            return a + Number(b.total_to_rp);
          } else {
            return a;
          }
        }, 0);


        // Round the numeric values to four decimal places before assigning them
        updatedValues['Price Information']['price_services_info_to_rp'] = parseFloat(subtotalSum.toFixed(4));
        updatedValues['Price Information']['discount_info_to_rp'] = parseFloat(discountSum.toFixed(4));
        updatedValues['Price Information']['vat_info_to_rp'] = parseFloat(vatToRpSum.toFixed(4));
        updatedValues['Price Information']['total_price_info_to_rp'] = parseFloat(totalToRpSum.toFixed(4));


        return updatedValues;
      });
    }


    //  CHECK_SALES_QUOTATION


    // console.log(formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null, 'NOTTTT ')

    // }, [formValues, fields, actionType]);
  }, [actionType]);

  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!isNaN(rowIndex)) {
        console.log(1);
        updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
      } else {
        console.log(2);
        updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
      }

      return updatedValues;
    });
  };

  const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
        return updatedValues;
      });

    }
  };

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    fileIndex
  ) => {
    console.log(file)
    console.log(rowIndex)
    console.log(sectionTitle)
    console.log(fieldName)
    console.log(fileIndex)
    // debugger;
    if (!isNaN(rowIndex)) {
      console.log(1)
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];
        // debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = '';
        }

        return updatedValues;
      });

    } else {
      console.log(2)
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];
        // debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });

    }
  };

  const handleInputChange = async (
    event,
    index,
    sectionTitle,
    field,
    fileIndex
  ) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, 'SOBARI')
    // debugger;

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
          }

          return updatedErrors;
        });
        return;
        // }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = "";
          }
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => (i === index ? { ...item, [name]: value } : item)
          );
        } else {
          updatedValues[sectionTitle] = {
            ...updatedValues[sectionTitle],
            [name]: value,
          }

        }
        return updatedValues;

      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
            (item, i) =>
              i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          updatedErrors[sectionTitle] = {
            ...updatedErrors[sectionTitle],
            [name]: fieldErrors,
          };
        };
        return updatedErrors;
      });

    }

    if (name === "service_id") {
      if (value) {
        await fetchSubServices(value);
      } else {
        // Reset Sub Service ketika service_id nya null
        setFormValues((prevValues) => {
          const updatedValues = { ...prevValues };
          updatedValues['Sub Service'] = [initialSubService]
          return updatedValues;
        });
      }
    } else if (name === 'sub_service_id' || name === 'price' || name === 'subtotal' || name === 'discount' || name === 'vat_percent') {
      // const dataService = options['sub_service_id'].find(item => item.id === formValues['Sub Service'][index]['sub_service_id']);

      if (name === 'sub_service_id') {
        if (value) {

          setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (index !== undefined) {
              updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
                (item, i) => {
                  if (i === index) {
                    const newItem = {
                      ...item,
                      [name]: value,
                    };

                    return newItem;
                  } else {
                    return item;
                  }
                }
              );
            }
            return updatedValues;

          });

          setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (index !== undefined) {
              const dataService = options['sub_service_id'].find(item => item.id === updatedValues[sectionTitle][index]['sub_service_id']);
              //               debugger;
              updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
                (item, i) => {
                  if (i === index) {
                    if (dataService) {

                      const currency = updatedValues['Service']['currency'];

                      /* OLD
                      const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
                                              (currency === 'USD') ? 'sell_price_dollar' :
                                              'sell_price_rp'; // Default to 'sell_price_rp' if not 'Rp' or 'USD'

                      const totalDiscount = Number(item.discount) > 0 ?
                                            (Number(item.subtotal) || dataService[basicPriceField]) * (Number(item.discount) / 100) :
                                            0;

                      const totalVat = ((Number(item.subtotal) || dataService[basicPriceField]) *
                                      (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;

                      const newItem = {
                        ...item,
                        subtotal: Number(item.subtotal) || dataService[basicPriceField],
                        vat_to_rp: totalVat,
                        total_to_rp: (Number(item.subtotal) || dataService[basicPriceField]) + totalVat - totalDiscount,
                      };
                      */

                      const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
                        (currency === 'USD') ? 'sell_price_dollar' :
                          'basic_price'; // Default to 'sell_price_rp' if not 'Rp' or 'USD'

                      const totalDiscount = Number(item.discount) > 0 ?
                        (dataService[basicPriceField] || Number(item.subtotal)) * (Number(item.discount) / 100) :
                        0;

                      const totalTanpaVAT = (dataService[basicPriceField] || Number(item.subtotal)) - totalDiscount
                      console.log(totalTanpaVAT, 'HMM1 --- totallll')
                      /*// OLD const totalVat = ((dataService[basicPriceField] || Number(item.subtotal)) *
                                        (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;*/
                      const totalVat = (totalTanpaVAT *
                        (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;

                      console.log('HMM2', (dataService[basicPriceField] || Number(item.subtotal)) + totalVat - totalDiscount)

                      const newItem = {
                        ...item,
                        price: dataService[basicPriceField] || Number(item.price),
                        quantity: item.quantity,
                        subtotal: (dataService[basicPriceField] || Number(item.price)) * item.quantity,
                        vat_to_rp: totalVat,
                        total_to_rp: ((dataService[basicPriceField] || Number(item.price)) * item.quantity) + totalVat - totalDiscount,
                      };

                      return newItem;
                    }
                  } else {
                    return item;
                  }
                }
              );
            }
            return updatedValues;
          });

          await fetchCriteriaBySubServiceId(value);

        } else {
          // kalau sub servicenya lebih dari satu bisa dihapus
          if (formValues[sectionTitle].length > 1) {
            handleRemoveFieldRow(sectionTitle, index)
            return;
          } else {
            setFormValues((prevValues) => {
              const updatedValues = { ...prevValues };
              updatedValues['Sub Service'] = [initialSubService]
              return updatedValues;
            });
            return
            //             setFormValues((prevValues) => {
            //               const updatedValues = { ...prevValues };
            //               if (index !== undefined) {
            //                 updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            //                   (item, i) => {
            //                     if (i === index) {
            //                       const newItem = {
            //                         ...item,
            //                         [name]: value,
            //                       };
            //
            //                       return newItem;
            //                     } else {
            //                       return item;
            //                     }
            //                   }
            //                 );
            //               }
            //               return updatedValues;
            //
            //             });
          }
        }
      }

      //       Old

      //      setFormValues((prevValues) => {
      //         const updatedValues = { ...prevValues };
      //         if (index !== undefined) {
      //           updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
      //             (item, i) => {
      //               if (i === index) {
      //                 const newItem = {
      //                   ...item,
      //                   [name]: value,
      //                 };
      //
      //                 return newItem;
      //               } else {
      //                 return item;
      //               }
      //             }
      //           );
      //         }
      //         return updatedValues;
      //
      //       });
      //
      //       setFormValues((prevValues) => {
      //         const updatedValues = { ...prevValues };
      //         if (index !== undefined) {
      //           const dataService = options['sub_service_id'].find(item => item.id === updatedValues[sectionTitle][index]['sub_service_id']);
      //           debugger;
      //           updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
      //             (item, i) => {
      //               if (i === index) {
      //                 if (dataService) {
      //
      //                   const currency = updatedValues['Service']['currency'];
      //
      //                   /* OLD
      //                   const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
      //                                           (currency === 'USD') ? 'sell_price_dollar' :
      //                                           'sell_price_rp'; // Default to 'sell_price_rp' if not 'Rp' or 'USD'
      //
      //                   const totalDiscount = Number(item.discount) > 0 ?
      //                                         (Number(item.subtotal) || dataService[basicPriceField]) * (Number(item.discount) / 100) :
      //                                         0;
      //
      //                   const totalVat = ((Number(item.subtotal) || dataService[basicPriceField]) *
      //                                   (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;
      //
      //                   const newItem = {
      //                     ...item,
      //                     subtotal: Number(item.subtotal) || dataService[basicPriceField],
      //                     vat_to_rp: totalVat,
      //                     total_to_rp: (Number(item.subtotal) || dataService[basicPriceField]) + totalVat - totalDiscount,
      //                   };
      //                   */
      //
      //                    const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
      //                                           (currency === 'USD') ? 'sell_price_dollar' :
      //                                           'sell_price_rp'; // Default to 'sell_price_rp' if not 'Rp' or 'USD'
      //
      //                   const totalDiscount = Number(item.discount) > 0 ?
      //                                         (dataService[basicPriceField] || Number(item.subtotal)) * (Number(item.discount) / 100) :
      //                                         0;
      //
      //                   const totalVat = ((dataService[basicPriceField] || Number(item.subtotal)) *
      //                                     (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;
      //
      //                   const newItem = {
      //                     ...item,
      //                     subtotal: dataService[basicPriceField] || Number(item.subtotal),
      //                     vat_to_rp: totalVat,
      //                     total_to_rp: (dataService[basicPriceField] || Number(item.subtotal)) + totalVat - totalDiscount,
      //                   };
      //
      //                   return newItem;
      //                 }
      //               } else {
      //                 return item;
      //               }
      //             }
      //           );
      //         }
      //         return updatedValues;
      //       });


      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => {
              if (i === index) {
                const newItem = {
                  ...item,
                  [name]: value,
                };

                return newItem;
              } else {
                return item;
              }
            }
          );
        }
        return updatedValues;

      });

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const currency = updatedValues['Service']['currency'];

        const updateItem = (item, optionsKey, indexSubService) => {
          const newItem = { ...item, [name]: value };
          //         const idProperty = (optionsKey === 'criteria_id') ? 'id' : 'service_id';
          const idProperty = 'id';
          const dataService = options[optionsKey].find(opt => opt[idProperty] === newItem[(optionsKey === 'criteria_id') ? 'criteria_id' : 'sub_service_id']);
          const ziz = options[optionsKey];
          //         debugger;

          if (dataService) {
            const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
              (currency === 'USD') ? 'sell_price_dollar' :
                'basic_price'; // Default to 'sell_price_rp' if not 'Rp' or 'USD'

            const totalDiscount = Number(item.discount) > 0 ?
              (Number(item.subtotal) || dataService[basicPriceField]) * (Number(item.discount) / 100) :
              0;


            const totalTanpaVAT = (Number(item.subtotal) || dataService[basicPriceField]) - totalDiscount
            console.log(totalTanpaVAT, 'HMM1 --- totallll')
            /*// OLD const totalVat = ((dataService[basicPriceField] || Number(item.subtotal)) *
                              (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;*/
            const totalVat = (totalTanpaVAT *
              (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;

            console.log('HMM2', (Number(item.subtotal) || dataService[basicPriceField]) + totalVat - totalDiscount)

            const newItem = {
              ...item,
              price: Number(item.price) || dataService[basicPriceField],
              quantity: item.quantity,
              subtotal: (Number(item.price) || dataService[basicPriceField]) * item.quantity,
              vat_to_rp: totalVat,
              total_to_rp: ((Number(item.price) || dataService[basicPriceField]) * item.quantity) + totalVat - totalDiscount,
            };
            updatedValues['Sub Service'][indexSubService] = newItem;
          }
        };

        // Assuming 'Sub Service' is an array in updatedValues
        updatedValues['Sub Service'].forEach((item, i) => {
          if (item['sub_service_id']) {
            if (!item['criteria_id']) {
              updateItem(item, 'sub_service_id', i)
            } else {
              updateItem(item, 'criteria_id', i);
            }
          }
        });

        return updatedValues;
      });


    } else if (name === 'criteria_id') {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map((item, i) => {
            if (i === index) {
              const newItem = { ...item, [name]: value };

              const optionsKey = value ? 'criteria_id' : 'sub_service_id';
              // const idProperty = value ? 'id' : 'service_id';
              const idProperty = 'id'
              const dataService = options[optionsKey].find(opt => opt[idProperty] === newItem[value ? 'criteria_id' : 'sub_service_id']);
              // debugger;

              if (dataService) {
                const currency = updatedValues['Service']['currency'];
                const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
                  (currency === 'USD') ? 'sell_price_dollar' :
                    'basic_price'; // Default to 'basic_price' if not 'Rp' or 'USD'

                const totalDiscount = Number(newItem.discount) > 0 ?
                  ((dataService[basicPriceField] || Number(newItem.price)) * newItem.quantity) * (Number(newItem.discount) / 100) :
                  0;


                const totalTanpaVAT = ((dataService[basicPriceField] || Number(newItem.price)) * newItem.quantity) - totalDiscount
                console.log(totalTanpaVAT, 'HMM1 --- totallll')
                /*// OLD const totalVat = ((dataService[basicPriceField] || Number(newItem.subtotal)) *
                              (newItem.vat_percent && newItem.vat_percent.length ? (Number(newItem.vat_percent[0]) / 100) : 0)) || 0;*/

                const totalVat = (totalTanpaVAT *
                  (newItem.vat_percent && newItem.vat_percent.length ? (Number(newItem.vat_percent[0]) / 100) : 0)) || 0;

                console.log('HMM2', (dataService[basicPriceField] || Number(newItem.subtotal)) + totalVat - totalDiscount)


                newItem.price = dataService[basicPriceField] || Number(newItem.price);
                newItem.quantity = newItem.quantity;
                newItem.subtotal = (dataService[basicPriceField] || Number(newItem.price)) * newItem.quantity;
                newItem.vat_to_rp = totalVat;
                newItem.total_to_rp = ((dataService[basicPriceField] || Number(newItem.price)) * newItem.quantity) + totalVat - totalDiscount


              }

              return newItem;
            } else {
              return item;
            }
          });
        }

        return updatedValues;
      });


    } else if (name === 'currency') {

      const currency = value;

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => {
              if (i === index) {
                const newItem = {
                  ...item,
                  [name]: value,
                };

                return newItem;
              } else {
                return item;
              }
            }
          );
        }
        return updatedValues;

      });

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        const updateItem = (item, optionsKey, indexSubService) => {
          const newItem = { ...item, [name]: value };
          //         const idProperty = (optionsKey === 'criteria_id') ? 'id' : 'service_id';
          const idProperty = 'id';
          const dataService = options[optionsKey].find(opt => opt[idProperty] === newItem[(optionsKey === 'criteria_id') ? 'criteria_id' : 'sub_service_id']);
          const ziz = options[optionsKey];
          //         debugger;

          if (dataService) {
            const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
              (currency === 'USD') ? 'sell_price_dollar' :
                'basic_price'; // Default to 'sell_price_rp' if not 'Rp' or 'USD'

            const totalDiscount = Number(item.discount) > 0 ?
              (dataService[basicPriceField] || Number(item.subtotal)) * (Number(item.discount) / 100) :
              0;


            const totalTanpaVAT = (dataService[basicPriceField] || Number(item.subtotal)) - totalDiscount
            console.log(totalTanpaVAT, 'HMM1 --- totallll')
            /*// OLD const totalVat = ((dataService[basicPriceField] || Number(item.subtotal)) *
                          (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;*/

            const totalVat = (totalTanpaVAT *
              (item.vat_percent && item.vat_percent.length ? (Number(item.vat_percent[0]) / 100) : 0)) || 0;


            console.log('HMM2', (dataService[basicPriceField] || Number(item.subtotal)) + totalVat - totalDiscount)


            const newItem = {
              ...item,
              price: dataService[basicPriceField] || Number(item.price),
              quantity: item.quantity,
              subtotal: (dataService[basicPriceField] || Number(item.price)) * item.quantity,
              vat_to_rp: totalVat,
              total_to_rp: ((dataService[basicPriceField] || Number(item.price)) * item.quantity) + totalVat - totalDiscount,
            };
            updatedValues['Sub Service'][indexSubService] = newItem;
          }
        };

        // Assuming 'Sub Service' is an array in updatedValues
        updatedValues['Sub Service'].forEach((item, i) => {
          if (item['sub_service_id']) {
            if (!item['criteria_id']) {
              updateItem(item, 'sub_service_id', i)
            } else {
              updateItem(item, 'criteria_id', i);
            }
          }
        });

        return updatedValues;
      });


    } else if (name === 'quantity') {
      setFormValues((prevValues) => {
        const updatedValues = { ...formValues };

        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map((item, i) => {
            if (i === index) {
              const newItem = { ...item, [name]: value };
              const dataService = options['sub_service_id'].find(item => item.id === updatedValues[sectionTitle][index]['sub_service_id']);

              if (dataService) {
                const currency = updatedValues['Service']['currency'];
                const basicPriceField = (currency === 'Rp') ? 'sell_price_rp' :
                  (currency === 'USD') ? 'sell_price_dollar' :
                    'basic_price'; // Default to 'basic_price' if not 'Rp' or 'USD'

                const totalDiscount = Number(newItem.discount) > 0 ?
                  ((Number(newItem.price) || dataService[basicPriceField]) * value) * (Number(newItem.discount) / 100) :
                  0;

                const totalTanpaVAT = ((Number(newItem.price) || dataService[basicPriceField]) * value) - totalDiscount;
                const totalVat = (totalTanpaVAT *
                  (newItem.vat_percent && newItem.vat_percent.length ? (Number(newItem.vat_percent[0]) / 100) : 0)) || 0;

                newItem.price = Number(newItem.price) || dataService[basicPriceField];
                newItem.subtotal = totalTanpaVAT;
                newItem.vat_to_rp = totalVat;
                newItem.total_to_rp = totalTanpaVAT + totalVat - totalDiscount;
              }
              return newItem;
            } else {
              return item;
            }
          });
        }
        return updatedValues;
      });
    }
    /*else if (name === "client_id" || name === "periode") {
          const [clientId, periode] = [formValues['Check Sales Quotation']['client_id'], value];
    
          if (periode) {
            await fetchMasterSalesQuotation(clientId,periode);
          }
        }*/


    // BARU

    if (fields.length && (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED)) {
      const updatedFields = fields.map((field) => {
        if (field.title === "Sub Service" && field.children) {
          return {
            ...field,
            children: field.children.map((subField, index) => {
              if (subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
                if (subField.name === "sub_service_id") {
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)),// || actionType === ACTION_TYPE.EDITED , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                      // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null

                    },
                  };
                } else {
                  // return {
                  //   ...subField,
                  //   additionalProps: {
                  //     ...subField.additionalProps,
                  //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
                  //   },
                  // };
                }
              } else {
                if (subField.name === "price" || subField.name === "quantity" || subField.name === "discount" || subField.name === "vat_percent" || subField.name === "subtotal" || subField.name === "criteria_id") {
                  // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: true
                      // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                      // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };
                }
              }
              return subField;
            }),
          };
        }
        return field;
      });
      const a = formValues;
      const is = formValues['Service'] &&
        (formValues['Service']['service_id'] === undefined ||
          formValues['Service']['service_id'] === null);


      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        updatedValues['Price Information']['price_services_info'] = `(${updatedValues['Sub Service'].length} service)`;

        // Calculate the sum of subtotals, discounts, vat_to_rp, and total_to_rp
        const subServiceValues = updatedValues['Sub Service'];

        // OLD
        //       const subtotalSum = subServiceValues.reduce((a, b) => a + (b.subtotal ? Number(b.subtotal) : 0), 0);
        //       const discountSum = subServiceValues.reduce((a, b) => a + (b.discount ?  (b.subtotal * Number(b.discount)/100) : 0), 0);
        //       const vatToRpSum = subServiceValues.reduce((a, b) => a + (b.vat_to_rp ? Number(b.vat_to_rp) : 0), 0);
        //       const totalToRpSum = subServiceValues.reduce((a, b) => a + (b.total_to_rp ? Number(b.total_to_rp) : 0), 0);

        //  NEW
        const subtotalSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined) {
            return a + Number(b.subtotal);
          } else {
            return a;
          }
        }, 0);

        const discountSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined && b.discount !== undefined) {
            return a + (b.subtotal * Number(b.discount) / 100);
          } else {
            return a;
          }
        }, 0);

        const vatToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.vat_to_rp !== undefined) {
            return a + Number(b.vat_to_rp);
          } else {
            return a;
          }
        }, 0);

        const totalToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.total_to_rp !== undefined) {
            return a + Number(b.total_to_rp);
          } else {
            return a;
          }
        }, 0);

        // Round the numeric values to four decimal places before assigning them
        updatedValues['Price Information']['price_services_info_to_rp'] = parseFloat(subtotalSum.toFixed(4));
        updatedValues['Price Information']['discount_info_to_rp'] = parseFloat(discountSum.toFixed(4));
        updatedValues['Price Information']['vat_info_to_rp'] = parseFloat(vatToRpSum.toFixed(4));
        updatedValues['Price Information']['total_price_info_to_rp'] = parseFloat(totalToRpSum.toFixed(4));


        return updatedValues;
      });
      // debugger;
      //       setFields(updatedFields);

    }
    if (actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER_FROM_SALES_QUOTATION || actionType === ACTION_TYPE.EDITED) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        updatedValues['Price Information']['price_services_info'] = `(${updatedValues['Sub Service'].length} service)`;

        // Calculate the sum of subtotals, discounts, vat_to_rp, and total_to_rp
        const subServiceValues = updatedValues['Sub Service'];

        // OLD
        //       const subtotalSum = subServiceValues.reduce((a, b) => a + (b.subtotal ? Number(b.subtotal) : 0), 0);
        //       const discountSum = subServiceValues.reduce((a, b) => a + (b.discount ?  (b.subtotal * Number(b.discount)/100) : 0), 0);
        //       const vatToRpSum = subServiceValues.reduce((a, b) => a + (b.vat_to_rp ? Number(b.vat_to_rp) : 0), 0);
        //       const totalToRpSum = subServiceValues.reduce((a, b) => a + (b.total_to_rp ? Number(b.total_to_rp) : 0), 0);

        //  NEW
        const subtotalSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined) {
            return a + Number(b.subtotal);
          } else {
            return a;
          }
        }, 0);

        const discountSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined && b.discount !== undefined) {
            return a + (b.subtotal * Number(b.discount) / 100);
          } else {
            return a;
          }
        }, 0);

        const vatToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.vat_to_rp !== undefined) {
            return a + Number(b.vat_to_rp);
          } else {
            return a;
          }
        }, 0);

        const totalToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.total_to_rp !== undefined) {
            return a + Number(b.total_to_rp);
          } else {
            return a;
          }
        }, 0);

        // Round the numeric values to four decimal places before assigning them
        updatedValues['Price Information']['price_services_info_to_rp'] = parseFloat(subtotalSum.toFixed(4));
        updatedValues['Price Information']['discount_info_to_rp'] = parseFloat(discountSum.toFixed(4));
        updatedValues['Price Information']['vat_info_to_rp'] = parseFloat(vatToRpSum.toFixed(4));
        updatedValues['Price Information']['total_price_info_to_rp'] = parseFloat(totalToRpSum.toFixed(4));

        return updatedValues;
      });
    }

  };


  // const handleInputChange = (event, index, sectionTitle, field) => {
  //   const { name, value } = event.target;
  //   const updatedErrors = { ...errors };

  //   setFormValues((prevValues) => {
  //     const updatedValues = { ...prevValues };
  //     if (
  //       index !== undefined &&
  //       (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
  //     ) {
  //       updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
  //         (item, i) => (i === index ? { ...item, [name]: value } : item)
  //       );
  //     } else {
  //       updatedValues[sectionTitle] = {
  //         ...updatedValues[sectionTitle],
  //         [name]: value,
  //       };
  //     }

  //     // Buat column residential address
  //     if (name === "use_as_residential_address" && value.includes(1)) {
  //       updatedValues[sectionTitle]["residential_address"] =
  //         updatedValues[sectionTitle]["citizen_id_address"];
  //     }

  //     if (
  //       name === "residential_address" &&
  //       value !== updatedValues[sectionTitle]["citizen_id_address"]
  //     ) {
  //       updatedValues[sectionTitle]["use_as_residential_address"] = [];
  //     }

  //     if (
  //       name === "citizen_id_address" &&
  //       updatedValues[sectionTitle]["use_as_residential_address"].length > 0 &&
  //       updatedValues[sectionTitle]["use_as_residential_address"].includes(1)
  //     ) {
  //       updatedValues[sectionTitle]["residential_address"] =
  //         updatedValues[sectionTitle]["citizen_id_address"];
  //     }

  //     return updatedValues;
  //   });

  //   setErrors((prevErrors) => {
  //     const fieldValue = value;
  //     const fieldErrors = validateFieldHelper(fieldValue, field);

  //     if (
  //       index !== undefined &&
  //       (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
  //     ) {
  //       updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
  //         (item, i) =>
  //           i === index ? { ...item, [name]: fieldErrors } : { ...item }
  //       );
  //     } else {
  //       updatedErrors[sectionTitle] = {
  //         ...updatedErrors[sectionTitle],
  //         [name]: fieldErrors,
  //       };
  //     }
  //     return updatedErrors;
  //   });
  // };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
    // debugger;
    if (fields.length && (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED)) {
      const updatedFields = fields.map((field) => {
        if (field.title === "Sub Service" && field.children) {
          return {
            ...field,
            children: field.children.map((subField, index) => {
              if (subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
                if (subField.name === "sub_service_id") {
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                      // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null

                    },
                  };
                } else {
                  // return {
                  //   ...subField,
                  //   additionalProps: {
                  //     ...subField.additionalProps,
                  //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
                  //   },
                  // };
                }
              } else {
                if (subField.name === "price" || subField.name === "quantity" || subField.name === "discount" || subField.name === "vat_percent" || subField.name === "subtotal" || subField.name === "criteria_id") {
                  // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
                  return {
                    ...subField,
                    additionalProps: {
                      ...subField.additionalProps,
                      disabled: true
                      // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                      // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };
                }
              }
              return subField;
            }),
          };
        }
        return field;
      });
      const a = formValues;
      const is = formValues['Service'] &&
        (formValues['Service']['service_id'] === undefined ||
          formValues['Service']['service_id'] === null);


      // debugger;
      //       setFields(updatedFields);

    }
    if (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };

        updatedValues['Price Information']['price_services_info'] = `(${updatedValues['Sub Service'].length} service)`;

        // Calculate the sum of subtotals, discounts, vat_to_rp, and total_to_rp
        const subServiceValues = updatedValues['Sub Service'];

        // OLD
        //       const subtotalSum = subServiceValues.reduce((a, b) => a + (b.subtotal ? Number(b.subtotal) : 0), 0);
        //       const discountSum = subServiceValues.reduce((a, b) => a + (b.discount ?  (b.subtotal * Number(b.discount)/100) : 0), 0);
        //       const vatToRpSum = subServiceValues.reduce((a, b) => a + (b.vat_to_rp ? Number(b.vat_to_rp) : 0), 0);
        //       const totalToRpSum = subServiceValues.reduce((a, b) => a + (b.total_to_rp ? Number(b.total_to_rp) : 0), 0);

        //  NEW
        const subtotalSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined) {
            return a + Number(b.subtotal);
          } else {
            return a;
          }
        }, 0);

        const discountSum = subServiceValues.reduce((a, b) => {
          if (b && b.subtotal !== undefined && b.discount !== undefined) {
            return a + (b.subtotal * Number(b.discount) / 100);
          } else {
            return a;
          }
        }, 0);

        const vatToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.vat_to_rp !== undefined) {
            return a + Number(b.vat_to_rp);
          } else {
            return a;
          }
        }, 0);

        const totalToRpSum = subServiceValues.reduce((a, b) => {
          if (b && b.total_to_rp !== undefined) {
            return a + Number(b.total_to_rp);
          } else {
            return a;
          }
        }, 0);

        // Round the numeric values to four decimal places before assigning them
        updatedValues['Price Information']['price_services_info_to_rp'] = parseFloat(subtotalSum.toFixed(4));
        updatedValues['Price Information']['discount_info_to_rp'] = parseFloat(discountSum.toFixed(4));
        updatedValues['Price Information']['vat_info_to_rp'] = parseFloat(vatToRpSum.toFixed(4));
        updatedValues['Price Information']['total_price_info_to_rp'] = parseFloat(totalToRpSum.toFixed(4));


        return updatedValues;
      });
    }
  };

  const validateFileInput = (
    fieldValue,
    field,
    sectionTitle,
    index,
    sectionErrors
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }

    }

    return "";
  };


  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Sub Service")
          // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };


  // const resetFormValues = () => {
  //   setFormValues(
  // Object.assign({}, initialFormValues(fields), actionType !== 'ADD_NEW_GROUP'
  //       ? {
  //         id: null,
  //       }
  //       : {})
  //   );
  // };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  // const initialFormValues = (type = "fields") => {
  //   if (type === "columns") {
  //     return columns.reduce(
  //       (acc, field) => ({ ...acc, [field.field]: "" }),
  //       {}
  //     );
  //   } else {
  //     const nonDividerAndTypographyFields = fields.filter(
  //       (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
  //     );
  //     return nonDividerAndTypographyFields.reduce(
  //       (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
  //       {}
  //     );
  //   }
  // };

  // const initialErrors = fields
  //   .filter(
  //     (field) =>
  //       field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
  //   )
  //   .reduce((acc, field) => ({ ...acc, [field.name]: "" }), {});

  // const [formValues, setFormValues] = useState(
  // Object.assign({}, initialFormValues("fields"), { id: null, })
  // );
  const [detailRow, setDetailRow] = useState({});
  const [detailRowV2, setDetailRowV2] = useState({

    /*
     * OLD
    "Sales Quotation Information": {
        "No. Sales Quotation": "293/PPM/SP/XII/2022",
        "Client Name": "PT. Kompas Tbk",
        "Date Created": "24 September 2023",
        "Created By": "Howard Esther",
        "Approval Line": {
            "name": "Dianne Russel",
            "position": "Sales Manager"
        },
        "Date Approved": "24/09/2023, 0910",
        "Comment": "-",
        'Status': 'Approved'
    },
    "Requested Service": [
        {
            "title": "Izin Usaha",
            "subtitle": "Penamaan Bisnis",
            "children": {
                "Criteria": "Criteria 1",
                "Subtotal": "Rp. 5000,000",
                "Discount (50%)": "Rp. 6000,000",
                "VAT (11%)": "Rp. 7000,000",
                "Total": "Rp. 8000,000"
            }
        },
        {
          "title": "Izin Usaha",
          "subtitle": "Bangunan Bisnis",
          "children": {
              "Criteria": "Criteria 11",
              "Subtotal": "Rp. 1000,000",
              "Discount (50%)": "Rp. 2000,000",
              "VAT (11%)": "Rp. 73000,000",
              "Total": "Rp. 123000,000"
          }
      }
    ],
    "Price Information": {
        "Price (2 service)": "Rp.3241",
        "Discount": "Rp. 6000000",
        "VAT": "Rp.4534534566"
    },
    "Total Price": "Rp.123123123123123",
    "Note": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  ut labore et dolore magna aliqua.Ut enim ad minim veniam "
    */
    "Sales Quotation Information": {
      "No. Sales Quotation": "",
      "Client Name": "",
      "Date Created": "",
      "Created By": "",
      "Approval Line": {
        "name": "",
        "position": ""
      },
      "Date Approved": "",
      "Comment": "",
      "Status": ""
    },
    "Requested Service": [
      {
        "title": "",
        "subtitle": "",
        "children": {
          "Particular": "",
          "Subtotal": "",
          "Discount (50%)": "",
          "VAT (11%)": "",
          "Total": ""
        }
      }
    ],
    "Price Information": {
      "Price (0 service)": "",
      "Discount": "",
      "VAT": ""
    },
    "Total Price": "",
    "Note": ""
  })
  // const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (actionType !== 'ADD_NEW_GROUP' && actionType !== actionType.DELETE) {
      localStorage.setItem("sales-management/sales-quotation/formValues", JSON.stringify(formValues));
    }

  }, [formValues, actionType]);

  useEffect(() => {
    console.warn('-----------------FORM VALUES - SALES ORDER ----------')
    console.log(formValues)
    console.warn('-----------------ERRORS - SALES ORDER ----------')
    console.log(errors)
    console.warn('-----------------OPTIONS - SALES ORDER ----------')
    console.log(options)

    if (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED) {
      setIsLoadedFormValues(true)
    }
  }, [formValues, errors]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getSalesQuotation({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          category,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }

    console.log("CATEGORY", category);
  }, [category]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getSalesQuotation({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          category,
          dateFilter,
          startDate,
          endDate,
        })
      );
    }
  }, [isActive]);


  const fetchMasterSalesQuotation = async (client_id, periode) => {
    try {
      const response = await axios.get('/master/sales-quotations', {
        params: {
          client_id,
          periode,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // debugger;
      setOptions((prevOptions) => ({
        ...prevOptions,
        ['sales_quotation_id']: response.data.data,
      }));

      setIsLoadedFormValues(true);
      // debugger;
    } catch (error) {
      console.error('Error fetching sales_quotation:', error);
    }
  };

  const fetchSubServices = async (mainServiceId) => {
    try {
      const response = await axios.get('/master/services', {
        params: {
          main_service_id: mainServiceId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // debugger;

      setOptions((prevOptions) => ({
        ...prevOptions,
        ['sub_service_id']: response.data.data,
      }));

      setIsLoadedFormValues(true);
      // debugger;
    } catch (error) {
      console.error('Error fetching sub-service categories:', error);
    }
  };

  const fetchCriteriaBySubServiceId = async (subServiceId) => {
    try {
      const response = await axios.get(`/master/criteria/${subServiceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions((prevOptions) => {
        // Use an empty array if prevOptions.criteria_id is undefined
        const existingCriteria = prevOptions.criteria_id || [];

        const uniqueCriteriaIds = new Set([
          ...existingCriteria.map((item) => item.id),
          ...response.data.data.map((item) => item.id),
        ]);

        const mergedCriteria = Array.from(uniqueCriteriaIds).map((id) => {
          const existingItem = existingCriteria.find((item) => item.id === id);
          if (existingItem) {
            return existingItem;
          } else {
            return response.data.data.find((item) => item.id === id);
          }
        });

        return {
          ...prevOptions,
          criteria_id: mergedCriteria,
        };
      });

    } catch (error) {
      console.error('Error fetching sub-service categories:', error);
    }
  };

  const setFormValuesFromSelectedData = async (
    /*data = {
    "Client": { "client_id": 1, "date": "23/11/2023" },
    "Service": { "service_id": 1 },
    "Sub Service": [
    { "sub_service_id": 2, "discount": "11", "vat_percent": ['11'], "criteria_id": "", "desc": "Test Desc", subtotal: "0", vat_to_rp: "0", total_to_rp: "0", },
    { "sub_service_id": 3, "discount": 99, "vat_percent": "", "criteria_id": "", "desc": "Test Desc 2", subtotal: "900", vat_to_rp: "800", total_to_rp: "900", }
  ],
    "Attachment": { "attachment": [{ id: 1, filename: 'gambar-satu.jpg', size: 5000 }] },
    "Note Section": { "note": "yhjyuu" },
    "Price Information": { "price_services_info": "(1 service)", "price_services_info_to_rp": "1000,000", "discount_info_to_rp": "2000,000", "vat_info_to_rp": "30000,000", "total_price_info_to_rp": "45000,000" }
  }
  */
    idFromDetail
  ) => {
    setIsLoadedFormValues(false);
    // nanti ini di uncomment kalau udah dijalan functionnya
    let data;
    if (idFromDetail) {
      data = rows.filter((row) => row.id == idFromDetail)[0];
    } else {
      data = rows.filter((row) => selectedData.includes(row.id))[0];
    }

    const { id } = data;
    //     debugger;

    if (id) {
      try {
        const { data } = await (await axios.get(`/sales-quotations/${id}/detail-for-edit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }))?.data;
        // debugger;

        const { Service } = data;

        if (Service.service_id) {
          await fetchSubServices(Service.service_id);

          if (data['Sub Service'].length) {
            for (const i of data['Sub Service']) {
              if (i.sub_service_id) {
                debugger;
                await fetchCriteriaBySubServiceId(i.sub_service_id)
              }
            }
          }

          setFormValues(data)
          setErrors(cloneObjectWithEmptyStrings(data, 'attachment'))
          // setIsLoadedFormValues(true);
        }


      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    // if (isLoadedFormValues) {
    // for (const key in data) {
    //   let value;

    //   if (key === "Client" && data[key]["date"]) {
    //     // Handle date using dayjs
    //     if (dayjs(data[key]["date"], "DD/MM/YYYY").isValid()) {
    //       value = dayjs(data[key]["date"], "DD/MM/YYYY");
    //       data[key]["date"] = value;
    //       value = { ...data[key] };
    //     } else {
    //       value = "";
    //       data[key]["date"] = value;
    //       value = { ...data[key] };
    //     }
    //   } else if (Array.isArray(data[key])) {
    //     // Corrected condition to check if the current property is an array
    //     value = data[key].slice();
    //   } else if (typeof data[key] === 'object' && data[key] !== null) {
    //     // Handle nested objects
    //     value = { ...data[key] };
    //   } else {
    //     // Handle regular values
    //     value = data[key];
    //   }

    //   setFormValues((prevFormValues) => ({
    //     ...prevFormValues,
    //     [key]: value,
    //   }));
    // }

    // };

    // }

  };


  const setFormValuesFromSalesQuotation = async () => {

    const { sales_quotation_id: id } = formValues['Check Sales Quotation'];

    if (id) {
      try {
        const { data } = await (await axios.get(`/sales-quotations/${id}/detail-for-edit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }))?.data;

        // Hapush id dan attachmentnnya
        delete data.id
        data['Attachment']['attachment'] = ['']

        debugger;
        const { Service } = data;

        if (Service.service_id) {
          await fetchSubServices(Service.service_id);

          setFormValues(data)
          setErrors(cloneObjectWithEmptyStrings(data, 'attachment'))
          // setIsLoadedFormValues(true);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  // const handleInputChange = (event, field) => {
  //   const { name, value, type } = event.target;
  //   const newErrors = { ...errors };
  //   console.log(name, value, "name, value");

  //   if (type === "file") {
  //     const file = event.target.files[0];
  //     if (file) {
  //       const fileError = validateFileInput(file, field);
  //       if (fileError) {
  //         newErrors[name] = fileError;
  //         setErrors(newErrors);
  //         return;
  //       }
  //       setFormValues((prevValues) => ({
  //         ...prevValues,
  //         [name]: file,
  //       }));
  //     }
  //   } else {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: value,
  //     }));
  //   }
  // };

  // const handleSwitchChange = (event) => {
  //   const { name, checked } = event.target;
  //   // console.log(name, checked);
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: checked,
  //   }));
  // };

  // const handleInputBlur = (event) => {
  //   const { name } = event.target;
  //   validateField(name);
  // };

  // const validateFileInput = (
  //   fieldValue,
  //   field,
  // ) => {
  //   if (fieldValue instanceof File) {
  //     const allowedExtensions = field.allowedExtensions || [
  //       ".pdf",
  //       ".doc",
  //       ".docx",
  //       ".jpeg",
  //       ".jpg",
  //       ".png",
  //     ];
  //     const size = field.maximumSizeInMB || 10;
  //     const maxSizeInBytes = size * 1024 * 1024; // MB

  //     const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
  //     if (!allowedExtensions.includes(`.${fileExtension}`)) {
  //       return "Invalid file extension. Please select a file with a valid extension.";
  //     } else if (fieldValue.size > maxSizeInBytes) {
  //       return `File size exceeds the maximum allowed size of ${size} MB.`;
  //     }
  //   }

  //   return "";
  // };

  // const validateField = (fieldName) => {
  //   const field = fields.find((field) => field.name === fieldName);
  //   const newErrors = { ...errors };

  //   if (field) {
  //     const { name, required, validation, errorMessage, label, type } = field;

  //     if (
  //       required &&
  //       (String(formValues[name]).trim() === "" ||
  //         formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
  //         formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
  //     ) {
  //       newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
  //     } else if (
  //       formValues[name] &&
  //       formValues[name].length &&
  //       validation &&
  //       !validation(formValues[name])
  //     ) {
  //       newErrors[name] = errorMessage || `${label} is invalid`;
  //     } else {
  //       newErrors[name] = "";
  //     }

  //     setErrors(newErrors);
  //     console.log(newErrors, "newErrors");
  //   }
  // };

  // const { token } = useSelector((state) => state.auth);



  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
          setFormValues(initialFormValues())
          setErrors(initialErrors())
          //           debugger;
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
          setFormValues(initialFormValues())
          setErrors(initialErrors())
        }
        setSelectedData([]);
      }
    }
  };


  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    // setIsLoadedFormValues(false);
    setActionType(actionType);
    setFormModal(true);
    // setErrors(initialErrors);
    const aaa = selectedData;
    //     debugger;
    //     if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
    if (actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Sales Quotation");
      setFormValuesFromSelectedData(dataSelected?.id);
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Sales Quotation");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `the sales quotation '${data.map((row) => row.code).join(", ")}'`
      );
    } else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle("Detail Sales Quotation");
      // setDetailRow(dataSelected)
      dispatch(
        getSalesQuotationDetails({
          token,
          id: dataSelected.id
        })
      );
    } else {
      setTitle("Add Sales Quotation");
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);

    setFormModal(false);

    setFormValues(initialFormValues())
    setErrors(initialErrors())
    // resetFormValues()
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    // resetFormValues()
  };

  const handleAddRow = () => {
    dispatch(addSalesQuotation(token, formValues));
  };

  const handleEditRow = () => {
    dispatch(editSalesQuotation(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteSalesQuotation(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */



  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {



        const { title } = fieldGroup;
        const sectionErrors =
          fieldGroup.title === "Sub Service"
            ? []
            : {};


        fieldGroup.children.forEach((field) => {
          const { name, type } = field;
          if (
            fieldGroup.title === "Sub Service"
          ) {

            formValues[title].forEach((sectionValues, index) => {
              // Check if the field is of type "file" and an array
              if (
                type === INPUT_TYPE.FILE &&
                Array.isArray(sectionValues[name])
              ) {
                console.log(1111);
                sectionValues[name].forEach((file, subIndex) => {
                  // Handle file input validation
                  const fileError = validateFileInput(file, field);

                  if (!sectionErrors[index]) {
                    sectionErrors[index] = {};
                  }

                  if (!sectionErrors[index][name]) {
                    sectionErrors[index][name] = [];
                  }

                  sectionErrors[index][name][subIndex] = fileError;

                  if (fileError) {
                    valid = false;
                    // debugger;
                  }
                });
              } else if (type !== "file") {
                const fieldValue =
                  sectionValues[name] !== undefined ? sectionValues[name] : "";
                const error = validateFieldHelper(fieldValue, field);

                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

                sectionErrors[index][name] = error;

                if (error) {
                  valid = false;
                }

              }
            });
          } else {
            console.log(2222);
            if (
              type === INPUT_TYPE.FILE &&
              Array.isArray(formValues[title][name])
            ) {

              formValues[title][name].forEach((file, subIndex) => {
                // Handle file input validation
                const fileError = validateFileInput(file, field);

                if (!sectionErrors[name]) {
                  sectionErrors[name] = [];
                }

                sectionErrors[name][subIndex] = fileError;

                if (fileError) {
                  valid = false;
                }
              });
            } else if (type !== "file") {
              // Handle regular input field validation
              const fieldValue =
                formValues[title] && formValues[title][name] !== undefined
                  ? formValues[title][name]
                  : "";
              const error = validateFieldHelper(fieldValue, field);

              sectionErrors[name] = error;

              if (error) {
                valid = false;
              }
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });


    setErrors(newErrors);

    // debugger;
    return valid;
  };


  // const validateForm = () => {
  //   let valid = true;
  //   const newErrors = {};

  //   fields.forEach((fieldGroup) => {
  //     if (fieldGroup.children) {
  //       const { title } = fieldGroup;

  //       const sectionErrors =
  //         fieldGroup.title === "Sub Service"
  //           ? []
  //           : {};

  //       fieldGroup.children.forEach((field) => {
  //         const { name } = field;

  //         if (
  //           fieldGroup.title === "Sub Service"
  //         ) {
  //           formValues[title].forEach((sectionValues, index) => {
  //             const fieldValue =
  //               sectionValues[name] !== undefined ? sectionValues[name] : "";
  //             const error = validateFieldHelper(fieldValue, field);

  //             if (!sectionErrors[index]) {
  //               sectionErrors[index] = {};
  //             }

  //             sectionErrors[index][name] = error;

  //             if (error) {
  //               valid = false;
  //             }
  //           });
  //         } else {
  //           const fieldValue =
  //             formValues[title] && formValues[title][name] !== undefined
  //               ? formValues[title][name]
  //               : "";
  //           const error = validateFieldHelper(fieldValue, field);
  //           const errorsz = errors;
  //           debugger;
  //           sectionErrors[name] = error;

  //           if (error) {
  //             valid = false;
  //           }
  //         }
  //       });

  //       newErrors[title] = sectionErrors;
  //     }
  //   });

  //   setErrors(newErrors);
  //   return valid;
  // };


  const renderFields = (section, index, field) => {
    if (isLoadedFormValues) {
      const currentFormValues =
        section.title === "Sub Service"
          ? formValues[section.title][index]
          // : section.title === "Attachment"
          //   ? formValues[section.title][index]
          : formValues[section.title];
      const currentErrors =
        section.title === "Sub Service"
          ? errors[section.title][index]
          // : section.title === "Attachment"
          //   ? errors[section.title][index]
          : errors[section.title];

      // debugger;
      // If section.title is undefined, use the general errors
      if (!section.title) {
        currentErrors = errors;
      }
      return (
        <>
          {section.children?.map((field) => {
            // Check if children array exists
            /*
                      const checkSalesQuotation = formValues['Check Sales Quotation'];
            
                      const [isClientIdExist, isClientIdANDPeriodeExist] = [
                        !checkSalesQuotation ||
                          checkSalesQuotation.client_id === undefined ||
                          checkSalesQuotation.client_id === null ||
                          checkSalesQuotation.client_id === '',
                        !checkSalesQuotation ||
                          checkSalesQuotation.client_id === undefined ||
                          checkSalesQuotation.client_id === null ||
                          checkSalesQuotation.client_id === '' ||
                          checkSalesQuotation.periode === undefined ||
                          checkSalesQuotation.periode === null ||
                          checkSalesQuotation.periode === '',
                      ];*/

            /*
             if (field.title === "Sub Service" && field.children) {
                    return {
                      ...field,
                      children: field.children.map((subField, index) => {
                        if(subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
                          if (subField.name === "sub_service_id") {
                            return {
                              ...subField,
                              additionalProps: {
                                ...subField.additionalProps,
                                disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                                 // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                                },
                            };
                          } else {
                          // return {
                          //   ...subField,
                          //   additionalProps: {
                          //     ...subField.additionalProps,
                          //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
                          //   },
                          // };
                        }
                      } else {
                        if (subField.name === "discount" || subField.name === "vat_percent"  || subField.name === "subtotal" || subField.name === "criteria_id") {
                          // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
                          return {
                            ...subField,
                            additionalProps: {
                              ...subField.additionalProps,
                              disabled: true
                              // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                              // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                            },
                          };
                        }}
                        return subField;
                      }),
                    };
                  }
                  */
            let optionsData;

            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

              // if (isRequired) {
              //   optionsData = [
              //     // field.required &&
              //     //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //     //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //     //     name: "Choose Option",
              //     //   },
              //     ...options[field.name],
              //   ];
              // } else {
              //   optionsData = [...options[field.name]];
              // }

              if (field.name === "sub_service_id") {

                field = {
                  ...field,
                  additionalProps: {
                    ...field.additionalProps,
                    disabled: (actionType !== ACTION_TYPE.EDITED && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                  },
                };

                // Filter options based on sub_service_id values that are not used
                const usedSubServiceIds = formValues['Sub Service']
                  .filter((_, i) => i !== index) // Exclude the sub_service_id at the current index
                  .map(subService => subService?.sub_service_id); // Use optional chaining to prevent errors if subService is undefined

                // Filter options based on sub_service_id values that are not used
                // OLD : optionsData = options[field.name].filter(option => !usedSubServiceIds.includes(option.id));
                  optionsData = [...options[field.name]];

//                 console.warn('usedSubServiceIds', usedSubServiceIds);
//                 console.warn('optionsData', optionsData);

              } else if (field.name === "criteria_id") {

                /* OLD */
                 const usedSubServiceIds = formValues['Sub Service']
                  .filter((_, i) => i === index)
                  .map(subService => subService?.sub_service_id);
                // console.log(usedSubServiceIds,'KKKK');

                optionsData = options[field.name].filter(option => usedSubServiceIds.includes(option.service_id));

              // NEW //
               const usedCriteriaIds = formValues['Sub Service']
                  .filter((_, i) => i !== index)
                  .map(subService => subService?.criteria_id);

                optionsData = optionsData.filter(option => !usedCriteriaIds.includes(option.id));

              } else {
                optionsData = [...options[field.name]];

                //                   if (field.name === "sales_quotation_id") {
                //                     field = {...field, additionalProps: {
                //                         ...field.additionalProps,
                //                         disabled: isClientIdANDPeriodeExist ,
                //                       },
                //                       disabled: isClientIdANDPeriodeExist
                //                     }
                //                   }
              }

            } else {

              if (!currentFormValues[field.name]) {
                currentFormValues[field.name] = field.defaultValue
              }

              if (field.name === "price" || field.name === "quantity" || field.name === "discount" || field.name === "vat_percent" || field.name === "criteria_id") {
                field = {
                  ...field, additionalProps: {
                    ...field.additionalProps,
                    disabled: formValues['Sub Service'][index] && (formValues['Sub Service'][index]['sub_service_id'] === undefined || formValues['Sub Service'][index]['sub_service_id'] === null || formValues['Sub Service'][index]['sub_service_id'] === '')
                  }
                }
                console.log(field)
              }

            }


            const startIconMappings = {
              USD: { subtotal: 'USD ', price: 'USD ' },
              Rp: { subtotal: 'Rp ', price: 'Rp ' },
              EUR: { subtotal: 'EUR ', price: 'EUR ' },
              CNY: { subtotal: 'CNY ', price: 'CNY ' },
              SGD: { subtotal: 'SGD ', price: 'SGD ' },
              GBP: { subtotal: 'GBP ', price: 'GBP ' },
              AUD: { subtotal: 'AUD ', price: 'AUD ' },
            };


            const labelMappings = {
              USD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              Rp: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              EUR: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              CNY: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              SGD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              GBP: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              AUD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
            };


            if (formValues['Service'] && formValues['Service']['currency']) {
              const currency = formValues['Service']['currency'];

              if (currency && startIconMappings[currency] && startIconMappings[currency][field.name]) {
                field = { ...field, startIcon: startIconMappings[currency][field.name] };
              }

              if (currency && labelMappings[currency] && labelMappings[currency].includes(field.name)) {
                field = { ...field, label: `${currency} ` };
              }
            }


            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
              case INPUT_TYPE.DISCOUNT:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.FILE:
                console.log(currentFormValues[field.name], 'SOBARIIIIII', field.name, section.title)
                fieldComponent = (
                  <div>
                    {currentFormValues[field.name].map(
                      (_, fileIndex) => (
                        <div key={fileIndex}>
                          <Grid container spacing={0.3}>
                            <Grid item xs={11.3}>
                              <FilePicker
                                indexFile={fileIndex}
                                field={field}
                                formValues={
                                  currentFormValues[field.name][fileIndex]
                                }
                                errors={currentErrors}
                                onFileSelect={(file) =>
                                  handleFileSelect(
                                    file,
                                    index,
                                    section.title,
                                    field.name,
                                    fileIndex
                                  )
                                }
                                handleInputChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    section.title,
                                    field,
                                    fileIndex
                                  )
                                }
                                tip={field?.tip}
                                URIForViewDocument={currentFormValues[field.name][fileIndex]['url']}
                              />
                            </Grid>

                            <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ...removeButton,
                                }}
                                onClick={() =>
                                  handleRemoveFile(
                                    section.title,
                                    index,
                                    fileIndex,
                                    field.name
                                  )
                                }
                                disabled={
                                  currentFormValues[field.name].length === 1
                                }
                              >
                                <>
                                  <img
                                    src={RemoveIcon}
                                    alt={`Remove ${section.title}`}
                                  />

                                </>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}

                    <Box sx={{ ...justifyContentEnd }}>
                      <Button
                        onClick={() => handleAddFile(section.title, index, field.name)}
                      >
                        Add More
                      </Button>
                    </Box>
                  </div>
                );

                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) => {
                      handleInputBlur(field.name, section.title, index, e);
                    }}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.DIVIDER:
                fieldComponent = (
                  <Divider sx={field.style} />
                );
                break;
              case INPUT_TYPE.TYPOGRAPHY:
                fieldComponent = (
                  <Typography variant={field.variant} sx={field.style}>{field.label}</Typography>
                );
                break;
              //               OLD
              //               case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
              //                 fieldComponent = (
              //                   <Typography key={field.name} variant={field.variant} sx={field.style}>{field.label} {field.name === 'price_services_info' ? currentFormValues[field.name] : formatCurrencyWithoutLable(currentFormValues[field.name])}</Typography>
              //                 );
              //                 break;
              case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
                if (field.name === 'price_services_info') {
                  fieldComponent = (
                    <Typography key={field.name} variant={field.variant} sx={field.style}>
                      {field.label} {currentFormValues[field.name]}
                    </Typography>
                  );
                } else if (field.name === 'discount_info_to_rp') {
                  fieldComponent = (
                    <Typography key={field.name} variant={field.variant} sx={field.style}>
                      ({field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])})
                    </Typography>
                  );
                } else {
                  fieldComponent = (
                    <Typography key={field.name} variant={field.variant} sx={field.style}>
                      {field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])}
                    </Typography>
                  );
                }
                break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.CHECKBOX:
                fieldComponent = (
                  <CheckboxGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.RADIO:
                fieldComponent = (
                  <RadioGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    errors={currentErrors}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.DATE:
                fieldComponent = (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              // Add cases for other field types
              default:
                fieldComponent = null;
            }

            return (
              <Grid
                item
                xs={field.gridWidth || 12}
                key={field.name}
                sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
              >
                {fieldComponent}
              </Grid>
            );
          })}
        </>
      );
    }
  };

  const renderSectionFields = (section) => {
    if (isLoadedFormValues) {
      // if (section.title === "Sub Service" || section.title === "Attachment") {
      if (section.title === "Sub Service") {
        // Render Sub Service or Attachment section
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
              </Box>
            )}
            {formValues[section.title].map((rowValues, index) => (
              <div key={index}>
                <Grid container spacing={0.3}>
                  <Grid item xs={11.3}>
                    <Grid container spacing={1}>
                      {renderFields(section, index)}
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ...removeButton,
                      }}
                      onClick={() => handleRemoveFieldRow(section.title, index)}
                      // OLD disabled={(actionType === ACTION_TYPE.ADD && formValues[section.title].length === 1) || actionType === ACTION_TYPE.EDITED}>
                      disabled={formValues[section.title].length === 1}>
                      <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                    </Button>
                  </Grid>
                </Grid>
                {formValues[section.title].length > 1 && (
                  <Divider sx={{ mt: 2, mb: 2 }} />
                )}
              </div>
            ))}
            {
              /* ********************** OLD **********************
               actionType !== ACTION_TYPE.EDITED && (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)} disabled={
                //(actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER && formValues[section.title].length === 1) ||
                actionType === ACTION_TYPE.EDITED}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
              */
              (<Box sx={{ ...justifyContentEnd }}>
                <Button onClick={() => handleAddFieldRow(section.title)}>
                  Add Another Sub {section.title === "Sub Service" ? " " : " "}
                </Button>
              </Box>)
            }
          </Grid>
        );
      } else {
        // Render other sections using renderFields function
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
                {/* Subtitle */}
                {section.subtitle && (
                  <Typography variant="subtitle2">{section.subtitle}</Typography>
                )}
              </Box>
            )
            }
            <Grid container spacing={1}>
              {renderFields(section)}
            </Grid>
          </Grid >
        );
      }
    }
  };

  const Detail = () => {
    const statusColors = {
      waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
      approved: { bgColor: successSurfaceColor, colors: successMainColor },
      rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
      // Add more statuses as needed
    };

    const statusInfo = statusColors[detailRowV2["Sales Quotation Information"]["Status"]] || {
      bgColor: infoSurfaceColor,
      colors: infoMainColor,
    };

    const excludedProperties = ["Comment", "Status"];

    return (
      <>
        {/* -------------------------------- Sales Quotation Information ------------------------------- */}
        {/* Sales Quotation Information */}
        <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' } }}>
          <Grid item xs={12}>
            <Box sx={{ marginBottom: 2, ...justifyContentBetween, ...alignItemsCenter }}>
              <Typography variant="h6" sx={{ fontWeight: 800 }}>
                Sales Quotation Information
              </Typography>
              <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
                {detailRowV2["Sales Quotation Information"]["Status"]}
              </StatusBadge>
            </Box>
          </Grid>

          {Object.entries(detailRowV2["Sales Quotation Information"]).map(([key, value]) => (
            <React.Fragment key={key}>
              {!excludedProperties.includes(key) && (
                <React.Fragment key={key}>
                  {key === "Approval Line" && typeof value === "object" ? (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="body1">{key}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          {value.name} <span style={{ color: '#666' }}>as {value.position}</span>
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="body1">{key}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">{value}</Typography>
                      </Grid>
                    </>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Grid>


        {/* -------------------------------- Requested Service ------------------------------- */}
        <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0 }}>
          <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 800 }}>Service & Price Information</Typography></Grid>

          {detailRowV2["Requested Service"].map((service, serviceIndex) => (
            <React.Fragment key={serviceIndex}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <span
                    style={{
                      marginRight: '5px',
                      backgroundColor: mainColor,
                      color: '#fff',
                      width: '20px',
                      height: '20px',
                      borderRadius: '100%',
                      fontSize: '13px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {serviceIndex + 1}
                  </span>
                  <Typography variant="subtitle1">
                    {service.title} - <b> {service.subtitle}</b>
                  </Typography>
                </Box>
              </Grid>

              {Object.entries(service.children).map(([key, value]) => (
                <React.Fragment key={key}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{key}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">{value}</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Grid>

        {/* -------------------------------- Price Information ------------------------------- */}
        <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0 }}>
          <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 800 }}>Price Information</Typography></Grid>

          {Object.entries(detailRowV2["Price Information"]).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <Typography variant="body1">{key}</Typography>
              </Grid>
              <Grid item xs={8} sx={{ textAlign: 'right' }}>
                <Typography variant="subtitle1">{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: 800 }}>Total Price</Typography>
          </Grid>
          <Grid item xs={8} sx={{ textAlign: 'right' }} >
            <Typography variant="h6" sx={{ fontWeight: 800 }}>{detailRowV2["Total Price"]}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, }}>
          <Grid item xs={4}>
            <Typography variant="body1">Note</Typography>
          </Grid>
          <Grid item xs={8} >
            <Typography variant="subtitle1">{detailRowV2["Note"]}</Typography>
          </Grid>
        </Grid>


        {/* -------------------------------- Signature ------------------------------- */}
        {detailRowV2["Sales Quotation Information"]["Status"] !== QuotationStatus.REJECTED && (<Grid container spacing={3} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 2.5 }}>
          <Grid item xs={6} sx={{ ...justifyContentCenter }}>
            {/* <Signature companyName="Paific Patent Multiglobal" isMarked signatureText="Dianne Russell" />*/}
            <Signature companyName="Sales Manager" signatureText={detailRowV2["Sales Quotation Information"]['Approval Line']['name']}  /*isMarked*/ />
          </Grid>
          <Grid item xs={6} sx={{ ...justifyContentCenter }}>
            {/*<Signature companyName="Client Deal" signatureText="(................................................)" />*/}
            <Signature companyName="Client Name" signatureText={detailRowV2["Sales Quotation Information"]['Client Name']} />
          </Grid>
        </Grid>)
        }

        {/* /* -------------------------------- Reason for Reject ------------------------------- */}
        {detailRowV2["Sales Quotation Information"]["Status"] === QuotationStatus.REJECTED && (
          <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 1 }}>
            <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 800 }}>Reason for Reject</Typography></Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Reason</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2">-</Typography>
            </Grid>
          </Grid>)
        }

      </>
    );
  };

  const renderFormContent = () => {
    if (
      //       (selectedData.length && actionType === ACTION_TYPE.EDITED)
      (actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <>
          <Grid
            container
            spacing={1.5}
            sx={{
              // overflowX: "auto",
              // height: "52vh",
              // paddingBottom: "2rem",
            }}
          // className="smooth-scroll"
          >
            {/* {JSON.stringify(isLoadedFormValues)} */}
            {/* <div class="test">
              {JSON.stringify(actionType)}
            {JSON.stringify(formValues)}
             </div>
             */}
            {/* <hr /> */}
            {/* {JSON.stringify(errors)} */}
            {isLoadedFormValues ?
              fields.map((section) => renderSectionFields(section)) : (<Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: '100%'
              }}><FacebookCircularProgress /></Box>)
            }
          </Grid>
        </>
      )
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    } else if (actionType === ACTION_TYPE.DETAIL) {
      return <Detail />
    }
  };

  const renderOtherActionButtons = () => {
    return (
      <Box>
        {actionType === ACTION_TYPE.DETAIL ? (
          <>
            {/*<Button variant="outlined" sx={{ ...btnLightBlue }} disableElevation id="download-quotation" onClick={() => {
                    window.open(
                        process.env.REACT_APP_API_URL +
                        `/api/sales-quotations/${detailRow.id}/download-pdf`,
                        "_blank"
                    );
                }} disabled>
              <img src={PrinterIcon} style={{ marginRight: '5px' }} alt="Printer Icon" />
              Download Quotation
            </Button>
            */}
          </>
        ) : actionType === ACTION_TYPE.EDITED ? (
          <>
            {/* <Button
              variant="outlined"
              sx={{ ...btnGreen }}
              disableElevation
              onClick={() => handleCloseFormModal()}
            >
              Mark as Deal
            </Button> */}
          </>
        ) : null}
      </Box>
    );
  };




  const renderActionButtons = () => {
    return (
      <Box>
        {actionType !== ACTION_TYPE.DETAIL ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
              id="cancel-modal"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              id="submit-modal"
            >
              {actionType === ACTION_TYPE.DELETED
                ? "Yes"
                : "Save"
              }
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => handleCloseFormModal()}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              sx={{ ...btnWhite, mr: 0, ml: 2 }}
              disableElevation
              type="submit"
              onClick={() => {
                handleFormModal(ACTION_TYPE.EDITED, detailRowV2)
              }
              }
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    );
  };



  const Signature = ({ companyName, isMarked, signatureText }) => (
    <Box sx={{ textAlign: 'center', border: "1px solid #ddd", width: '100%', borderRadius: '8px', p: 1 }}>
      <Typography variant="subtitle1">{companyName}</Typography>
      <Box sx={{ height: '60px' }}>
        {isMarked && (<img src={successIMG} width="55px" />)}
      </Box>
      <Typography sx={{ fontWeight: '500' }}>{signatureText ? signatureText : '(................................................)'}</Typography>
    </Box>
  );

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getSalesQuotation({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          category,
          dateFilter,
          startDate,
          endDate,
        })
      );
    }
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (dateFilter !== 'custom_date' && dateFilter !== ACTION_TYPE.DEFAULT_DATE && status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getSalesQuotation({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          category,
          dateFilter,
          startDate,
          endDate,
        })
      );
    }
  }, [dateFilter])

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getSalesQuotation({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        category,
        dateFilter,
        startDate,
        endDate,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getSalesQuotation({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        category,
        dateFilter,
        startDate,
        endDate,
      })
    );
  }
  function _search() {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getSalesQuotation({
          token, limit, search, status, sortDirection, sortBy, category,
          dateFilter,
          startDate,
          endDate,
        })
      );
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getSalesQuotation({ token, limit, search, status, sortDirection, sortBy, category,
    // dateFilter,
    // startDate,
    // endDate, })
    // );
  }


  function _category(category) {
    setCategory(category);
  }

  function _dateFilter(date) {
    if (date === "custom_date") {
      setShowDatePicker(true);
      setDateFilter(date);

    } else {
      setShowSelectDate(false);

      if ((dateFilter == 'custom_date' && (date !== 'custom_date' && date !== undefined))) {
        setShowDatePicker(false);

        setStartDate(null);
        setStartDateElm(null);
        setEndDate(null);
        setEndDateElm(null);
      }

      if (date && status !== ACTION_TYPE.DEFAULT_STATUS) {
        setDateFilter(date);
      }
    }
  }

  const handleStartDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setStartDate(formattedDate);
      setStartDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleEndDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setEndDate(formattedDate);
      setEndDateElm(date);
    } else {
      console.error("Invalid end date:", date);
      // You might want to handle this error case gracefully
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setShowSelectDate(false);
      dispatch(
        getSalesQuotation({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
        })
      );
    }
  }, [startDate, endDate]);

  const handleSelectClose = (e) => {
    if (e) e.stopPropagation();
    setShowDatePicker(false);
    setShowSelectDate(false);
  };



  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                placeholder="Search client name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
                id="search-input"
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
                id="search-button"
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                  open: showSelectDate, // Set the open prop based on showDatePicker
                  onClose: () => handleSelectClose, // Close the menu on selecting an option
                  onOpen: () => setShowSelectDate(true),
                  // onOpen: () => date !== 'custom_date' , // Open the menu when "Custom Date" is
                }}
                sx={{
                  borderRadius,
                }}
                value={dateFilter}
                // inputRef={textFieldRef} // Assign a ref to the TextField
                // onClick={(e) => _dateFilter(e.target.value)}
                onChange={(e) => _dateFilter(e.target.value)}
                MenuProps={{
                  disableCloseOnSelect: false,
                }}
                disabled={status === ACTION_TYPE.DEFAULT_STATUS}
                id="select-date"
              >
                <MenuItem sx={{
                  outline: '1px solid red', position: 'absolute', right: 0, padding: '1px 5px', fontWeight: '500', borderRadius: 50, color: 'red', zIndex: '999', width: 'auto', height: '16px', display: 'flex',
                  justifyContent: 'center',
                  top: '1.12rem',
                  right: '1rem',
                }} id="close"> Close </MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value={ACTION_TYPE.ALL_DATE} id="all_date">All longs date</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="last_30_days" id="last_30_days">Last 30 days</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="last_6_months" id="last_6_months">Last 6 months</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="last_1_year" id="last_1_year">Last 1 year</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="custom_date" id="custom_date">Custom Date</MenuItem>
                {showDatePicker && (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                    <Grid container spacing={2} p={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Start From</Typography>
                        <DatePicker
                          selectedDate={startDateElm}
                          onChange={handleStartDateChange}
                          components={{
                            OpenPickerButton: CustomOpenPickerButton,
                          }}
                          format="DD/MM/YYYY"
                          value={startDateElm}
                          sx={{
                            ...inputDate,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Until</Typography>
                        <DatePicker
                          selectedDate={endDateElm}
                          onChange={handleEndDateChange}
                          components={{
                            OpenPickerButton: CustomOpenPickerButton,
                          }}
                          format="DD/MM/YYYY"
                          value={endDateElm}
                          sx={{
                            ...inputDate,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                )}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={category}
                onChange={(e) => _category(e.target.value)}
                disabled={status === ACTION_TYPE.DEFAULT_STATUS}
                id="select-service"
              >
                <MenuItem value={ACTION_TYPE.ALL_CATEGORY} id="all-service">
                  All service category
                </MenuItem>
                {options["service_id"] &&
                  options["service_id"].map((type) => (
                    <MenuItem key={type.id} value={type.id} id={`${type.id}${type.name}`}>
                      {type.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
                id="status-select"
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS} id="select_status">
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS} id="all_status">All Status</MenuItem>
                <MenuItem value="waiting" id="waiting">Waiting</MenuItem>
                <MenuItem value="approved" id="approved">Approved</MenuItem>
                <MenuItem value="rejected" id="rejected">Rejected</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Sales Quotation"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          // userAccess={userAccess}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED && actionType !== ACTION_TYPE.DETAIL ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementOtherActionButton={renderOtherActionButtons}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
        <Modal open={formModal} onClose={() => handleCloseFormModal()}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementOtherActionButton={renderOtherActionButtons}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
            />
          </Box>
        </Modal>
      )}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default SalesQuotation;
