export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_FAILED = "GET_PAYMENT_FAILED";

export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_FAILED = "ADD_PAYMENT_FAILED";

export const EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS";
export const EDIT_PAYMENT_FAILED = "EDIT_PAYMENT_FAILED";

export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAILED = "DELETE_PAYMENT_FAILED";

export const GET_DETAIL_PAYMENT_SUCCESS = "GET DETAIL PAYMENT SUCCESS";
export const GET_DETAIL_PAYMENT_FAILED = "GET DETAIL PAYMENT FAILED";
export const CLEAR_DETAIL_PAYMENT = "CLEAR DETAIL PAYMENT";

export const PAY_PAYMENT_SUCCESS = "PAY_PAYMENT_SUCCESS";
export const PAY_PAYMENT_FAILED = "PAY_PAYMENT_FAILED";

export const PAYMENT_SET_FILTER = "SET / PAYMENT FILTER TABLE";
