import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../../components/Form";
import {
  approvePayment,
  getDetailPayment,
  payPayment,
} from "./clients/actions";
import {
  ACTION_TYPE,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackIcon from "./../../../assets/back.svg";
import ZoomInIcon from "./../../../assets/zoom-in.png";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import PrinterGrayIcon from "./../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../assets/download-gray.png";
import ZoomOutIcon from "./../../../assets/zoom-out.png";
import logo from "./../../../logo.png";
import boderImg from "./../../../assets/border.png";
import PreviewPDF from "../payment-request/componens/PreviewPDF";
import DetailDocument from "../payment-request/componens/DetailPapper";
import DocumentForPrint from "../payment-request/componens/DocumentForPrint";
// import { paymentStatusColor } from "./parts/CusomStyle";
// import { ACTION_APPROVE } from "./parts/imutable_state";
import BottomAction from "../payment-request/componens/detail/BottomAction";

import {
  ACTION_APPROVE,
  ANOTHER_INPUT_TYPE,
} from "../payment-request/parts/imutable_state";

import {
  paymentStatusColor,
  useStyles,
} from "../payment-request/parts/CusomStyle";
import { fields } from "./parts/imutable_state";
import DetailPaymentForm from "./detail_payment_form";

//* ======================= Type Def ========================
/**
 * - Type for field
 * @typedef {object} FieldType
 * @property {string} name
 * @property {string} type
 * @property {string} label
 * @property {string} defaultValue
 * @property {boolean} required
 * @property {string} [errorMessage]
 * @property {CallableFunction} [validation]
 * @property {string} [aliasLable]
 * @property {string} [tip]
 */

function DetailPayment() {
  const FORM_STATUS = {
    APPROVAL: "APPROVAL",
    PAY: "PAY",
  };

  const approvalField = {
    name: "reason",
    required: true,
    type: INPUT_TYPE.TEXTAREA,
    placeholder: "Input reason",
    label: "Reason",
  };
  const [title, setTitle] = useState("Detail Payment");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [showModal, setShowModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [modalConfirmMessage, setModalConfirmMessage] = useState("");
  const [detailPayment, setDetailPayment] = useState({});
  const [height, setHeight] = useState(0);
  const [formStatus, setFormStatus] = useState(FORM_STATUS.APPROVAL);
  const [
    isDoneFetchHeightDocumentPreview,
    setIsDoneFetchHeightDocumentPreview,
  ] = useState(true);

  const documentPreviewForPrintRef = useRef(null);
  const { detail_payment, isLoading, message } = useSelector(
    (state) => state.financePayment
  );
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setPaymentId(id);
      // console.log(id);
    }
  }, [id]);

  useEffect(() => {
    if (paymentId) {
      dispatch(getDetailPayment(token, paymentId));
    }
  }, [paymentId]);

  useEffect(() => {
    if (detail_payment) {
      setDetailPayment(detail_payment);
    }
  }, [detail_payment]);

  /* ========================================================= */
  /*                       FORM SECTION                        */
  /* ========================================================= */

  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});

  const initialAttachmentValues = [""];
  const initialAttachmentErrors = [""];

  const initalFormValues = () => {
    const localFormValues = {};
    fields.forEach((field) => {
      if (field.type === ANOTHER_INPUT_TYPE.FILE) {
        localFormValues[field.name] = initialAttachmentValues;
      } else {
        localFormValues[field.name] = field.defaultValue;
      }
    });
    return localFormValues;
  };

  const initialErrorValues = () => {
    const localErrorValues = {};
    fields.forEach((field) => {
      if (field.type === ANOTHER_INPUT_TYPE.FILE) {
        localErrorValues[field.name] = initialAttachmentErrors;
      } else {
        localErrorValues[field.name] = "";
      }
    });
    return localErrorValues;
  };

  const resetToInitialValue = () => {
    setFormValues(initalFormValues());
    setErrors(initialErrorValues());
  };

  const isFormFilled = () => {
    let isValid = true;
    Object.values(formValues).forEach((value) => {
      console.log(value, "value values");
      if (Array.isArray(value)) {
        isValid = value.every((valFile) => valFile !== "");
        if (isValid) return;
      } else {
        isValid = value.trim() !== "";
        if (isValid) return;
      }
    });
    return isValid;
  };

  const setApprovalForm = (statusApproval) => {
    setFormValues({ reason: "", status: statusApproval });
    setErrors({ reason: "" });
  };

  //* ======================= VALIDATION ==================== */
  /**
   * validate file input
   * @param {File} file
   * @param {FieldType} field
   * @returns {string}
   */
  const validateInputFile = (file, field) => {
    if (file instanceof File) {
      /**@type {Array} */
      const allowedExtension = field.allowedExtension || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtension.includes(".".concat(fileExtension))) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (file.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  /**
   * @param {FieldType} field
   * @param {object} currentErrorf
   */
  const validateNonInputFile = (field, currentError) => {
    let valid = true;
    const { name, required, errorMessage, validation, aliasLable, label } =
      field;
    const valueForm = formValues[name];
    if (
      (required && String(valueForm).trim() === "") ||
      valueForm === null ||
      valueForm === undefined
    ) {
      currentError[name] =
        errorMessage ||
        "Please enter ".concat((aliasLable ? aliasLable : label).toLowerCase());
      valid = false;
    }

    if (
      valueForm &&
      valueForm.length &&
      validation &&
      !!validation(formValues[name])
    ) {
      currentError[name] =
        errorMessage || String(field.label).concat("is invalid");
      valid = false;
    }
    return [valid, currentError];
  };

  const validateForm = () => {
    let valid = true;
    let newError = initialErrorValues();
    fields.forEach((field) => {
      const { name, required, type, errorMessage } = field;
      if (type === ANOTHER_INPUT_TYPE.FILE) {
        //* validate file input
        formValues[name].forEach((fileValue, index) => {
          let errorFileValue = "";
          if (required && fileValue === "") {
            errorFileValue = "Please select file attachment";
          } else {
            errorFileValue = validateInputFile(fileValue, field);
          }

          if (errorFileValue) {
            newError[name][index] = errorFileValue;
            valid = false;
          }
        });
      } else if (required) {
        const [returnValid, returnError] = validateNonInputFile(
          field,
          newError
        );
        valid = returnValid;
        newError = returnError;
      }
    });
    setErrors(newError);
    return valid;
  };

  /* ========================================================= */
  /*                       HANDLER SECTION                     */
  /* ========================================================= */

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputBlur = (fieldName) => {
    if (fieldName === approvalField.name) {
      const copyError = { ...errors };
      if (formValues[approvalField.name] === "") {
        copyError[approvalField.name] = "Enter reason";
      }
      setErrors(copyError);
    }
  };

  const validateReasonField = () => {
    let valid = true;
    const copyError = { ...errors };
    if (formValues[approvalField.name] === "") {
      copyError[approvalField.name] = "Enter reason";
      valid = false;
    }
    setErrors(copyError);
    return valid;
  };

  const handleBackBtn = () => {
    navigate(-1);
  };
  const handleCloseModal = () => {
    setConfirmModal(true);
  };
  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };
  const handleYesConfirmModal = () => {
    resetToInitialValue();
    setModalConfirmMessage("");
    setShowModal(false);
    setConfirmModal(false);
  };
  const handleSubmitForm = (event) => {
    event.preventDefault();
    // validate form;
    if (formStatus === FORM_STATUS.PAY) {
      if (validateForm()) {
        handlePayPayment();
      }
    } else if (formStatus === FORM_STATUS.APPROVAL && validateReasonField()) {
      // if (detailPayment?.Action === "Verification") {
      //   dispatch(approvePayment(token, formValues, paymentId));
      //   handleYesConfirmModal();
      // } else if (detailPayment?.Action === "Payment") {
      // }
      handlePayPayment();
    }
  };

  const attachmentCheck = (attach) => {
    return attach.every((item) => item !== "");
  };

  const handlePayPayment = () => {
    const manipulatedPayload = { ...formValues };
    if (!manipulatedPayload["status"]) {
      const listAttachment = formValues["attachments"];
      if (listAttachment && Array.isArray(listAttachment)) {
        if (!attachmentCheck(listAttachment)) {
          delete manipulatedPayload["attachments"];
        }
      }
      manipulatedPayload["status"] = "Pay";
    }
    dispatch(payPayment(token, manipulatedPayload, paymentId));
    handleYesConfirmModal();
  };

  const handlePrint = () => {
    window.print();
  };

  const handlePay = () => {
    // handle pay action
    setShowModal(true);
    setFormStatus(FORM_STATUS.PAY);
    resetToInitialValue();
    setTitle("Pay Payment");
    setModalConfirmMessage(
      "You want to cancel the process paying payment request"
    );
    setShowModal(true);
  };

  const cancelHandle = () => {
    setShowModal(true);
    setTitle("Cancel Payment");
    setModalConfirmMessage(
      "You want to cancel the process cancel payment request"
    );
    setApprovalForm("Reject");
  };

  /* ========================================================= */
  /*                 COMPONENT RENDER SECTION                  */
  /* ========================================================= */

  const renderActionButtonModal = () => (
    <Box>
      <Button
        variant="outlined"
        sx={{ ...btnWhite }}
        onClick={handleCloseModal}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disableElevation
        type="submit"
      >
        Save
      </Button>
    </Box>
  );
  const renderElementFormModal = () => {
    if (formStatus === FORM_STATUS.APPROVAL) {
      return (
        <>
          <TextArea
            errors={errors}
            field={approvalField}
            formValues={formValues[approvalField.name]}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            placeholder={approvalField.placeholder}
            showError
          />
        </>
      );
    } else {
      return (
        <DetailPaymentForm
          errors={errors}
          formValues={formValues}
          setErrors={setErrors}
          setFormValues={setFormValues}
        />
      );
    }
  };

  // Function to set height if the ref is initialized
  const setHeightIfRefExists = () => {
    if (documentPreviewForPrintRef?.current) {
      setHeight(documentPreviewForPrintRef.current.clientHeight);
    }
  };

  // Check if the ref exists and then set the height
  useEffect(() => {
    if (!isLoading && detail_payment !== null) {
      setTimeout(() => {
        // console.log("timeout");
        setHeightIfRefExists();
        setIsDoneFetchHeightDocumentPreview(true);
      }, 2000);
    }
  }, [isLoading, detail_payment, documentPreviewForPrintRef]); // Dependency array to watch for changes in the ref

  // useEffect(() => {
  //   // console.error('================');
  //   console.log(errors, "ini effect error");
  //   // console.error('================');
  // }, [errors]);

  // useEffect(() => {
  //   // console.warn('~~~~~~~~~~~~~~~~~~~~')
  //   console.log(formValues, "ini effect form");
  //   // console.warn('~~~~~~~~~~~~~~~~~~~~')
  // }, [formValues]);

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={handleBackBtn}
                  sx={{ marginRight: ".5rem" }}
                >
                  <img src={ArrowBackIcon} />
                </IconButton>
                {"Detail Payment"}
              </Box>
            </div>
          }
        />
        <Divider />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            maxHeight: "62vh",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                {/* <ForPrintingV3 /> */}
                <DocumentForPrint
                  detailPR={detailPayment}
                  useStyles={useStyles}
                  mode="PAYMENT"
                />
              </Grid>
              <Grid item xs={5.2} style={{ height: "67vh" }}>
                {/* <Detail/> */}
                <DetailDocument
                  detailDocument={detailPayment}
                  //* Set to null
                  attachmentAltenative={null}
                  mode="PAYMENT"
                />
              </Grid>
              <Grid item xs={6.8}>
                {/* <img src="https://s3.bukalapak.com/img/3942411727/w-1000/lacto.png"></img> */}
                <PreviewPDF
                  detailPR={detailPayment}
                  isDoneFetchHeightDocumentPreview={
                    isDoneFetchHeightDocumentPreview
                  }
                  useStyles={useStyles}
                  mode={"PAYMENT"}
                />
                {/* <PreviewPDFV2 /> */}
              </Grid>
            </Grid>
          )}
        </Box>
        <style>
          {`
        .page-break {
          page-break-before: always;
        }
        .DocumentPreview{
          display:none;
        }
        .DocumentPreviewFooter{
          padding: 20px;
          background-color: #09345C;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

          @page {
              // size: A4;
//               size: 210mm 280mm;
            size: 210mm ${pxToMm(height)}mm; /* A4 size */
              margin: 0;
              /* Remove all margins */
          }

          body {
              margin: 0;
          }

          body *{
            visibility:hidden;
          }
.DocumentPreviewFooter{
   position:fixed;
  bottom:0;
  left:0;
  width:100%;
  border-radius:unset;
}
          .DocumentPreviewFooter, .DocumentPreviewFooter *{
              visibility: visible;
 padding: 10px 20px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          print-color-adjust: exact;
          webkit-print-color-adjust: exact; /* For WebKit-based browsers */
        background-color:  #09345C !important; /* Override background color */
        }

          .DocumentPreview{
            display:block;
            position: fixed !important;
            top: 0;

            left: 0;
            width: 100%;
            z-index: 11999;
            visibility: visible;
            page-break-before: always;
          }

          .DocumentPreview,.DocumentPreview *{
              visibility: visible;
          }
        }

        `}
        </style>
        <Box sx={{ paddingBlock: ".6rem" }}>
          {!isLoading && (
            <BottomAction
              handlePrintBtn={handlePrint}
              handleRightAction={handlePay}
              handleSecondRightAction={cancelHandle}
              id={paymentId}
              mode="PAYMENT"
              status={detailPayment?.Status}
            />
          )}
        </Box>
      </Card>
      <Modal open={showModal}>
        <Box>
          <Form
            title={title}
            onCloseModal={handleCloseModal}
            elementForm={renderElementFormModal}
            elementActionButton={renderActionButtonModal}
            onSubmit={handleSubmitForm}
          ></Form>
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onCloseModal={handleYesConfirmModal}
        onConfirmModal={handleCloseConfirmModal}
        message={modalConfirmMessage}
      ></ModalConfirmCancel>
    </>
  );
}

export default DetailPayment;
