import React from "react";
import {
  ACTION_TYPE,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../util/function";
import { fields, ANOTHER_INPUT_TYPE } from "../parts/imutable_state";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
  SelectOneAutocompleteSearch,
} from "./../../../../components/Form";
import ViewIcon from "../../../../assets/eye.png";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../../util/style";
import RemoveIcon from "../../../../assets/minus.svg";
import ConfirmDelete from "../../../../components/ConfirmDelete";

function FormContent({
  actionType,
  formValues,
  isLoadedFormValues,
  options,
  errors,
  selectedData,
  dataDeleted,
  handleRemoveFile,
  handleAddFile,
  handleFileSelect,
  handleInputChange,
  handleInputBlur,
  chooseCategory,
}) {
  //* Individual Component
  const renderSelectOneAutocomplite = (field, fieldOptions) => {
    if (field.name === "currency") {
      return (
        <React.Fragment key={field.name}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <SelectOneAutocomplete
                field={field}
                formValues={formValues}
                errors={errors}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                fieldOptions={fieldOptions}
                tip={field?.tip}
              />
            </Grid>
            <Grid item xs={10} style={{ marginTop: "1.4rem" }}>
              <TextInput
                field={fields.find((fiel) => fiel.type === INPUT_TYPE.CURRENCY)}
                formValues={formValues}
                errors={errors}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                tip={field?.tip}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (field.name === "service_id" || field.name === "sub_service_id") {
      return chooseCategory === 1 ? (
        <React.Fragment key={field.name}>
          <SelectOneAutocomplete
            field={field}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            fieldOptions={fieldOptions}
            tip={field?.tip}
          />
        </React.Fragment>
      ) : null;
    } else if (field.name === "client_id") {
      if (formValues["category_id"] !== 2) {
        return (
          <SelectOneAutocompleteSearch
            // disabled={}
            name={field.name}
            errors={errors}
            handleInputChange={handleInputChange}
            title={field.label}
            url={"/master/clients"}
            value={formValues[field.name]}
          />
        );
      } else {
        return null;
      }
    } else {
      return (
        <React.Fragment key={field.name}>
          <SelectOneAutocomplete
            field={field}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            fieldOptions={fieldOptions}
            tip={field?.tip}
          />
        </React.Fragment>
      );
    }
  };

  if (!isLoadedFormValues) {
    if (
      //       (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      actionType === ACTION_TYPE.EDITED ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      // console.log(formValues, "dalam render form");
      return (
        <>
          <Grid>
            {fields.map((field) => {
              // # Old
              // const fieldOptions = Array.isArray(options[field.name])
              //   ? [
              //     field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //       id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //       // name: `Select ${field.label}`,
              //       name: `Choose Option`,
              //     },
              //     ...options[field.name],
              //   ]
              //   : [];

              // # New
              let optionsData;
              let fieldOptions = [];
              if (
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE &&
                field.name == "currency"
              ) {
                fieldOptions = field.options;
              } else if (Array.isArray(options[field.name])) {
                let isRequired = null;
                if (field.required && field.type === INPUT_TYPE.SELECT_ONE) {
                  isRequired = {
                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    name: "Choose Option",
                  };
                }

                if (isRequired) {
                  optionsData = [...options[field.name], isRequired];
                } else {
                  optionsData = [...options[field.name]];
                }

                fieldOptions = Array.isArray(options[field.name])
                  ? optionsData
                  : [];
              }

              let fieldComponent = null;

              field.additionalProps = {};

              const listFieldEditMode = [
                "sub_service_id",
                "total_price",
                "note",
              ];

              if (
                actionType === ACTION_TYPE.EDITED &&
                !listFieldEditMode.includes(field.name)
              ) {
                field.additionalProps["disabled"] = true;
                field.additionalProps["readOnly"] = true;
              }
              switch (field.type) {
                case INPUT_TYPE.TEXT:
                case INPUT_TYPE.NUMBER:
                case INPUT_TYPE.PHONE_NUMBER:
                case INPUT_TYPE.POINT:
                  fieldComponent = (
                    <TextInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.PASSWORD:
                  fieldComponent = (
                    <PasswordInputWithValidation
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TEXTAREA:
                  fieldComponent = (
                    <TextArea
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field.tip}
                    />
                  );
                  break;
                case ANOTHER_INPUT_TYPE.FILE:
                  let childName = field.children[0].name;
                  const getUrlFile = (fileObj) => {
                    console.log({ fileObj });
                    if (fileObj instanceof File) {
                      return window.URL.createObjectURL(fileObj);
                    } else if (
                      typeof fileObj === "object" &&
                      "url" in fileObj
                    ) {
                      return fileObj.url;
                    }
                    return "";
                  };
                  fieldComponent = (
                    <Grid item xs={12} key={field.title}>
                      {field.isDisplayTitle !== false && (
                        <Box sx={{ marginBottom: 2 }}>
                          {/* Title */}
                          <Typography variant="h6" sx={{ fontWeight: 800 }}>
                            {field.title}
                          </Typography>
                          {field.subtitle && (
                            <Typography variant="subtitle2">
                              {field.subtitle}
                            </Typography>
                          )}
                        </Box>
                      )}
                      {formValues[childName].map((_, index) => {
                        const urlAttachment = getUrlFile(
                          formValues[childName][index]
                        );
                        return (
                          <div key={index}>
                            <Grid container spacing={0.3}>
                              <Grid item xs={urlAttachment ? 9.5 : 10.5}>
                                <Grid container>
                                  <Grid container>
                                    <Grid item xs={11.3}>
                                      <FilePicker
                                        indexFile={index}
                                        field={field.children[0]}
                                        formValues={
                                          formValues[childName][index]
                                        }
                                        errors={errors}
                                        onFileSelect={(file) =>
                                          handleFileSelect(
                                            file,
                                            childName,
                                            index
                                          )
                                        }
                                        handleInputChange={(e) =>
                                          handleInputChange(
                                            e,
                                            field,
                                            index,
                                            childName
                                          )
                                        }
                                        tip={field.children[0]?.tip}
                                        // URIForViewDocument={urlAttachment}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {urlAttachment && (
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    // background: "yellow",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                  title="Preview attachment"
                                    onClick={(e) => e.stopPropagation()}
                                    // style={{ background: "green" }}
                                  >
                                    {urlAttachment && (
                                      <a
                                        href={urlAttachment}
                                        onClick={(e) => e.stopPropagation()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img src={ViewIcon} alt="Cloud Icon" />
                                      </a>
                                    )}
                                  </div>
                                </Grid>
                              )}
                              <Grid
                                item
                                xs={1.5}
                                sx={{
                                  marginTop: "1.25rem",
                                  // background: "#0a3",
                                  display: "grid",
                                  placeItems: "center",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  disableElevation
                                  sx={{
                                    backgroundColor: (theme) =>
                                      theme.palette.primary.main,
                                    ...removeButton,
                                  }}
                                  onClick={() =>
                                    handleRemoveFile(childName, index)
                                  }
                                  // disabled={
                                  //   (actionType === ACTION_TYPE.ADD &&
                                  //     formValues[childName].length === 1) ||
                                  //   actionType === ACTION_TYPE.EDITED
                                  // }
                                >
                                  <img
                                    src={RemoveIcon}
                                    alt={`Remove ${childName}`}
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                      {(actionType !== ACTION_TYPE.EDITED ||
                        formValues[childName].length < 1) && (
                        <Box sx={{ ...justifyContentEnd }}>
                          <Button
                            onClick={() => handleAddFile(childName)}
                            disabled={
                              actionType === ACTION_TYPE.EDITED &&
                              formValues[childName].length > 1
                            }
                          >
                            <span
                              style={{
                                fontSize: "1.5rem",
                                marginRight: ".6rem",
                                display: "inline-block",
                              }}
                            >
                              +
                            </span>
                            Add More
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE:
                  fieldComponent = (
                    <SelectOne
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                  fieldComponent = renderSelectOneAutocomplite(
                    field,
                    fieldOptions
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
                  fieldComponent = (
                    <SelectOneCountryAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field?.tip}
                    />
                  );

                  break;
                case INPUT_TYPE.SELECT_MULTIPLE:
                  fieldComponent = (
                    <SelectMultiple
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectMultipleAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.CHECKBOX:
                  fieldComponent = (
                    <CheckboxGroupInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={
                        fieldOptions.length
                          ? fieldOptions.filter((option) => option !== false)
                          : field.options
                      }
                    />
                  );
                  break;
                case INPUT_TYPE.RADIO:
                  fieldComponent = (
                    <RadioGroupInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={
                        fieldOptions.length
                          ? fieldOptions.filter((option) => option !== false)
                          : field.options
                      }
                    />
                  );
                  break;
                case INPUT_TYPE.DATE:
                  fieldComponent = (
                    <DatePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TIME:
                  fieldComponent = (
                    <TimePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                default:
                  fieldComponent = null;
              }

              return (
                <Grid
                  item
                  xs={field.gridWidth || 12}
                  key={field.name}
                  sx={{ paddingTop: "unset !important" }}
                >
                  {fieldComponent}
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={dataDeleted} />;
    }
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <FacebookCircularProgress />
      </Box>
    );
  }
}

export default FormContent;
