import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Avatar,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import DocumentIcon from "./../../assets/document.png";
import SearchIconBlack from "./../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../assets/back.svg";
import { AccessRestrictedAlert } from "./../../components/Alert";

import {
  borderRadius,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
  alignItemsCenter,
  mainColor,
  thirdColor,
  fourColor,
  table,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../components/Form";
import {
  // addAttachment,
  // deleteAttachment,
  // editAttachment,
  // getAttachment,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  formatSize,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { modalActionButton } from "../../util/style";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowBackWhiteIcon from "./../../assets/back-white.svg";
import { RequestLoading } from "../../util/global_state";

const employeeDetail = {
  "Personal Information": [
    {
      title: "Basic Information",
      children: [
        {
          title: "Personal Data",
          children: {
            "Employe Name": "Jacob Jones",
            "Foto Profile": null,
            // Birthdate: {
            //   date: "24 January 2000",
            //   count: "23 years old",
            // },
            "Place Of Birth": "Bekasi",
            Gender: "Male",
            Religion: "Islam",
            "Marital Status": "Single",
            "Blood Type": "A+",
          },
        },
        {
          title: "Contact",
          children: {
            Email: "john.jacob@gmail.com",
            "Mobile Phone": "+62 880 2151 3115",
            Phone: "(252) 555-0126",
          },
        },
        {
          title: "Identity & Address",
          children: {
            "Identitity Type": "KTP",
            "Identitity Number": "0845856102138520",
            "Identitity Expiration Date": "Permanent",
            "Postal Code": "10320",
            "Citizen ID Address":
              "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10260",
            "Residential Address":
              "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10260",
          },
        },
      ],
    },
    {
      title: "Family",
      children: [
        {
          title: "Jeevan Das",
          children: {
            Relationship: "Father",
            Birthdate: "06 May 1972",
            "Identitity Number": "0845856102138420",
            Gender: "Male",
            Religion: "Islam",
            "Marital Status": "Married",
            Job: "Karyawan Swasta",
          },
        },
        {
          title: "Emilie Francois",
          children: {
            Relationship: "Mother",
            Birthdate: "06 May 1972",
            "Identitity Number": "0845856102138420",
            Gender: "Male",
            Religion: "Islam",
            "Marital Status": "Married",
            Job: "Karyawan Swasta",
          },
        },
      ],
    },
    {
      title: "Emergency Contact",
      children: [
        {
          title: "Jeevan Das",
          children: {
            Relationship: "Father",
            "Phone Number": "0845856102138420",
          },
        },
      ],
    },
  ],
  Employment: [
    {
      title: "", // dinullkan saja, sesuai design
      children: {
        "Company Name": "PT. Pacific Patent Multiglobal",
        "Employe ID": "102138520",
        "Organization Name": "Development",
        "Job Position": "Software Development",
        "Job Level": "Staff",
        "Employee Status": "Contract",
        Brench: "Pusat",
        "Join Date": {
          date: "04 December 2020",
          count: "3 years 2 months 10 days",
        },
        "Sign Date": "-",
        "End Date": "04 December 2023",
        "Approval Line": "Dianne Russell as Director",
      },
    },
    {
      title: "Benefit",
      children: {
        Allowances: "Rp. 200.000",
        "Meal Allowance": "Rp. 30.000",
      },
    },
  ],
  "Education & Experience": [
    {
      title: "Formal Education",
      children: [
        {
          title: "Bachelor Degree (S1)",
          children: {
            "Institution Name": "Universitas Indoesia",
            Majors: "Design Komunikasi Visual",
            Score: "4.0",
            "Start Year": "January 2016",
            "End Year": "January 2020",
            Certificates: [
              {
                filename: "certificatedoc1.pdf",
                size: "3.2 MB",
                url: "https://download_certificate1.pdf",
              },
              {
                filename: "certificatedoc2.pdf",
                size: "4.2 MB",
                url: "https://download_certificate2.pdf",
              },
            ],
          },
        },
        {
          title: "Bachelor Degree (S2)",
          children: {
            "Institution Name": "Universitas Indoesia",
            Majors: "Design Komunikasi Visual",
            Score: "4.0",
            "Start Year": "January 2016",
            "End Year": "January 2020",
            Certificates: [
              {
                filename: "certificatedoc1.pdf",
                size: "3.2 MB",
                url: "https://download_certificate1.pdf",
              },
              {
                filename: "certificatedoc2.pdf",
                size: "4.2 MB",
                url: "https://download_certificate2.pdf",
              },
            ],
          },
        },
      ],
    },
    {
      title: "Informal Education",
      children: [
        {
          title: "Kursus Bahasa Arab",
          children: {
            "Organized By": "Kursus Bahasa Arab",
            Fee: "10.000.000",
            "Start Date": "January 2016",
            "End Date": "February 2018",
            Duration: "2 year 1 month",
            Certificates: [
              {
                filename: "certificatedoc1.pdf",
                size: "3.2 MB",
                url: "https://download_certificate1.pdf",
              },
              {
                filename: "certificatedoc2.pdf",
                size: "4.2 MB",
                url: "https://download_certificate2.pdf",
              },
            ],
          },
        },
        {
          title: "Kursus Bahasa Inggris",
          children: {
            "Organized By": "Kursus Bahasa Inggris",
            Fee: "10.000.000",
            "Start Date": "January 2016",
            "End Date": "February 2018",
            Duration: "2 year 1 month",
            Certificates: [
              {
                filename: "certificatedoc1.pdf",
                size: "3.2 MB",
                url: "https://download_certificate1.pdf",
              },
              {
                filename: "certificatedoc2.pdf",
                size: "4.2 MB",
                url: "https://download_certificate2.pdf",
              },
            ],
          },
        },
      ],
    },
    {
      title: "Work Exprience",
      children: [
        {
          title: "PT. MajuJaya",
          children: {
            "Job Position": "Admin",
            Start: "January 2016",
            To: "May 2018",
            "Length Of Service": "2 year 4 month",
          },
        },
        {
          title: "PT. HarapanJaya",
          children: {
            "Job Position": "Admin",
            Start: "January 2016",
            To: "May 2018",
            "Length Of Service": "2 year 4 month",
          },
        },
      ],
    },
  ],
  "Other Information": [
    {
      title: "Supporting Attachments",
      children: {
        "Bank Name": "Mandiri",
        "Bank Account": "1520019038880",
        "Bank Account Holder": "JACOB JONES",
        "BPJS Ketenagakerjaan": "3370 2354 0962 9234",
        "BPJS Kesehatan": "458272984631",
      },
    },
    {
      title: "Tax Config",
      children: {
        NPWP: "01.234.667.8-123.000",
        "PTKP Status": "TK/0 (Tanpa Tanggungan)",
        "Employee Tax Status": "Pegawai Tetap",
        "Tax Method": "Gross Up",
      },
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tab: {
    transform: "rotate(-90deg)",
    whiteSpace: "nowrap",
    minWidth: "auto",
    marginRight: theme.spacing(2),
    "&.Mui-selected": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
}));

const DetailEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);
  const { token } = useSelector((state) => state.auth);
  const [options, setOptions] = useState({
    employes: [], // Example for service categories
  });
  const params = useParams();
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isFetchLoading2, setIsFetchLoading2] = useState(true);
  const [id, setId] = useState(parseInt(params.id));
  const [selectedIdEmployee, setSelectedIdEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState("-");
  const [profilePicture, setProfilePicture] = useState("-");
  const [employeeDetail, setEmployeeDetail] = useState({
    "Personal Information": [
      {
        title: "Basic Information",
        children: [
          {
            title: "Personal Data",
            children: {
              "Employe Name": "Jacob Jones",
              // Birthdate: {
              //   date: "24 January 2000",
              //   count: "23 years old",
              // },
              "Place Of Birth": "Bekasi",
              Gender: "Male",
              Religion: "Islam",
              "Marital Status": "Single",
              "Blood Type": "A+",
            },
          },
          {
            title: "Contact",
            children: {
              Email: "john.jacob@gmail.com",
              "Mobile Phone": "+62 880 2151 3115",
              Phone: "(252) 555-0126",
            },
          },
          {
            title: "Identity & Address",
            children: {
              "Identitity Type": "KTP",
              "Identitity Number": "0845856102138520",
              "Identitity Expiration Date": "Permanent",
              "Postal Code": "10320",
              "Citizen ID Address":
                "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10260",
              "Residential Address":
                "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10260",
            },
          },
        ],
      },
      {
        title: "Family",
        children: [
          {
            title: "Jeevan Das",
            children: {
              Relationship: "Father",
              Birthdate: "06 May 1972",
              "Identitity Number": "0845856102138420",
              Gender: "Male",
              Religion: "Islam",
              "Marital Status": "Married",
              Job: "Karyawan Swasta",
            },
          },
          {
            title: "Emilie Francois",
            children: {
              Relationship: "Mother",
              Birthdate: "06 May 1972",
              "Identitity Number": "0845856102138420",
              Gender: "Male",
              Religion: "Islam",
              "Marital Status": "Married",
              Job: "Karyawan Swasta",
            },
          },
        ],
      },
      {
        title: "Emergency Contact",
        children: [
          {
            title: "Jeevan Das",
            children: {
              Relationship: "Father",
              "Phone Number": "0845856102138420",
            },
          },
        ],
      },
    ],
    Employment: [
      {
        title: "", // dinullkan saja, sesuai design
        children: {
          "Company Name": "PT. Pacific Patent Multiglobal",
          "Employe ID": "102138520",
          "Organization Name": "Development",
          "Job Position": "Software Development",
          "Job Level": "Staff",
          "Employee Status": "Contract",
          Brench: "Pusat",
          "Join Date": {
            date: "04 December 2020",
            count: "3 years 2 months 10 days",
          },
          "Sign Date": "-",
          "End Date": "04 December 2023",
          "Approval Line": "Dianne Russell as Director",
        },
      },
      {
        title: "Benefit",
        children: {
          Allowances: "Rp. 200.000",
          "Meal Allowance": "Rp. 30.000",
        },
      },
    ],
    "Education & Experience": [
      {
        title: "Formal Education",
        children: [
          {
            title: "Bachelor Degree (S1)",
            children: {
              "Institution Name": "Universitas Indoesia",
              Majors: "Design Komunikasi Visual",
              Score: "4.0",
              "Start Year": "January 2016",
              "End Year": "January 2020",
              Certificates: [
                {
                  filename: "certificatedoc1.pdf",
                  size: "3.2 MB",
                  url: "https://download_certificate1.pdf",
                },
                {
                  filename: "certificatedoc2.pdf",
                  size: "4.2 MB",
                  url: "https://download_certificate2.pdf",
                },
              ],
            },
          },
          {
            title: "Bachelor Degree (S2)",
            children: {
              "Institution Name": "Universitas Indoesia",
              Majors: "Design Komunikasi Visual",
              Score: "4.0",
              "Start Year": "January 2016",
              "End Year": "January 2020",
              Certificates: [
                {
                  filename: "certificatedoc1.pdf",
                  size: "3.2 MB",
                  url: "https://download_certificate1.pdf",
                },
                {
                  filename: "certificatedoc2.pdf",
                  size: "4.2 MB",
                  url: "https://download_certificate2.pdf",
                },
              ],
            },
          },
        ],
      },
      {
        title: "Informal Education",
        children: [
          {
            title: "Kursus Bahasa Arab",
            children: {
              "Organized By": "Kursus Bahasa Arab",
              Fee: "10.000.000",
              "Start Date": "January 2016",
              "End Date": "February 2018",
              Duration: "2 year 1 month",
              Certificates: [
                {
                  filename: "certificatedoc1.pdf",
                  size: "3.2 MB",
                  url: "https://download_certificate1.pdf",
                },
                {
                  filename: "certificatedoc2.pdf",
                  size: "4.2 MB",
                  url: "https://download_certificate2.pdf",
                },
              ],
            },
          },
          {
            title: "Kursus Bahasa Inggris",
            children: {
              "Organized By": "Kursus Bahasa Inggris",
              Fee: "10.000.000",
              "Start Date": "January 2016",
              "End Date": "February 2018",
              Duration: "2 year 1 month",
              Certificates: [
                {
                  filename: "certificatedoc1.pdf",
                  size: "3.2 MB",
                  url: "https://download_certificate1.pdf",
                },
                {
                  filename: "certificatedoc2.pdf",
                  size: "4.2 MB",
                  url: "https://download_certificate2.pdf",
                },
              ],
            },
          },
        ],
      },
      {
        title: "Work Exprience",
        children: [
          {
            title: "PT. MajuJaya",
            children: {
              "Job Position": "Admin",
              Start: "January 2016",
              To: "May 2018",
              "Length Of Service": "2 year 4 month",
            },
          },
          {
            title: "PT. HarapanJaya",
            children: {
              "Job Position": "Admin",
              Start: "January 2016",
              To: "May 2018",
              "Length Of Service": "2 year 4 month",
            },
          },
        ],
      },
    ],
    "Other Information": [
      {
        title: "Supporting Attachments",
        children: {
          "Bank Name": "Mandiri",
          "Bank Account": "1520019038880",
          "Bank Account Holder": "JACOB JONES",
          "BPJS Ketenagakerjaan": "3370 2354 0962 9234",
          "BPJS Kesehatan": "458272984631",
        },
      },
      {
        title: "Tax Config",
        children: {
          NPWP: "01.234.667.8-123.000",
          "PTKP Status": "TK/0 (Tanpa Tanggungan)",
          "Employee Tax Status": "Pegawai Tetap",
          "Tax Method": "Gross Up",
        },
      },
    ],
  });

  useEffect(() => { }, [dispatch]);

  useEffect(() => {
    setIsFetchLoading(true);

    // Fetch employes options
    axios
      .get("/master/employes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        setOptions((prevOptions) => ({
          ...prevOptions,
          employes: modifiedData,
        }));

        setIsFetchLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching service categories:", error);
      });
  }, []);

  useEffect(() => {
    setSelectedIdEmployee(parseInt(id));
  }, [id]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      setIsFetchLoading2(true);
      if (selectedIdEmployee) {
        try {
          navigate(`/human-resources/employees/detail/${selectedIdEmployee}`);
          const response = await axios.get(`/employes/${selectedIdEmployee}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setEmployeeDetail(response.data.data);
          setIsFetchLoading2(false);
        } catch (error) {
          console.error("Error fetching employee data:", error);
        }
      }
    };

    fetchEmployeeData();
  }, [selectedIdEmployee, token]);

  useEffect(() => {
    setEmployeeName(
      employeeDetail["Personal Information"][0]["children"][0]["children"][
      "Employe Name"
      ] || "-"
    );
    setProfilePicture(
      employeeDetail["Personal Information"][0]["children"][0]["children"][
        "Foto Profile"
        ] || null
    )
  }, [employeeDetail]);

  const handleInputChange = ({ target: { value } }) => {
    if (value && value !== -1) {
      setSelectedIdEmployee(parseInt(value));
    } else {
      setSelectedIdEmployee(parseInt(id));
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSubValue(0); // Reset subValue when switching between main tabs
  };

  const handleSubTabChange = (event, newSubValue) => {
    setSubValue(newSubValue);
  };

  const mainTabs = Object.keys(employeeDetail).map((key, index) => (
    <Tab
      label={key}
      key={key}
      value={index}
      sx={{
        ...(value === index && {
          color: mainColor, // Change text color when active
          "&:hover": {
            backgroundColor: "transparent", // Remove hover background when active
          },
          "& .MuiDivider-vertical": {
            display: "none", // Hide the dividers between tabs
          },
        }),
        borderColor: "transparent", // Remove border for all tabs
        fontWeight: "400",
        fontSize: "13px",
        color: "black",
        alignItems: "flex-start !important",
        minHeight: "unset !important",
      }}
    />
  ));

  const subTabs = employeeDetail[mainTabs[value].props.label].map(
    (subTab, index) => (
      <Tab label={subTab.title} key={subTab.title} value={index} />
    )
  );

  const renderPersonalInformation = () => {
    const data = employeeDetail["Personal Information"];
    const subKey = data[subValue].title;
    const subData = data[subValue].children;

    function removeKeyFromData(data, keyToRemove) {
      // Check if the data is an array
      if (Array.isArray(data)) {
          // Iterate through each item in the array
          data.forEach(item => {
              if (item.children) {
                  // Recursively call the function for children
                  removeKeyFromData(item.children, keyToRemove);
              }
          });
      } else if (typeof data === 'object' && data !== null) {
          // If data is an object, check for the key to remove
          if (data.hasOwnProperty(keyToRemove)) {
              delete data[keyToRemove];
          }
  
          // Recursively call the function for nested objects
          Object.keys(data).forEach(key => {
              if (typeof data[key] === 'object') {
                  removeKeyFromData(data[key], keyToRemove);
              }
          });
      }
    }

    removeKeyFromData(data, "Foto Profile")

    return (
      <div>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Personal Information
        </Typography>
        <Tabs
          value={subValue}
          onChange={handleSubTabChange}
          aria-label="Horizontal tabs example"
        >
          {data.map((subTab, index) => (
            <Tab label={subTab.title} key={subTab.title} value={index} />
          ))}
        </Tabs>
        <div
          style={{
            maxHeight: "360px",
            overflow: "auto",
          }}
        >
          {renderNestedData("Personal Information", subData, subKey)}
        </div>
      </div>
    );
  };

  const renderEmployment = () => {
    const data = employeeDetail["Employment"];
    const subKey = data[subValue].title;
    return (
      <div>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Employment
        </Typography>
        <div
          style={{
            maxHeight: "360px",
            overflow: "auto",
          }}
        >
          {data.map((employmentItem, index) => (
            <div key={index}>
              <h2>{employmentItem.title}</h2>
              <div>
                {renderNestedData(
                  "Employment",
                  employmentItem.children,
                  subKey
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEducationAndExperience = () => {
    const data = employeeDetail["Education & Experience"];
    // debugger;
    const subKey = data[subValue].title;
    const subData = data[subValue].children;

    return (
      <div>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Education & Experience
        </Typography>
        <Tabs
          value={subValue}
          onChange={handleSubTabChange}
          aria-label="Horizontal tabs example"
        >
          {data.map((subTab, index) => (
            <Tab label={subTab.title} key={subTab.title} value={index} />
          ))}
        </Tabs>
        <div
          style={{
            maxHeight: "360px",
            overflow: "auto",
          }}
        >
          {renderNestedData("Education & Experience", subData, subKey)}
        </div>
      </div>
    );
  };

  const renderOtherInformation = () => {
    const data = employeeDetail["Other Information"];
    const subKey = data[subValue].title;
    return (
      <div>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Other Information
        </Typography>
        <div
          style={{
            maxHeight: "360px",
            overflow: "auto",
          }}
        >
          {data.map((otherIinformationItem, index) => (
            <div key={index}>
              <h2>{otherIinformationItem.title}</h2>
              <div>
                {renderNestedData(
                  "Other Information",
                  otherIinformationItem.children,
                  subKey
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderData = () => {
    const mainKey = mainTabs[value].props.label;
    if (mainKey === "Personal Information") {
      return renderPersonalInformation();
    } else if (mainKey === "Employment") {
      return renderEmployment();
    } else if (mainKey === "Education & Experience") {
      return renderEducationAndExperience();
    } else if (mainKey === "Other Information") {
      return renderOtherInformation();
    }
    // Handle other sections as needed
  };

  // const renderData = () => {
  //   const mainKey = mainTabs[value].props.label;
  //   const subKey = employeeDetail[mainKey][subValue].title;
  //   const data = employeeDetail[mainKey][subValue].children;

  //   return (
  //     <div>
  //       <h1>{mainKey}</h1>
  //       <Tabs
  //         value={subValue}
  //         onChange={handleSubTabChange}
  //         aria-label="Horizontal tabs example"
  //       >
  //         {subTabs}
  //       </Tabs>
  //       <div>{renderNestedData(data)}</div>
  //     </div>
  //   );
  // };

  const renderNestedData = (mainKey, data, subKey) => {
    let sectionCounters = {
      "Personal Information": 0,
      Employment: 0,
      "Education & Experience": 0,
      "Other Information": 0,
    };

    return (
      <>
        {Array.isArray(data)
          ? data.map((sectionData, index) => {
            const sectionTitle = sectionData.title;
            if (sectionTitle) {
              sectionCounters[mainKey]++;
            }

            return (
              <React.Fragment key={index}>
                {sectionTitle && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: " flex",
                      fontWeight: "500",
                      fontSize: "1.1rem",
                      margin: "1rem 0",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        background: mainColor,
                        borderRadius: "100%",
                        height: " 20px",
                        width: " 20px",
                        display: " flex",
                        alignItems: " center",
                        justifyContent: " center",
                        color: " white",
                        fontWeight: " bold",
                      }}
                    >
                      {sectionCounters[mainKey]}
                    </span>
                    {sectionTitle}
                  </Typography>
                )}

                {Object.entries(sectionData.children).map(
                  ([key, value], i) => {
                    return (
                      <React.Fragment key={i}>
                        {!(
                          (subKey !== "Family" && key === "Birthdate") ||
                          key === "Certificates" ||
                          key === "Join Date"
                        ) ? (
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                margin: ".3rem 0",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  minWidth: "300px",
                                }}
                              >
                                {key}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "500",
                                }}
                              >
                                {value !== null && typeof value === "object"
                                  ? // Handle the case where value is an object
                                  renderNestedObject(value)
                                  : value}
                              </Typography>
                            </Box>
                          ) : null}

                        {key === "Certificates"
                          ? // Handle the "Certificate" object with filename, size, and url
                          renderCertificates(value)
                          : null}

                        {(subKey !== "Family" && key === "Birthdate") ||
                          key === "Join Date"
                          ? renderDate(key, value)
                          : null}
                      </React.Fragment>
                    );
                  }
                )}
              </React.Fragment>
            );
          })
          : // Handle the case where data is an object
          Object.entries(data).map(([key, value], index) => {
            return (
              <React.Fragment key={index}>
                {!(
                  (subKey !== "Family" && key === "Birthdate") ||
                  key === "Certificates" ||
                  key === "Join Date"
                ) ? (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        margin: ".3rem 0",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          minWidth: "300px",
                        }}
                      >
                        {key}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "500",
                        }}
                      >
                        {value !== null && typeof value === "object"
                          ? // Handle the case where value is an object
                          renderNestedObject(value)
                          : value}
                      </Typography>
                    </Box>
                  ) : null}

                {(subKey !== "Family" && key === "Birthdate") ||
                  key === "Join Date"
                  ? renderDate(key, value)
                  : null}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  const renderNestedObject = (obj) => {
    return (
      <div>
        {Object.entries(obj).map(([key, value], i) => (
          <div key={i}>
            {/* <strong>{key}: </strong> */}
            {value}
          </div>
        ))}
      </div>
    );
  };

  const renderCertificates = (certificates) => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          margin: ".3rem 0",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            minWidth: "300px",
          }}
        >
          Certificates
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
          }}
        >
          {certificates.map((item, i) => (
            <div
              key={i}
              style={{
                ...alignItemsCenter,
                gap: "1rem",
                minWidth: "600px",
                padding: "0.5rem 0",
              }}
            >
              <img src={DocumentIcon} alt="Document Icon" />
              <span>{item.filename}</span>
              <span style={{ textTransform: "uppercase", color: fourColor }}>
                {formatSize(item.size)}
              </span>
              <a href={item.url} target="_blank">
                Download
              </a>
            </div>
          ))}
        </Typography>
      </Box>
    );
  };

  const renderDate = (key, value) => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          margin: ".3rem 0",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            minWidth: "300px",
          }}
        >
          {key}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
          }}
        >
          {value.date} &nbsp;&nbsp;
          {value.count && (
            <StatusBadge isinfo={true}>{value.count}</StatusBadge>
          )}
        </Typography>
      </Box>
    );
  };

  const formatDate = (dateObj) => {
    return `${dateObj.date}, ${dateObj.count}`;
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}

      <Grid container spacing={2}>
        <Grid item xs={0.7} sx={{ ...alignItemsCenter }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              ...removeButton,
            }}
            onClick={() => navigate("/human-resources/employees")}
          >
            <img src={ArrowBackWhiteIcon} />
          </Button>
        </Grid>

        <Grid item xs={11.3}>
          <Card
            style={{
              boxShadow: "unset",
              borderRadius,
              border: "1px solid #ddd",
            }}
          >
            <CardContent>
              <Autocomplete
                options={options["employes"] || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  options["employes"].find(
                    (option) => option.id === selectedIdEmployee
                  ) || -1
                }
                onChange={(event, newValue) =>
                  handleInputChange({
                    target: {
                      value: newValue ?.id || -1,
                    },
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
                sx={{ ...formGroup }}
              ></Autocomplete>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATA EMLOYEe                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          borderBottom: "0px",
          border: "1px solid #ddd",
          minHeight: "68vh",
          maxHeight: "70vh",
        }}
      >
        <CardContent
          sx={{
            "& .MuiDivider-vertical": {
              display: "none", // Hide the dividers between tabs
            },
            marginBottom: 2,
          }}
        >
          {isFetchLoading || isFetchLoading2 ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={2.7}
                  sx={{
                    position: "relative",
                    minHeight: "450px",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        border: `1px solid ${thirdColor}`,
                        borderRadius: "10px",
                        flexGrow: 1,
                        padding: "10px",
                        borderBottomLeftRadius: "unset",
                        borderBottomRightRadius: "unset",
                      }}
                    >
                      <Avatar
                        alt={employeeName}
                        src={profilePicture}
                        sx={{
                          width: "100%",
                          height: "170px",
                          borderRadius: "8px",
                          fontSize: "5rem",
                          fontWeight: "bold",
                          marginBottom: 1,
                        }}
                      />
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                          "& .MuiTabs-indicator": {
                            display: "none", // Hide the active tab border
                          },
                        }}
                      >
                        {mainTabs}
                      </Tabs>
                    </Box>
                    <Box
                      sx={{
                        border: `1px solid ${thirdColor}`,
                        borderRadius: "10px",
                        borderTopLeftRadius: "unset",
                        borderTopRightRadius: "unset",
                        padding: "10px",
                      }}
                    >
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="button"
                        onClick={() =>
                          navigate(
                            `/human-resources/employees/edit/${selectedIdEmployee}/${mainTabs[value].props.label}`
                          )
                        }
                      >
                        Edit Employee
                    </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={8.9}
                  sx={
                    {
                      // overflow: "auto",
                      // position: "relative",
                    }
                  }
                >
                  <Typography className={useStyles.tabPanel}>
                    {renderData()}
                  </Typography>
                </Grid>
              </Grid>
            )}
        </CardContent>
      </Card>
    </>
  );
};

export default DetailEmployee;
