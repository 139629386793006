import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { Toolbar, Autocomplete, } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  secondColor,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  inputDate,
  btnTableToolbar,
  imgBtnToolbar,
  fourColor,
  FacebookCircularProgress,
  CustomOpenPickerButton,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  AttachmentItem,
  removeButton,
  alignItemsCenter,
  justifyContentEnd,
} from "../../../util/style";
import ArrowBackWhiteIcon from "./../../../assets/back-white.svg";
import exportIcon from "./../../../assets/export.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../../components/Form";
import {
  getTimeOffRequestDetail,
  getTimeOffRequest,
  addTimeOffRequest,
  editTimeOffRequest,
  deleteTimeOffRequest,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import DetailTimeline from "./../../business-permit/application/detail-timeline";

const DetailTimeOffRequest = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
    const ANOTHER_ACTION_TYPE = {
      PREVIEW: 'PREVIEW',
      MAP: 'MAP'
    }

  const [isPresenceHadir, setIsPresenceHadir] = useState(true);

  const isPresenceHadirDisabled = () => {
    return !isPresenceHadir;
  };

  // const isPresenceHadirRequired = () => {
  //   return isPresenceHadir;
  // };

  const fields = [
//     {
//       name: "employee_name",
//       type: INPUT_TYPE.TEXT,
//       label: "Employee Name",
//       defaultValue: "",
//       required: true,
//       additionalProps: {
//         disabled: true,
//       },
//     },
//     {
//       name: "date",
//       type: INPUT_TYPE.DATE,
//       label: "Date",
//       defaultValue: "",
//       required: true,
//       formatDate: "DD/MM/YYYY", // Custom date format
//       additionalProps: {
//         disabled: true,
//       },
//     },
//     {
//       name: "time_off_id",
//       type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
//       label: "Presence",
//       required: true,
//       apiEndpoint: "/master/time-off-all-status",
//       placeholder: "Select presence type",
//       additionalProps: {
//         disabled: false,
//       },
//     },
//     {
//       name: "schedule_in_time",
//       type: INPUT_TYPE.TIME,
//       label: "Schedule In",
//       defaultValue: "",
//       required: true,
//       additionalProps: {
//         disabled: false,
//       },
//       gridWidth: 6,
//     },
//     {
//       name: "clock_in_time",
//       type: INPUT_TYPE.TIME,
//       label: "Clock In",
//       defaultValue: "",
//       required: false, //isPresenceHadirRequired(),
//       additionalProps: {
//         disabled: isPresenceHadirDisabled(),
//       },
//       gridWidth: 6,
//     },
//     {
//       name: "schedule_out_time",
//       type: INPUT_TYPE.TIME,
//       label: "Schedule Out",
//       defaultValue: "",
//       required: false,
//       additionalProps: {
//         disabled: false,
//       },
//       gridWidth: 6,
//     },
//     {
//       name: "clock_out_time",
//       type: INPUT_TYPE.TIME,
//       label: "Clock Out",
//       defaultValue: "",
//       required: false, //isPresenceHadirRequired(),
//       additionalProps: {
//         disabled: isPresenceHadirDisabled(),
//       },
//       gridWidth: 6,
//     },
//     {
//       name: "reason_in",
//       type: INPUT_TYPE.TEXTAREA,
//       label: "Reason for Editing",
//       defaultValue: "",
//       required: false,
//       placeholder: "Reason for Editing",
//     },
//     // Add more fields as needed
  ];

  const columns = [
    {
      field: "code",
      headerName: "No. Reference",
      width: 150,
      sortable: true,
      disableColumnMenu: true,
//       valueGetter: (params) => {
//         return params.row.user ? params.row.user ?.fullname : "-";
//       },
       renderCell: (params) => {
        return (
          <div style={{ color: "blue" }}>
            <a
//               onClick={() => navigate(`detail/${params.row.user_id}`)}
               onClick={() => handleFormModal(ACTION_TYPE.DETAIL, params.row)}
              style={{ textDecoration: "none", cursor: "grab" }}
            >
              {params.value}
            </a>
          </div>
        );
      },
    },
    {
      field: "time_off_type",
      headerName: "Time Off Type",
      sortable: true,
      width: 250,
      disableColumnMenu: true,
    },
     {
      field: "time_off_category",
      headerName: "Time Off Category",
      sortable: true,
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "time_off_date",
      headerName: "Time Off Date",
      fieldSortable: 'start_date',
      sortable: true,
      width: 200,
      disableColumnMenu: true,
    },
     {
      field: "days",
      headerName: "Day/s",
      sortable: true,
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
      return (
            <StatusBadge
              status={params.value}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {params.value} days
            </StatusBadge>
          )
      }
    },
     {
      field: "reason",
      headerName: "Reason",
      sortable: true,
      width: 270,
      disableColumnMenu: true,
    },
    {
      field: "amount_files",
      headerName: "Attach File",
      sortable: false,
      width: 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value) {
          return (
            <div style={{ color: "blue" }}>
              <a
                // onClick={() => navigate(`detail/${params.row.id}`)}
                onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.PREVIEW, params.row)}
                style={{ textDecoration: "none", cursor: "grab" }}
              >
                View File
              </a>
            </div>
          );
        } else {
          return '-'; // Return null if params.value is falsy
        }
      }
    },
    {
    field: "status",
    headerName: "Status",
    // description: "This column has a value getter and is not sortable.",
    sortable: true,
    width: 150,
    disableColumnMenu: true,
    valueGetter: (params) => params.value, // Return the status value as a string
    renderCell: (params) => {
      const statusColors = {
        Pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
        Approved: { bgColor: successSurfaceColor, colors: successMainColor },
        Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
        // Add more statuses as needed
      };

      const statusInfo = statusColors[params.value] || {
        bgColor: infoSurfaceColor,
        colors: infoMainColor,
      };

      return (
        <StatusBadge
          bgColor={statusInfo.bgColor}
          colors={statusInfo.colors}
        >
          {params.value}
        </StatusBadge>
      );
    }
  }


  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState("attendance");
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Attendance");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({
    employes: [],
  });
  const [status, setStatus] = useState(ACTION_TYPE.ALL_STATUS);
  const [category, setCategory] = useState(ACTION_TYPE.ALL_CATEGORY);
  const [periodDateElm, setPeriodDateElm] = useState(dayjs());
  const [periodDate, setPeriodDate] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState('custom_date');
   const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateElm, setStartDateElm] = useState(null);
  const [endDateElm, setEndDateElm] = useState(null);
  const [periodElm, setPeriodElm] = useState(null);
  const [period, setPeriod] = useState(null);
  const [selectedIdEmployee, setSelectedIdEmployee] = useState(null);
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelectedTerm, setIsSelectedTerm] = useState(true);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = useState(parseInt(params.id));

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.timeOffRequestDetail);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

//   useEffect(() => {
//     if (dayjs(periodDateElm).isValid()) {
//       setPeriodDate(dayjs(periodDateElm).format("YYYY-MM-DD"));
//     }
//   }, [periodDateElm]);
//
//   useEffect(() => {
//     if (dayjs(periodDate).isValid()) {
//       dispatch(
//         getTimeOffRequestDetail({
//           token,
//           limit,
//           search,
//           sortDirection,
//           sortBy,
//           dateFilter: periodDate,
//         })
//       );
//     }
//   }, [periodDate]);

  useEffect(() => {
    setSelectedIdEmployee(parseInt(id));
//     dispatch(
//       getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category, userId:id || params.id })
//     );
  }, [id]);

  const handleChange = ({ target: { value } }) => {
    if (value && value !== -1) {
      setSelectedIdEmployee(parseInt(value));
    } else {
      setSelectedIdEmployee(parseInt(id));
    }
  };

  useEffect(() => {
    // dispatch(RequestLoading());
    setIsFetchLoading(true);

    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("code")) {
            return {
              ...item,
              name: `${item.code} - (${item.description})`,
            };
          } else {
            return item;
          }
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
        setIsFetchLoading(false);
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });

      /* ------------------------- Fetch employes options ------------------------- */

    axios
      .get("/master/employes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("name")) {
            return {
              ...item,
              name: item.name,
            };
          } else {
            return item;
          }
        });

        setOptions((prevOptions) => ({
          ...prevOptions,
          employes: modifiedData,
        }));
      })
      .catch((error) => {
        console.error("Error fetching service categories:", error);
      });
  }, []);


  const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

  useEffect(() => {
    fetchOptions('master/time-off-category', 'category');
  }, []);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
      })
    );
  };

//   const handlePeriodDateChange = (date) => {
//     if (dayjs(date).isValid()) {
//       const formattedDate = dayjs(date).format("YYYY-MM-DD");
//       setPeriodDate(formattedDate);
//       setPeriodDateElm(date);
//     } else {
//       console.error("Invalid start date:", date);
//       // You might want to handle this error case gracefully
//     }
//   };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null })
  );
  const [errors, setErrors] = useState(initialErrors);
  const [detailRow, setDetailRow] = useState({
        "Time Off": {
            "Employee ID": "020120094",
            "Employee Name": "Mohammad Ganda",
            "Time Off Type": "Dinas Dalam Kota",
            "Time Off Category": "Dinas",
            "Day/s": "1 Day",
            "Date": "7 May - 7 May 2024",
            "Reason": "pengecekan laptop bu yuli di harco mangga dua",
            "Request By": "Mohammad Ganda",
            "Attach File": [],
            "status": "Pending"
        },
        "Status": [
            {
                "user": "Juwita Apriliana Silaban",
                "role": "Penanggung Jawab Management Information System    ",
                "timestamp": "7 May 2024, 09:17",
                "status": "Pending",
                "reason": "-"
            },
            {
                "user": "TimeOff Requested",
                "timestamp": "7 May 2024, 09:17"
            }
        ]
    });
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);


  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
  }, [formValues]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];

      // Find the corresponding column object
      const column = columns.find((col) => col.field === field);

      setSortBy(column.fieldSortable || field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])


  function _category(val) {
    setCategory(val);
  }

  const handlePeriodChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY");
      setPeriod(formattedDate);
      setPeriodElm(date);
    } else {
      console.error("Invalid start date:", date);
    }
  };

  const handleClearDate = () => {
    setPeriod(null);
    setPeriodElm(null);
        // Close the DatePicker
    setPickerOpen(false);
  };

  function _status(status) {
    setStatus(status);
  }

  const dispatchAction = useCallback(() => {
    if (
      id ||
      status !== ACTION_TYPE.DEFAULT_STATUS ||
      selectedIdEmployee ||
      period
    ) {
      dispatch(
        getTimeOffRequestDetail({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          period,
          category,
          userId: selectedIdEmployee || params.id,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [
    id,
    status,
    category,
    sortDirection,
    sortBy,
    period,
    selectedIdEmployee,
    token,
    limit,
    search,
    dispatch,
    params.id,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(dispatchAction,10); // Debounce the dispatchAction function
    return () => clearTimeout(timeoutId); // Clear timeout on cleanup
  }, [dispatchAction]);
//   OLD
//   useEffect(() => {
//     if (id || status !== ACTION_TYPE.DEFAULT_STATUS || selectedIdEmployee || period) {
//       dispatch(
//         getTimeOffRequestDetail({
//           token,
//           limit,
//           search,
//           status,
//           sortDirection,
//           sortBy,
//           period,
//           category,
//           userId:selectedIdEmployee || params.id
//         })
//       );
//     } else {
//       setSelectedData([]); //buat disabled button toolbar
//     }
//   }, [id, status, category, sortDirection, sortBy, period, selectedIdEmployee]);


  useEffect(() => {
    if (selectedIdEmployee) {
//       dispatch(
//         getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category, userId:selectedIdEmployee || params.id })
//       );
    }
  }, [selectedIdEmployee]);


  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {

//       dispatch(
//         getTimeOffRequestDetail({
//           token,
//           limit,
//           search,
//           status,
//           sortDirection,
//           sortBy,
//           category,
//           period,
//           category,
//           userId:selectedIdEmployee || params.id
//         })
//       );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [category]);

  useEffect(() => {
//     if (period) {
//       if (dayjs(period).isValid()) {
//         dispatch(
//           getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category, userId:selectedIdEmployee || params.id })
//         );
//       }
//     }
  }, [period]);

  const handleStartDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setStartDate(formattedDate);
      setStartDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleEndDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setEndDate(formattedDate);
      setEndDateElm(date);
    } else {
      console.error("Invalid end date:", date);
      // You might want to handle this error case gracefully
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
//       dispatch(
//         getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category, userId:selectedIdEmployee || params.id })
//       );
    }
  }, [startDate, endDate]);

const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category, userId:selectedIdEmployee || params.id })
      );
    }
  }, [isActive]);

   useEffect(() => {
    if (searchTerm.length >= 2) {
      axios.get(`/employes?search=${searchTerm}&limit=999`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setSuggestedItems(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching suggestions:', error);
        });
    } else {
      setSuggestedItems([]);
    }
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
//     if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      setIsSelectedTerm(false);
      setSearchTerm(value);
//     }
  };
  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];

    for (const key in data) {
      let value;

      if (key === "date") {
        if (dayjs(data[key], "DD/MM/YYYY").isValid()) {
          value = dayjs(data[key], "DD/MM/YYYY").format("YYYY-MM-DD");
        } else {
          value = "";
        }
      } else if (key === "user") {
        value = data[key].fullname;
      } else if (key.includes("_time")) {
        value =
          data[key] !== "-"
            ? dayjs(dayjs().format("YYYY-MM-DD") + " " + data[key])
            : "";
      } else if (key === "time_off_id") {
        value =
          typeof data[key] === "object" && data[key] !== null
            ? data[key].id
            : data[key]; // Extract the 'id' property from the 'time_off_id' object
        /*
        // Katanya dicomment dulu
        if (data[key]) {
          if (
            (data[key] == 5 || data[key] == 4 || data[key] == 67)
          ) {
            // id 5 => Hadir
            // id 4 => WFH
            // id 67 => Terlambat
            setIsPresenceHadir(true);
          } else {
            setIsPresenceHadir(false);
          }
        } else {
          setIsPresenceHadir(false);
        }
        */
      } else {
        value = data[key];
      }

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [key]: value,
        employee_name: data.user ?.fullname || prevFormValues.employee_name, // Update employee_name
      }));
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Attendance");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Attendance");
      const data = rows.filter((row) => selectedData.includes(row.id));
      // setUserDeleted(data.map((row) => row.user?.fullname).join(", "));
      setUserDeleted("attendance");
    } else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle("Detail Time Off");
      setDetailRowFromSelectedData(dataSelected)
    } else if (actionType === ANOTHER_ACTION_TYPE.PREVIEW) {
      setTitle("Attach File");
//       dataSelected['files'][0]['filename'] = 'asdasdasd.jvg'
      setDetailRow(dataSelected);
    } else {
      resetFormValues();
      setTitle("Add New Attendance");
    }
  };

const Detail = () => {
  const statusColors = {
    Draft: { bgColor: warningSurfaceColor, colors: warningMainColor },
    Pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
    Approved: { bgColor: successSurfaceColor, colors: successMainColor },
    Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    // Add more statuses as needed
  };

  const statusInfo = statusColors[detailRow['Time Off']['status']] || {
    bgColor: infoSurfaceColor,
    colors: infoMainColor,
  };

  const renderDetailRows = () => {
    const rows = [];
    const detailData = detailRow['Time Off'];

    for (const key in detailData) {
      if (key !== 'Attach File' && key !== 'status' && detailData.hasOwnProperty(key)) {
        let value = detailData[key];
        if (key === 'Day/s') {
          value = <StatusBadge isinfo={true}>{value}</StatusBadge>;
        }

        rows.push(
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Typography variant="body2">{key}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2">{value}</Typography>
            </Grid>
          </React.Fragment>
        );
      }
    }
    return rows;
  };


    const renderAttachments = () => {
      const attachments = detailRow['Time Off']['Attach File'];
      if (attachments && attachments.length > 0) {
        return (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 800 }}>Attachment</Typography>
            </Grid>
            <Grid item xs={12}>
              {attachments.map((attachment, attachmentIndex) => (
                <AttachmentItem key={attachmentIndex} item={attachment} title="Attach File" />
              ))}
            </Grid>
          </>
        );
      }
      return null;
    };

  return (
    <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' } }}>
      <Grid item xs={12}>
       <Box sx={{ marginBottom: -3, ...justifyContentEnd, ...alignItemsCenter }}>
        <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
          {detailRow['Time Off']["status"]}
        </StatusBadge>

      </Box>
    </Grid>
      {renderDetailRows()}
      {renderAttachments()}
      <Box sx={{ marginLeft: -1}}>
      <DetailTimeline timelineData={detailRow['Status']}/>
      </Box>
    </Grid>
  );
};


  const setDetailRowFromSelectedData = async (dataSelected) => {
    const {id} = dataSelected;
    // debugger;
    setIsLoadedFormValues(false);
    setDetailRow({
      "Time Off": {
          "Employee ID": "Loading...",
          "Employee Name": "Loading...",
          "Time Off Type": "Loading...",
          "Time Off Category": "Loading...",
          "Day/s": "Loading...",
          "Date": "Loading...",
          "Reason": "Loading...",
          "Request By": "Loading...",
          "Attach File": [],
          "status": "Loading..."
      },
      "Status": [
          {
              "user": "Loading...",
              "role": "Loading...",
              "timestamp": "Loading...",
              "status": "Loading...",
              "reason": "Loading..."
          },
          {
              "user": "Loading...",
              "timestamp": "Loading..."
          }
      ]
  });
    try {
        const response = await axios.get(`/web-time-off-requests/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        let data = response.data.data;
        debugger;
        setDetailRow(data);
        setIsLoadedFormValues(true);

      } catch (error) {
        console.error("Error fetching client data:", error);
    } finally {
      setIsLoadedFormValues(true);
    }

  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
      }
    } else {
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  const handleAddRow = () => {
    // dispatch(addTimeOffRequest(token, formValues));
  };

  const handleEditRow = () => {
    const formatTime = (time) =>
      dayjs(time).isValid() ? dayjs(time).format("HH:mm") : null;

    dispatch(
      editTimeOffRequest(
        token,
        Object.assign(formValues, {
          clock_in_time: formatTime(formValues.clock_in_time),
          clock_out_time: formatTime(formValues.clock_out_time),
          schedule_in_time: formatTime(formValues.schedule_in_time),
          schedule_out_time: formatTime(formValues.schedule_out_time),
        }),
        formValues.id
      )
    );
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteTimeOffRequest(token, { data: { ids } }, true));
  };

  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name,value, 'kolom')
    if (name === "time_off_id") {
      // id 5 => Hadir
      // id 4 => WFH
      // id 67 => Terlambat
      // setIsPresenceHadir((value == 5 || value == 4 || value == 67) ? true : false);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);

    if (name === "time_off_id") {
      const value = formValues[name];
      // id 5 => Hadir
      // id 4 => WFH
      // id 67 => Terlambat
//       setIsPresenceHadir((value == 5 || value == 4 || value == 67) ? true : false);
    }
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `${field.label} is required`;
      } else if (validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `${field.label} is required`;
        valid = false;
      }

      if (validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        }
        setSelectedData([]);
      }
    }
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearch(suggestion.fullname);
    setSuggestedItems([]);
    setIsSelectedTerm(true);
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if(isLoadedFormValues) {
      if (
        (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
        (!selectedData.length && actionType === ACTION_TYPE.ADD)
      ) {
        return (
          <Grid container spacing={1}>
            {fields.map((field) => {
              let optionsData;
              if (Array.isArray(options[field.name])) {
                const isRequired = field.required &&
                  field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    name: "Choose Option",
                  };

                if (isRequired) {
                  optionsData = [
                    // field.required &&
                    //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    //     name: "Choose Option",
                    //   },
                    ...options[field.name],
                  ];
                } else {
                  optionsData = [...options[field.name]];
                }
              }

              const fieldOptions = Array.isArray(options[field.name])
                ? optionsData
                : [];

              let fieldComponent = null;

              switch (field.type) {
                case INPUT_TYPE.TEXT:
                case INPUT_TYPE.NUMBER:
                case INPUT_TYPE.PHONE_NUMBER:
                case INPUT_TYPE.POINT:
                case INPUT_TYPE.CURRENCY:
                  fieldComponent = (
                    <TextInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.TEXTAREA:
                  fieldComponent = (
                    <TextArea
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE:
                  fieldComponent = (
                    <SelectOne
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectOneAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_MULTIPLE:
                  fieldComponent = (
                    <SelectMultiple
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectMultipleAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.DATE:
                  fieldComponent = (
                    <DatePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TIME:
                  fieldComponent = (
                    <TimePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                default:
                  fieldComponent = null;
              }

              return (
                <Grid
                  item
                  xs={field.gridWidth || 12}
                  key={field.name}
                  sx={{ paddingTop: "unset !important" }}
                >
                  {fieldComponent}
                </Grid>
              );
            })}
          </Grid>
        );
      } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
        return <ConfirmDelete userDeleted={userDeleted} />;
      } else if (actionType === ANOTHER_ACTION_TYPE.PREVIEW) {
          return <Grid
            item
            xs={12}>
              <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: '100%',
          height:'100%'}}>
              {detailRow['files'].map((attachment, attachmentIndex) => (
                  <AttachmentItem key={attachmentIndex} item={attachment} title={"Attach File"}/>
              ))}
              </Box>
          </Grid>
      } else if (actionType === ANOTHER_ACTION_TYPE.MAP) {
              return <Grid
            item
            xs={12}>
              <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: '100%',
          height:'100%'}}>
            <iframe src={`//maps.google.com/maps?q=${detailRow.lat},${detailRow.long}&z=19&output=embed`}  width="100%" height="400px" frameborder="0" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
        </Box>
        </Grid>
      } else if (actionType === ACTION_TYPE.DETAIL) {
          return <Detail />
      }else {
        return(<Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: '100%',
          height:'100%'
        }}><FacebookCircularProgress /></Box>)
      }
  } else {
        return(<Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'
    }}><FacebookCircularProgress /></Box>)
  }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        {/*<Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => handleCloseFormModal()}
        >
          Yes
        </Button>
        */}
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Close
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, isLoading]);

  useEffect(() => {
//       dispatch(
//         getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category,userId:selectedIdEmployee || params.id })
//       );
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getTimeOffRequestDetail({ token, limit, page, search, status, sortDirection, sortBy,  period, category,userId:selectedIdEmployee || params.id })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category,userId:selectedIdEmployee || params.id })
    );
  }
  function _search() {
//     if (startDate && endDate) {
      dispatch(
        getTimeOffRequestDetail({ token, limit, search, status, sortDirection, sortBy,  period, category,userId:selectedIdEmployee || params.id })
      );
//     }
    setIsSelectedTerm(true);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const ModifyToolbarRight = () => {
    const queryParams = {
      ...(category !== ACTION_TYPE.ALL_CATEGORY ? { time_off_category_id: category } : {}),
      status,
      periode:period,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => value ? `${key}=${encodeURIComponent(value)}` : '')
      .filter(Boolean)
      .join('&');

    const apiUrlWithParams = `/api/web-time-off-requests/${selectedIdEmployee}/export-detail?${queryString}`;

    return (
      <span>
        <Button
          size="medium"
          color="primary"
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
//           onClick={() => {
//             window.open(
//               process.env.REACT_APP_API_URL +
//               `/api/web-time-off-requests/${selectedIdEmployee}/export-detail`,
//               "_blank"
//             );
//           }}
          onClick={() => {
            window.open(
              process.env.REACT_APP_API_URL + apiUrlWithParams,
              "_blank"
            );
          }}
          disabled={!rows.length}
        >
          <img
            src={exportIcon}
            style={{
              ...imgBtnToolbar,
              marginRight: 5,
            }}
          />
          Export
        </Button>
      </span>
    );
  };


  return (
    <>
      {/*

      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={0.7} sx={{ ...alignItemsCenter }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              ...removeButton,
            }}
            onClick={() => navigate(-1)}
          >
            <img src={ArrowBackWhiteIcon} />
          </Button>
        </Grid>

        <Grid item xs={11.3}>
          <Card
            style={{
              boxShadow: "unset",
              borderRadius,
              border: "1px solid #ddd",
            }}
          >
            <CardContent sx={{mt:1}}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
              <Autocomplete
                options={options["employes"] || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  options["employes"].find(
                    (option) => option.id === selectedIdEmployee
                  ) || -1
                }
                onChange={(event, newValue) =>
                  handleChange({
                    target: {
                      value: newValue ?.id || -1,
                    },
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
                sx={{ ...formGroup, mt:0 }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  SelectProps={{
                    IconComponent: ExpandMoreIcon,
                  }}
                  sx={{
                    borderRadius,
                  }}
                  value={category}
                  onChange={(e) => _category(e.target.value)}
                >
                  <MenuItem value={ACTION_TYPE.ALL_CATEGORY} id="all-service">
                    All Category
                  </MenuItem>
                  {options["category"] &&
                    options["category"].map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                <DatePicker
                  open={pickerOpen}
                  onOpen={() => setPickerOpen(true)}
                  onClose={() => setPickerOpen(false)}
                  selectedDate={periodElm}
                  onChange={handlePeriodChange}
                  components={{
                    OpenPickerButton: CustomOpenPickerButton,
                    Toolbar: (props) => (
                      <Toolbar {...props}>
                        <Button onClick={handleClearDate} startIcon={<ClearIcon />}>
                          Clear
                        </Button>
                      </Toolbar>
                    ),
                  }}
  //                 format="DD/MM/YYYY"
                  format="YYYY"
                  views={["year"]}
                  value={periodElm}
                  sx={{
                    ...inputDate,
                  }}
                />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  SelectProps={{
                    IconComponent: ExpandMoreIcon,
                  }}
                  sx={{
                    borderRadius,
                  }}
                  value={status}
                  onChange={(e) => _status(e.target.value)}
                >
                  <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Canceled">Canceled</MenuItem>
                </TextField>
              </Grid>
            </Grid>
        </CardContent>
      </Card>
        </Grid>
      </Grid>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Attendance"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handleFormModal={handleFormModal}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            dateChoosed={periodDate}
            search={search}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            showAddToolbar={false}
            showEditToolbar={false}
            showDeleteToolbar={false}
            componentModifyToolbarRight={ModifyToolbarRight}
          />
        </CardContent>
      </Card>

      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default DetailTimeOffRequest;
