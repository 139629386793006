import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TreeView, TreeItem } from "@mui/lab";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  Divider,
  FormGroup,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  modalHeader,
  modalActionButton,
  form,
  modal,
  FacebookCircularProgress,
  btnTableToolbar,
  imgBtnToolbar,
} from "../../util/style";
import exportIcon from "./../../assets/export.png";
import axios from "../../core/axios_config";
import Form, {
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../components/Form";
import {
  addUserAccess,
  deleteUserAccess,
  editUserAccess,
  getUserAccess,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import LeftDrawer from "../../components/LeftDrawer";
import { ErrorAlert } from "../../components/Alert";

const fields = [
  {
    name: "id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Users",
    defaultValue: "",
    apiEndpoint: "/master/users",
    params: {
      dont_have_access_only: true,
    },
    required: true,
    errorMessage: "Please select a user name first",
    // additionalProps: {
    //   disabled: true,
    // },
  },
  {
    name: "email",
    type: INPUT_TYPE.TEXT,
    label: "Email",
    defaultValue: "",
    // required: true,
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "position",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Position",
    defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    apiEndpoint: "/master/positions?only_have_user=true",
    // required: true,
    additionalProps: {
      disabled: true,
    },
  },
];

const useStyles = defaultStylePage;

const menuDataZ = [
  // {
  //   id: 1,
  //   name: "System Configuration",
  //   parent_id: null,
  //   children: [
  //     {
  //       id: 2,
  //       name: "Director",
  //       parent_id: 1,
  //       has_access: false,
  //       permission: [
  //         { id: 11, name: "director_create", has_access: false },
  //         { id: 12, name: "director_read", has_access: false },
  //         { id: 13, name: "director_update", has_access: false },
  //         { id: 14, name: "director_delete", has_access: false },
  //         { id: 15, name: "director_limited", has_access: false },
  //       ],
  //       children: [],
  //     },
  //     {
  //       id: 3,
  //       name: "Status",
  //       parent_id: 1,
  //       has_access: false,
  //       permission: [
  //         { id: 31, name: "status_create", has_access: false },
  //         { id: 32, name: "status_read", has_access: false },
  //         { id: 33, name: "status_update", has_access: false },
  //         { id: 34, name: "status_delete", has_access: false },
  //         { id: 35, name: "status_limited", has_access: false },
  //       ],
  //       children: [],
  //     },
  //     // Add more items here...
  //   ],
  // },
  // {
  //   id: 10,
  //   name: "Users Management",
  //   parent_id: null,
  //   children: [
  //     {
  //       id: 11,
  //       name: "Users",
  //       parent_id: 10,
  //       has_access: false,
  //       permission: [
  //         { id: 38, name: "users_create", has_access: false },
  //         { id: 39, name: "users_read", has_access: false },
  //         { id: 40, name: "users_update", has_access: false },
  //         { id: 41, name: "users_delete", has_access: false },
  //         { id: 42, name: "users_limited", has_access: false },
  //       ],
  //       children: [],
  //     },
  //     {
  //       id: 12,
  //       name: "Users Access",
  //       parent_id: 10,
  //       has_access: false,
  //       permission: [
  //         { id: 43, name: "users_access_create", has_access: false },
  //         { id: 44, name: "users_access_read", has_access: false },
  //         { id: 45, name: "users_access_update", has_access: false },
  //         { id: 46, name: "users_access_delete", has_access: false },
  //         { id: 47, name: "users_access_limited", has_access: false },
  //       ],
  //       children: [],
  //     },
  //     // Add more items here...
  //   ],
  // },
  {
    id: 1,
    name: "System Configuration",
    parent_id: null,
    link: "/system-configuration",
    position: null,
    status: "active",
    created_by: null,
    is_checked: false,
    children: [
      {
        id: 2,
        name: "Menu Management",
        parent_id: 1,
        link: "/system-configuration/menu-management",
        position: 1,
        status: "active",
        created_by: null,
        has_access: true,
        is_checked: true,
        permission: [
          {
            id: 1,
            name: "menu_management_create",
            menu_id: 2,
            has_access: false,
            is_checked: false,
          },
          {
            id: 2,
            name: "menu_management_read",
            menu_id: 2,
            has_access: true,
            is_checked: true,
          },
          {
            id: 3,
            name: "menu_management_update",
            menu_id: 2,
            has_access: false,
            is_checked: false,
          },
          {
            id: 4,
            name: "menu_management_delete",
            menu_id: 2,
            has_access: false,
            is_checked: false,
          },
          {
            id: 5,
            name: "menu_management_limited",
            menu_id: 2,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 3,
        name: "Power",
        parent_id: 1,
        link: "/system-configuration/power",
        position: 2,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 6,
            name: "power_create",
            menu_id: 3,
            has_access: false,
            is_checked: false,
          },
          {
            id: 7,
            name: "power_read",
            menu_id: 3,
            has_access: false,
            is_checked: false,
          },
          {
            id: 8,
            name: "power_update",
            menu_id: 3,
            has_access: false,
            is_checked: false,
          },
          {
            id: 9,
            name: "power_delete",
            menu_id: 3,
            has_access: false,
            is_checked: false,
          },
          {
            id: 10,
            name: "power_limited",
            menu_id: 3,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 4,
        name: "Director",
        parent_id: 1,
        link: "/system-configuration/director",
        position: 3,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 11,
            name: "director_create",
            menu_id: 4,
            has_access: false,
            is_checked: false,
          },
          {
            id: 12,
            name: "director_read",
            menu_id: 4,
            has_access: false,
            is_checked: false,
          },
          {
            id: 13,
            name: "director_update",
            menu_id: 4,
            has_access: false,
            is_checked: false,
          },
          {
            id: 14,
            name: "director_delete",
            menu_id: 4,
            has_access: false,
            is_checked: false,
          },
          {
            id: 15,
            name: "director_limited",
            menu_id: 4,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 5,
        name: "Stages",
        parent_id: 1,
        link: "/system-configuration/stages",
        position: 4,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 16,
            name: "stages_create",
            menu_id: 5,
            has_access: false,
            is_checked: false,
          },
          {
            id: 17,
            name: "stages_read",
            menu_id: 5,
            has_access: false,
            is_checked: false,
          },
          {
            id: 18,
            name: "stages_update",
            menu_id: 5,
            has_access: false,
            is_checked: false,
          },
          {
            id: 19,
            name: "stages_delete",
            menu_id: 5,
            has_access: false,
            is_checked: false,
          },
          {
            id: 20,
            name: "stages_limited",
            menu_id: 5,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 6,
        name: "Services",
        parent_id: 1,
        link: "/system-configuration/services",
        position: 5,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 21,
            name: "services_create",
            menu_id: 6,
            has_access: false,
            is_checked: false,
          },
          {
            id: 22,
            name: "services_read",
            menu_id: 6,
            has_access: false,
            is_checked: false,
          },
          {
            id: 23,
            name: "services_update",
            menu_id: 6,
            has_access: false,
            is_checked: false,
          },
          {
            id: 24,
            name: "services_delete",
            menu_id: 6,
            has_access: false,
            is_checked: false,
          },
          {
            id: 25,
            name: "services_limited",
            menu_id: 6,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 7,
        name: "Attachment File",
        parent_id: 1,
        link: "/system-configuration/attachment-file",
        position: 6,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 26,
            name: "attachment_file_create",
            menu_id: 7,
            has_access: false,
            is_checked: false,
          },
          {
            id: 27,
            name: "attachment_file_read",
            menu_id: 7,
            has_access: false,
            is_checked: false,
          },
          {
            id: 28,
            name: "attachment_file_update",
            menu_id: 7,
            has_access: false,
            is_checked: false,
          },
          {
            id: 29,
            name: "attachment_file_delete",
            menu_id: 7,
            has_access: false,
            is_checked: false,
          },
          {
            id: 30,
            name: "attachment_file_limited",
            menu_id: 7,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 8,
        name: "Status",
        parent_id: 1,
        link: "/system-configuration/status",
        position: 7,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 31,
            name: "status_create",
            menu_id: 8,
            has_access: false,
            is_checked: false,
          },
          {
            id: 32,
            name: "status_read",
            menu_id: 8,
            has_access: false,
            is_checked: false,
          },
          {
            id: 33,
            name: "status_update",
            menu_id: 8,
            has_access: false,
            is_checked: false,
          },
          {
            id: 34,
            name: "status_delete",
            menu_id: 8,
            has_access: false,
            is_checked: false,
          },
          {
            id: 35,
            name: "status_limited",
            menu_id: 8,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 9,
        name: "User Logs",
        parent_id: 1,
        link: "/system-configuration/user-logs",
        position: 8,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 36,
            name: "user_logs_read",
            menu_id: 9,
            has_access: false,
            is_checked: false,
          },
          {
            id: 37,
            name: "user_logs_limited",
            menu_id: 9,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
    ],
    permission: [],
  },
  {
    id: 10,
    name: "Users Management",
    parent_id: null,
    link: "/user-management",
    position: null,
    status: "active",
    created_by: null,
    is_checked: false,
    children: [
      {
        id: 11,
        name: "Users",
        parent_id: 10,
        link: "/user-management/users",
        position: 1,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 38,
            name: "users_create",
            menu_id: 11,
            has_access: false,
            is_checked: false,
          },
          {
            id: 39,
            name: "users_read",
            menu_id: 11,
            has_access: false,
            is_checked: false,
          },
          {
            id: 40,
            name: "users_update",
            menu_id: 11,
            has_access: false,
            is_checked: false,
          },
          {
            id: 41,
            name: "users_delete",
            menu_id: 11,
            has_access: false,
            is_checked: false,
          },
          {
            id: 42,
            name: "users_limited",
            menu_id: 11,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
      {
        id: 12,
        name: "Users Access",
        parent_id: 10,
        link: "/user-management/users-access",
        position: 2,
        status: "active",
        created_by: null,
        has_access: false,
        is_checked: false,
        permission: [
          {
            id: 43,
            name: "users_access_create",
            menu_id: 12,
            has_access: false,
            is_checked: false,
          },
          {
            id: 44,
            name: "users_access_read",
            menu_id: 12,
            has_access: false,
            is_checked: false,
          },
          {
            id: 45,
            name: "users_access_update",
            menu_id: 12,
            has_access: false,
            is_checked: false,
          },
          {
            id: 46,
            name: "users_access_delete",
            menu_id: 12,
            has_access: false,
            is_checked: false,
          },
          {
            id: 47,
            name: "users_access_limited",
            menu_id: 12,
            has_access: false,
            is_checked: false,
          },
        ],
        children: [],
      },
    ],
    permission: [],
  },
];

const MenuPermission = ({ data, actionType, userId, token }) => {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    const fetchMenuData = async (URL) => {
      try {
        const response = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const apiMenuData = response.data.data;

        // // Update menuData structure using the fetched API data
        // const updatedMenuData = menuData.map((menu) => {
        //   const matchingApiMenu = apiMenuData.find(
        //     (apiMenu) => apiMenu.id === menu.id
        //   );
        //   if (matchingApiMenu) {
        //     return {
        //       ...menu,
        //       name: matchingApiMenu.name,
        //       children: matchingApiMenu.children, // Update children based on the API response
        //       // You may need to update permission and other properties similarly
        //     };
        //   }
        //   return menu;
        // });

        setMenuData([...apiMenuData]);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    if (actionType === ACTION_TYPE.EDITED && userId) {
      fetchMenuData(
        `master/menus?with_children=true&user_id=${userId}&status=active`
      );
    } else if (actionType === ACTION_TYPE.ADD) {
      if (!menuData.length) {
        fetchMenuData(
          `master/menus?with_children=true&is_new=true&status=active`
        );
      }
    }
  }, [actionType, userId]);

  // OLD
  /*const initializeCheckedState = (data, isParentChecked = false) =>
    data.map((item) => ({
      ...item,
      is_checked: item.is_checked || isParentChecked,
      children: item.children
        ? initializeCheckedState(item.children, item.is_checked)
        : [],
      permission: item.permission
        ? item.permission.map((perm) => ({
            ...perm,
            has_access: perm.has_access || item.is_checked, // Initialize has_access based on the parent node's is_checked
          }))
        : [],
    }));*/

  // NEW
  const initializeCheckedState = (data) =>
    data.map((item) => ({
      ...item,
      children: item.children ? initializeCheckedState(item.children) : [],
      permission: item.permission
        ? item.permission.map((perm) => ({
          ...perm,
          has_access: perm.is_checked || false, // Initialize based on perm.is_checked
        }))
        : [],
    }));

  const [menuState, setMenuState] = useState(() =>
    initializeCheckedState(menuData)
  );

  useEffect(() => {
    setMenuState(() => initializeCheckedState(menuData));
  }, [menuData]);

  useEffect(() => {
    console.log(
      " ------------------------------ checkedItems ------------------------------",
      actionType
    );
    console.log(menuState);
    data(menuState);
  }, [menuState]);

  const handlePermissionCheckboxChange = (event, permissionId, nodeId) => {
    const isChecked = event.target.checked;

    const updatePermissionState = (data, id, permId, isChecked) => {
      let allPermissionChecked = true; // Track if all permissions are checked
      let anyPermissionChecked = false; // Track if at least one permission is checked

      const newData = data.map((item) => {
        if (item.id === id) {
          const updatedPermission = item.permission.map((perm) => {
            if (perm.id === permId) {
              return {
                ...perm,
                has_access: isChecked,
              };
            }
            return perm;
          });

          allPermissionChecked = updatedPermission.every(
            (perm) => perm.has_access
          );
          anyPermissionChecked = updatedPermission.some(
            (perm) => perm.has_access
          );

          return {
            ...item,
            permission: updatedPermission,
            is_checked: allPermissionChecked || anyPermissionChecked, // If all permissions are checked or at least one permission is checked, check the parent node as well
          };
        }
        if (item.children) {
          const updatedChildren = updatePermissionState(
            item.children,
            id,
            permId,
            isChecked
          );
          allPermissionChecked = updatedChildren.every(
            (child) => child.is_checked
          );
          anyPermissionChecked = updatedChildren.some(
            (child) => child.is_checked
          );
          return {
            ...item,
            children: updatedChildren,
            // is_checked: allPermissionChecked || anyPermissionChecked,
          };
        }
        return item;
      });

      return newData;
    };

    const updatedMenuData = updatePermissionState(
      [...menuState],
      nodeId,
      permissionId,
      isChecked
    );
    setMenuState(updatedMenuData);
  };

  const handleCheckboxChange = (event, itemId) => {
    const isChecked = event.target.checked;

    const updateCheckedState = (data, id, isChecked) => {
      return data.map((item) => {
        if (item.id === id) {
          const updatedChildren = item.children.map((child) => ({
            ...child,
            is_checked: isChecked,
            permission: child.permission.map((perm) => ({
              ...perm,
              has_access: isChecked,
            })),
          }));
          return {
            ...item,
            is_checked: isChecked,
            children: updatedChildren,
            permission: item.permission.map((perm) => ({
              ...perm,
              has_access: isChecked,
            })), // Update the has_access property of permissions when main node is checked/unchecked
          };
        }
        if (item.children) {
          const updatedChildren = updateCheckedState(
            item.children,
            id,
            isChecked
          );
          return {
            ...item,
            is_checked: item.is_checked,
            children: updatedChildren,
          };
        }
        return item;
      });
    };

    const updatedMenuData = updateCheckedState(
      [...menuState],
      itemId,
      isChecked
    );
    setMenuState(updatedMenuData);
  };

  const updatePermissionState = (data, id, isChecked) => {
    return data.map((item) => {
      if (item.id === id) {
        // const updatedChildren = item.children.map((child) => ({
        //   ...child,
        //   is_checked: isChecked,
        //   permission: child.permission.map((perm) => ({
        //     ...perm,
        //     has_access: isChecked,
        //   })),
        // }));
        return {
          ...item,
          permission: item.permission.map((perm) => ({
            ...perm,
            has_access: isChecked,
          })),
        };
        // return {
        //   ...item,
        //   is_checked: isChecked,
        //   children: updatedChildren,
        //   permission: item.permission.map((perm) => ({
        //     ...perm,
        //     has_access: isChecked,
        //   })),
        // };
      }
      if (item.children) {
        const updatedChildren = updatePermissionState(
          item.children,
          id,
          isChecked
        );
        return {
          ...item,
          children: updatedChildren,
        };
      }
      return item;
    });
  };

  const shouldExpandNode = (node) => {
    const hasSubPermissions = node.permission.some((perm) => perm.has_access);
    const hasSubNodesWithAccess = node.children.some((child) =>
      shouldExpandNode(child)
    );
    return hasSubPermissions || hasSubNodesWithAccess;
  };

  // const shouldExpandNode = (node) => {
  //   const permissionNames = node.permission.map((perm) => perm.name);
  //   console.log("permissionNames:", permissionNames);
  //   return (
  //     permissionNames.includes("create") ||
  //     permissionNames.includes("read") ||
  //     permissionNames.includes("update")
  //   );
  // };

  const renderTree = (data) =>
    data.map((item) => (
      <TreeItem
        key={item.id}
        nodeId={String(item.id)}
        label={renderNode(item)}
        defaultExpanded={shouldExpandNode(item)}
      >
        {Array.isArray(item.children) ? renderTree(item.children) : null}
        <TreeItem
          nodeId={`${item.id}-permissions`}
          label={renderPermission(item.permission, item.id)}
          defaultExpanded={shouldExpandNode(item)}
        />
      </TreeItem>
    ));

  const renderNode = (item) => (
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          checked={item.is_checked || false}
          indeterminate={item.indeterminate}
          onChange={(e) => handleCheckboxChange(e, item.id)}
          onClick={(e) => e.stopPropagation()} // Prevent propagation of the click event
        />
      }
      label={item.name}
    />
  );

  const renderPermissionLabel = (permissionName) => {
    if (permissionName.includes("_create")) return "Create";
    if (permissionName.includes("_update")) return "Update";
    if (permissionName.includes("_delete")) return "Delete";
    if (permissionName.includes("_read")) return "Read";
    if (permissionName.includes("_limited"))
      return "Limit access to view data created by other users";
    return permissionName;
  };

  const shouldStretchLabels = (permission) => {
    // Check if any of the permission names include "limited"
    const hasLimited = permission.some((perm) =>
      perm.name.includes("_limited")
    );
    // If any permission name includes "limited," return a class that doesn't stretch labels
    return hasLimited
      ? "permission-form-group"
      : "permission-form-group stretch-labels";
  };

  const renderPermission = (permission, nodeId) => (
    <FormGroup
      className={`${shouldStretchLabels(permission)} inline-permission`}
    >
      {permission.map((perm) => (
        <FormControlLabel
          key={perm.id}
          control={
            <Checkbox
              disableRipple
              checked={perm.has_access || false}
              onChange={(e) =>
                handlePermissionCheckboxChange(e, perm.id, nodeId)
              } // Pass both item.id and perm.id to handlePermissionCheckboxChange
            />
          }
          label={renderPermissionLabel(perm.name)}
        />
      ))}
    </FormGroup>
  );

  return (
    <div>
      {!menuState.length && <FacebookCircularProgress />}
      {actionType === ACTION_TYPE.EDITED && menuState.length ? (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={menuState.map((item) => String(item.id))}
        >
          {renderTree(menuState)}
        </TreeView>
      ) : null}
      {actionType === ACTION_TYPE.ADD && menuState.length ? (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(menuState)}
        </TreeView>
      ) : null}
    </div>
  );
  // return (
  //   <TreeView
  //     defaultCollapseIcon={<ExpandMoreIcon />}
  //     defaultExpandIcon={<ChevronRightIcon />}
  //   >
  //     {!menuState.length ? <FacebookCircularProgress /> : renderTree(menuState)}
  //   </TreeView>

  // );
};

const UsersAccess = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();

  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    console.log(currentPath, userAccess);
  }, [userAccess]);
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const columns = [
    {
      field: "fullname",
      headerName: "Name",
      flex: 1,
      // width: 180,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      // width: 220,
      disableColumnMenu: true,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
      // width: 180,
      disableColumnMenu: true,
      sortable: true,
      valueGetter: (params) => {
        return params.row.position ? params.row.position ?.name : "-";
      },
    },
    // {
    //   field: "approval",
    //   headerName: "Approval",
    //   width: 180,
    //   disableColumnMenu: true,
    //   sortable: true,
    //   valueGetter: (params) => {
    //     return params.row.approval ? params.row.approval?.fullname : "-";
    //   },
    // },
    {
      field: "access",
      headerName: "Access",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      flex: 1,
      // width: 280,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        if (params.value ?.length) {
          return (
            <Box
              sx={{
                display: "inline-block",
                gap: "0.4rem",
                padding: ".5rem 0",
              }}
            >
              {params.value.map((v, index) => (
                <StatusBadge
                  key={index}
                  isinfo={true}
                  style={{ margin: ".1rem .25rem" }}
                >
                  {v}
                </StatusBadge>
              ))}
            </Box>
          );
        } else {
          return null; // Return null if params.value is empty or undefined
        }
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   // description: "This column has a value getter and is not sortable.",
    //   sortable: true,
    //   width: 120,
    //   disableColumnMenu: true,
    //   valueGetter: (params) => params.value, // Return the status value as a string
    //   renderCell: (params) => (
    //     <StatusBadge status={params.value}> {params.value} </StatusBadge>
    //   ),
    // },
  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New User Access");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [menuData, setMenuData] = useState([]);
  const [position, setPosition] = useState(ACTION_TYPE.DEFAULT_POSITION);
  const dispatch = useDispatch();

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.usersaccess);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name, params) => {
      try {
        const requestOptions = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        if (apiEndpoint === "/master/users") {
          if (actionType === ACTION_TYPE.ADD && params) {
            requestOptions.params = { ...params };
          }

          // Now you can use the requestOptions in your API call
        }

        const response = await axios.get(apiEndpoint, requestOptions);

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint, params } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name, params);
      }
    });
  }, [actionType]);

  useEffect(() => {
    const aa = menuData;
    console.log(aa, "aaa");
  }, [menuData]);

  useEffect(() => {
    if (position !== ACTION_TYPE.DEFAULT_POSITION) {
      dispatch(
        getUserAccess({ token, limit, search, position, sortDirection, sortBy })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [position]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        status: false,
      })
    );
  };

  const fetchUserData = async (id) => {
    try {
      const response = await axios.get(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), {
      id: null,
      permissions: [],
    })
  );
  const [errors, setErrors] = useState(
    Object.assign({}, initialErrors, { permissions: null })
  );

  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
    if (formValues["permissions"] ?.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        permissions: null,
      }));
    }
  }, [formValues]);

  useEffect(() => {
    console.log(errors, "errors,");
    // console.log(initialFormValues(), "asdad");
  }, [errors]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getUserAccess({ token, limit, search, position, sortDirection, sortBy })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    for (const key in data) {
      console.log(key, "key", data[key]);
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value ?.id : value,
        }));
      }
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name, value");

    if (name === "id") {
      // Update email and position based on user and id
      const userData = await fetchUserData(value);

      if (userData) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
          email: userData.email,
          position: userData.position ?.id || -1,
        }));
      }
    } else {
      // For other fields, just update the formValues as usual
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
      } else if (validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    [
      ...fields,
      {
        name: "permissions",
        label: "Access",
        defaultValue: [],
        required: true,
        errorMessage: "Please set access first",
      },
    ].forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;
      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE ||
          (Array.isArray(formValues[name]) && formValues[name].length === 0))
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
        valid = false;
      }

      if (validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        }
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit User Access");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Users Access");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `users access '${data.map((row) => row.fullname).join(", ")}'`
      );
    } else {
      resetFormValues();
      setTitle("Add New User Access");
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  const handleAddRow = () => {
    dispatch(addUserAccess(token, formValues));
  };

  const handleEditRow = () => {
    dispatch(editUserAccess(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteUserAccess(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  const handleGetPermissionWithAccess = (val) => {
    const _getPerm = (items) => {
      const permissionsWithAccess = [];

      const traverse = (item) => {
        if (item.permission) {
          const filteredPermissions = item.permission.filter(
            (permission) => permission.has_access
          );
          permissionsWithAccess.push(
            ...filteredPermissions.map((permission) => permission.name)
          );
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach(traverse);
        }
      };

      items.forEach(traverse);

      return permissionsWithAccess;
    };

    const permissionNamesWithAccess = _getPerm(val);

    setFormValues((prevValues) => ({
      ...prevValues,
      permissions: permissionNamesWithAccess,
    }));
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <>
          {fields.map((field) => {
            // add disabled on users select
            if (actionType === ACTION_TYPE.EDITED && field.name === "id")
              field.additionalProps = { disabled: true };
            if (actionType === ACTION_TYPE.ADD && field.name === "id")
              field.additionalProps = { disabled: false };

            const fieldOptions = Array.isArray(options[field.name])
              ? [
                field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  // name: `Select ${field.label}`,
                  name: `Choose Option`,
                },
                ...options[field.name],
              ]
              : [];

            switch (field.type) {
              case INPUT_TYPE.TEXT:
                return (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.PASSWORD:
                return (
                  <PasswordInputWithValidation
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.SELECT_ONE:
                return (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                return (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE:
                return (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                return (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              default:
                return null;
            }
          })}
          <Divider sx={{ margin: "2rem 0 1rem" }} />
          {errors.permissions && <ErrorAlert title={errors.permissions} />}
          <MenuPermission
            data={handleGetPermissionWithAccess}
            actionType={actionType}
            userId={formValues.id}
            token={token}
          />
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...formGroup,
              padding: "0 1rem",
            }}
          >
            <FormControlLabel
              control={
                <StatusSwitch
                  checked={formValues.status}
                  name="status"
                  onChange={handleSwitchChange}
                />
              }
              label={
                <Typography
                  variant="h7"
                  sx={{ marginLeft: 1, fontWeight: "500" }}
                >
                  {formValues.status ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </Box> */}
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, position, isLoading, "query");
  }, [search, position, isLoading]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    if (position !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getUserAccess({
          token,
          page,
          limit,
          search,
          position,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getUserAccess({
        token,
        page,
        limit,
        search,
        position,
        sortDirection,
        sortBy,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getUserAccess({
        token,
        limit,
        search,
        position,
        sortDirection,
        sortBy,
      })
    );
  }
  function _search() {
    if (position !== ACTION_TYPE.DEFAULT_POSITION) {
      dispatch(
        getUserAccess({ token, limit, search, position, sortDirection, sortBy })
      );
    }
  }
  function _status(status) {
    setStatus(status);
  }

  function _position(val) {
    setPosition(val);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const ModifyToolbarRight = () => {
    return (
      <span>
        <Button
          size="medium"
          color="primary"
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            window.open(
              process.env.REACT_APP_API_URL + `/api/users/export`,
              "_blank"
            );
          }}
        >
          <img
            src={exportIcon}
            style={{
              ...imgBtnToolbar,
              marginRight: 5,
            }}
          />
          Export
        </Button>
      </span>
    );
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>

            <Grid item xs={2}>
              {/* <Autocomplete
                options={options["position"] || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  options["position"].find(
                    (option) => option.id === position
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    _position(newValue.id);
                  } else {
                    // Handle the case when no option is selected (if needed)
                    _position("");
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
                sx={{ ...formGroup }}
              /> */}

              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={position}
                onChange={(e) => _position(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_POSITION}>
                  Select Position
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_POSITION}>
                  All Position
                </MenuItem>
                {options["position"] &&
                  options["position"].map((position) => (
                    <MenuItem key={position.id} value={position.id}>
                      {position.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"User Access"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            positionChoosed={position}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            userAccess={userAccess}
            componentModifyToolbarRight={ModifyToolbarRight}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
          <Modal open={formModal} onClose={() => handleCloseFormModal()}>
            <Box>
              <Form
                title={title}
                onCloseModal={() => handleCloseFormModal()}
                elementForm={renderFormContent}
                elementActionButton={renderActionButtons}
                onSubmit={handleSubmit}
              />
            </Box>
          </Modal>
        )}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default UsersAccess;
