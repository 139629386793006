"use strict";
//@ts-check

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../../util/style";
import { pxToMm } from "../../../../../util/function";
import boderImg from "./../../../../../assets/border.png";
import logo from "./../../../../../logo.png";
import Signature from "../../../payment-request/componens/Signature";
import { useStyles } from "../../hooks/useStyle";
import { ValidateValueRender } from "../../../proforma-invoice/validation/validateValue";
import ZoomOutIcon from "./../../../../../assets/zoom-out.png";
import ZoomInIcon from "./../../../../../assets/zoom-in.png";
import { manipulatePaylaodDetail } from "../../parts/imutable_state";
export default function DocumentPreview({
  documentPreviewForPrintRef,
  height,
}) {
  const { data, message } = useSelector((state) => state.financeInvoiceDetail);
  const [fontSize, setFontSize] = useState(12); // Initial font size
  const [percentage, setPercentage] = useState(100); // Initial percentage text
  const documentPreviewRef = useRef(null);
  const [detailDocument, setDetailDocument] = useState({
    product: {
      status: "Pending",
      no_proforma: "12782",
      create_at: new Date().toLocaleDateString("id-ID", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      }),
      no_reference: "123321",
      service_category: "Patent",
      created_by: "Json miranti",
      currency: "Rp",
      sub_service: [
        { name: "Patent Pecahan", id: 2, price: 2_000_000 },
        { name: "Patent Sederhana", id: 4, price: 4_000_000 },
      ],
      price: 12323121,
      discount: 2_000_000,
      vat: 50_000,
      total: 2536273,
      description:
        "Lorem ipsum dolor sit amet consectetur. Auctor dolor pellentesque viverra enim posuere elementum iaculis a",
      remark: "Microsoft Office",
    },
    bank: {
      acount_name: "eric",
      acount_address: "Jl. Gatot",
      acount_no: 1232321,
      bank_name: "Bank XYZ",
      bank_address: "Jalan Sudirman",
      payment_receive: 99999999,
      payment_conversion_receive: 990099,
    },
    client: {
      bill_to: "Andre",
      attn: "Avon product",
      bill_address: "Jl. Tomang No. 123 Jakarta 456",
    },
    Accounting: "Markus jhonson",
  });

  useEffect(() => {
    if (data) {
      const manipulated = manipulatePaylaodDetail(data);
      setDetailDocument(manipulated);
    }
  }, [data]);
  const validation = new ValidateValueRender(detailDocument);

  useEffect(() => {
    // Apply the zoom effect to specific elements when fontSize changes
    if (documentPreviewRef.current) {
      const applyZoomToChildren = (element) => {
        if (element.childNodes.length > 0) {
          for (const childNode of element.childNodes) {
            applyZoomToChildren(childNode);
          }
        }
        if (element.style) {
          const tagName = element.tagName.toLowerCase();
          const className = element.className.toLowerCase();
          // if (tagName === 'h1' || tagName === 'h2' || tagName === 'h3' || tagName === 'h4' || tagName === 'h5' || tagName === 'h6') {
          //   // Apply +1px zoom to h1, h2, h3, h4, h5, h6
          //   element.style.fontSize = `${fontSize + 1}px`;
          // } else {
          //   element.style.fontSize = `${fontSize}px`;
          // }

          const hasNotEffectClass =
            className && className.includes("not-effect");

          if (!hasNotEffectClass) {
            // Apply +1px zoom to h1, h2, h3, h4, h5, h6 if it doesn't have the 'not-effect' class
            element.style.fontSize = `${fontSize + 1}px`;
          } else {
            // Apply normal zoom if it has the 'not-effect' class
            element.style.fontSize = `${element.style.fontSize}px`;
          }
        }
      };
      applyZoomToChildren(documentPreviewRef.current);
    }
  }, [fontSize]);

  //* ====================================================== *//
  //*                          HANDLER                       *//
  //* ====================================================== *//

  const handleZoomIn = () => {
    setFontSize((prevSize) => prevSize + 1);
    setPercentage((prevPercentage) => prevPercentage + 10);
  };

  const handleZoomOut = () => {
    setFontSize((prevSize) => Math.max(prevSize - 1, 1));
    setPercentage((prevPercentage) => Math.max(prevPercentage - 10, 10));
  };

  //* ====================================================== *//
  //*                         COMPONENT                      *//
  //* ====================================================== *//

  const renderGridItemsFromObject = () => {
    /**
     * key === key on detail document
     * value === display in UI
     */
    const alias = {
      "No. Proforma Invoice": "No",
      "Created At": "Create At",
      "No Reference": "No. Reference",
      Remark: "Remark",
    };

    return Object.entries(alias).map(([key, aliasName]) => (
      <React.Fragment key={key}>
        <Grid item xs={4}>
          <Typography variant="body1">{aliasName}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
            : &nbsp;
            {key === "Remark"
              ? detailDocument.client[key]
              : detailDocument.product[key]}
          </Typography>
        </Grid>
      </React.Fragment>
    ));
  };

  const RenderClientInformation = () => {
    const listRender = {
      "Bill To": "Bill To",
      Attn: "Attn",
      "Bill Address": "Bill Address",
    };

    return Object.entries(listRender).map(([key, aliasName]) => (
      <>
        <Box paddingY=".1rem">
          <Typography sx={{ color: neutral70, fontWeight: "600" }}>
            {aliasName}
          </Typography>
        </Box>
        <Box paddingY=".1rem">
          <Typography fontWeight={"500"}>
            {validation.renderValue(detailDocument.client[key], key, false)}
          </Typography>
        </Box>
      </>
    ));
  };

  const RenderPriceInformation = () => {
    const pricesObj = {};
    Object.keys(detailDocument.product).forEach((key) => {
      if (key.includes("Price") || key === "Discount" || key == "VAT") {
        pricesObj[key] = detailDocument.product[key];
      }
    });

    return (
      <>
        <Typography variant="subtitle1">Price</Typography>
        <ul style={{ marginBlock: ".1rem", paddingLeft: "1.6rem" }}>
          {Object.entries(pricesObj).map(([key, value], index) => {
            return (
              <li key={index}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">{key}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" textAlign={"right"}>
                      {/* {validation.renderValue(vat, "vat", false, null, {
                        fractionDigits: 2,
                      })} */}
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          })}
          <li style={{ listStyleType: "none" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"800"}
                >
                  Total
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"800"}
                >
                  {/* {validation.renderValue(totalPrice, "total", false, null, {
                    fractionDigits: 2,
                  })} */}
                  {detailDocument.product["Total"]}
                </Typography>
              </Grid>
            </Grid>
          </li>
        </ul>
      </>
    );
  };

  const RenderBankTransfer = () => {
    // const listRender = {
    //   acount_name: "Account Name",
    //   acount_address: "Account address",
    //   bank_name: "Name of Bank",
    //   acount_no: "Account No.",
    //   bank_address: "Bank Address",
    //   payment_receive: "Payment Received",
    //   payment_conversion_receive: "Payment Conversion Received",
    // };
    return (
      <>
        <Typography
          variant="subtitle1"
          sx={{ color: neutral70, fontWeight: "600", paddingY: ".5rem" }}
        >
          Bank Transfer
        </Typography>
        {Object.entries(detailDocument["bank"]).map(([key, value], index) => (
          <Grid container key={index}>
            <Grid item xs={6}>
              <Typography>{key}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {/* {validation.renderValue(detailDocument?.bank[key], key, true)} */}
                {value}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const SignatureSectionComp = () => (
    <Grid
      container
      spacing={3}
      sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
    >
      <Grid item xs={8} sx={{ ...justifyContentCenter }}>
        {/* <Signature companyName="User" signatureText={detailPR["Created By"]} /> */}
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        {/* TODO: Change to relative to data */}
        <Signature
          companyName="Accounting"
          // signatureText={null}
          signatureText={detailDocument?.others?.approval}
        />
      </Grid>
    </Grid>
  );

  const RenderFooter = () => (
    <div className={classes.footer}>
      <Box sx={{ display: "flex", gap: ".5rem" }}>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          +6221 2986 5888
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          pacific@pacificpatent.com
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        www.pacificpatent.com
      </Typography>
    </div>
  );

  const classes = useStyles();
  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: thirdColor,
        position: "relative",
        p: 3,
        overflow: "auto",
      }}
      ref={documentPreviewRef}
    >
      <Grid item xs={12} sx={{ position: "absolute", width: "100%", p: 2 }}>
        {/* Header Control */}
        <Box
          sx={{
            marginBottom: 2,
            alignItems: "baseline",
            ...justifyContentBetween,
            ...alignItemsCenter,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 800, color: neutral70 }}
            className="not-effect"
          >
            Document Preview
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Box sx={{ ...justifyContentEnd, gap: ".5rem" }}>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => handleZoomIn()}
              >
                <img src={ZoomInIcon} />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => handleZoomOut()}
              >
                <img src={ZoomOutIcon} />
              </IconButton>
              <Button
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkgray", // Change the color when hovered if desired
                  },
                  borderRadius,
                }}
                className="not-effect"
              >
                {percentage}%
              </Button>
            </Box>
          </Box>
        </Box>
        <Paper className={`${classes.root}`} ref={documentPreviewForPrintRef}>
          <img src={boderImg} className={classes.logo} />

          {/* Header */}
          <div className={classes.header}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "800" }}
              className="not-effect"
            >
              INVOICE
            </Typography>
            <img src={logo} alt="logo" />
          </div>

          {/* End of Header */}

          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs="6" container>
              {renderGridItemsFromObject()}
            </Grid>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                PT Pacific Patent Multiglobal{" "}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "right", color: neutral70 }}
              >
                DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav
                51-52
                <br /> Jakarta Pusat 10260
              </Typography>
            </Box>
          </Grid>

          {/* Content */}
          <div className={classes.content}>
            <Box marginTop={"1rem"}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: neutral70,
                  fontWeight: "600",
                  paddingY: ".5rem",
                }}
              >
                Description
              </Typography>
              <Typography>{detailDocument?.product?.Description}</Typography>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 0 }}
            >
              <Grid item xs={8} paddingRight={".5rem"}>
                <Divider />
                <RenderPriceInformation />
                <RenderBankTransfer />
              </Grid>
              <Grid item xs={4}>
                <RenderClientInformation />
              </Grid>
              <Box marginLeft={"1.2rem"}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: neutral70,
                    fontWeight: "600",
                    paddingY: ".5rem",
                  }}
                >
                  Note
                </Typography>
                <Typography>
                  1. Metode Pembayaran – via bank transfer sesuai dengan bank
                  details yang tercantum pada invoice
                </Typography>
                <Typography>
                  2. Mohon cantumkan nomor invoice / proforma invoice pada saat
                  pembayaran dan bukti pembayaran di email ke
                  accounting@pacificpatent.com atau via WhatsApp ke 0877 2245
                  8585
                </Typography>
              </Box>
            </Grid>
            <SignatureSectionComp />
          </div>

          {/* Price information and total */}
          {/* <PriceInformation /> */}
          {/* Signatur Component */}
          {/* Footer */}
          <RenderFooter />
        </Paper>
      </Grid>
      <style>
        {`
        .page-break {
          page-break-before: always;
        }
        .DocumentPreview{
          display:none;
        }
        .DocumentPreviewFooter{
          padding: 20px;
          background-color: #09345C;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

          @page {
              // size: A4;
//               size: 210mm 280mm;
            size: 210mm ${pxToMm(height)}mm; /* A4 size */
              margin: 0;
              /* Remove all margins */
          }

          body {
              margin: 0;
          }

          body *{
            visibility:hidden;
          }
.DocumentPreviewFooter{
   position:fixed;
  bottom:0;
  left:0;
  width:100%;
  border-radius:unset;
}
          .DocumentPreviewFooter, .DocumentPreviewFooter *{
              visibility: visible;
 padding: 10px 20px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          print-color-adjust: exact;
          webkit-print-color-adjust: exact; /* For WebKit-based browsers */
        background-color:  #09345C !important; /* Override background color */
        }

          .DocumentPreview{
            display:block;
            position: fixed !important;
            top: 0;

            left: 0;
            width: 100%;
            z-index: 11999;
            visibility: visible;
            page-break-before: always;
          }

          .DocumentPreview,.DocumentPreview *{
              visibility: visible;
          }
        }

        `}
      </style>
    </Box>
  );
}
