import { formatCurrency, formatDate, INPUT_TYPE } from "../../../../util/function";
import { PaymentStatusBadge } from "../../payment-request/parts/CusomStyle";
import { ANOTHER_INPUT_TYPE } from "../../payment-request/parts/imutable_state";

export const fields = [
  {
    name: "pay_at",
    type: INPUT_TYPE.DATE,
    label: "Pay At",
    defaultValue: "",
    required: true,
    aliasLable: "Pay At",
  },
  {
    name: "attachments",
    type: ANOTHER_INPUT_TYPE.FILE,
    label: "Upload File",
    defaultValue: "",
    required: false,
    tip: "Max size: 5 MB. Format File: PDF.",
    title: "Attachment",
    subtitle: "You can upload the documents required for this process",
    aliasLable: "Attachment",
  },
];

export const columns = [
  {
    field: "reference_code",
    headerName: "No. Reference",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    // description: "This column has a value getter and is not sortable.",
    sortable: true,
    width: 180,
    disableColumnMenu: true,
    valueGetter: (params) => params.value, // Return the status value as a string
    renderCell: ({ value, row }) => {
      // console.log(params);
      const { mode } = row;
      const isPaymentMode = mode && mode === "payment";
      const manipulateValue = (value) => {
        switch (value) {
          case "Approved":
            if (isPaymentMode) {
              return "Waiting Payment";
            } else {
              return value;
            }
          case "Rejected Payment":
            return "Rejected";
          case "Verified":
            return "Waiting Payment";
          default:
            return value;
        }
      };
      return (
        <PaymentStatusBadge status={manipulateValue(value)}>
          {manipulateValue(value)}
        </PaymentStatusBadge>
      );
    },
  },

  {
    field: "category_name",
    headerName: "Category",
    width: 210,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "due_date",
    headerName: "Due Date",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (params) => formatDate(params.value),
    // renderCell: (params) => {
    //   const text = params.value || "";
    //   return text.length > 52 ? text.slice(0, 49) + "..." : text;
    // },
  },
  {
    field: "total",
    headerName: "Total",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: ({ value, row }) => {
      let currency = row.currency;
      let optionCurrency = {
        country: "",
        currency: "",
      };
      switch (currency) {
        case "Rp":
          optionCurrency.country = "id-ID";
          optionCurrency.currency = "IDR";
          break;
        case "USD":
          optionCurrency.country = "en-EN";
          optionCurrency.currency = currency;
          break;
        default:
          optionCurrency.country = "en-EN";
          optionCurrency.currency = currency;
      }
      return formatCurrency(value, optionCurrency);
    },
  },
  {
    field: "client_name",
    headerName: "Client Name",
    width: 210,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? param.value : "-"),
  },
  {
    field: "service_name",
    headerName: "Service",
    width: 230,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? param.value : "-"),
  },
  {
    field: "sub_service_name",
    headerName: "Sub Service",
    width: 230,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? param.value : "-"),
    // valueGetter: (value) => {
    //   console.log(value, 'dari getter');
    //   return value;
    // }
  },
  {
    field: "create_at",
    headerName: "Create At",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (params) => formatDate(params.value),
    // renderCell: (params) => (
    //   <Box
    //     sx={{
    //       "& > img": { mr: 1, flexShrink: 0 },
    //       "& > span": { color: "#555" },
    //     }}
    //   >
    //     <img
    //       loading="lazy"
    //       width="20"
    //       // srcSet={`https://flagcdn.com/w40/${params.value.code.toLowerCase()}.png 2x`}
    //       src={`https://flagcdn.com/w20/${params.value.code.toLowerCase()}.png`}
    //       alt=""
    //     />
    //     {params.value.name} <span>({params.value.code})</span>
    //   </Box>
    // ),
  },
  {
    field: "created_by",
    headerName: "Request By",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "pay_at",
    headerName: "Pay At",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? formatDate(param.value) : "-"),
  },
];
